.series-families {
    .tab-content {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
    }
    .page-title {
        font-size: 18px;
        color: #3a3f4a;
        font-family: $NotoBold;
        margin-bottom: 30px;
    }
    a.btn.btn-default.load-more-results-button {
        width: 100%;
        text-align: center;
        margin-top: -15px;
        margin-bottom: 30px;
    }
    .series-families-list {
        list-style: none;
        padding: 0;
        margin: 0 -15px 0 -15px;
        display: flex;
        flex-wrap: wrap;
        .series-families-list-item-wrapper {
            display: flex;
            .series-families-item {
                width: 100%;
                border: 1px solid #dddddd;
                margin-bottom: 30px;
                min-height:238px;
                transform: scale(0.975);
                @include prefix((
                  transition: all 0.5s
                ), webkit moz o);
                &:hover {
                    border: 1px solid $clr-wilo-green;
                    z-index: 1;
                    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
                    transform: scale(1);
                    transition: box-shadow 0.2s ease, transform 0.2s ease, z-index 0s 0s ease, -webkit-transform 0.2s ease;
                }
                .series-families-item-image-wrapper {
                    padding: 30px 30px 15px 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 260px;
                    img {
                        width: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .series-families-item-information {
                    flex: 7;
                    padding: 15px 30px 30px 30px;
                    .series-families-item-promotional-title,
                    .series-families-item-title {
                        font-family: $NotoBold;
                    }
                    .series-families-item-titles {
                        margin-bottom: 30px;
                        .series-families-item-title {
                            font-size: 18px;
                            color: #3a3f4a;
                            margin-bottom: 0;
                        }
                        .series-families-item-promotional-title {
                            color: $clr-light-grey;
                        }
                    }
                    .series-families-item-buttons {
                        display: flex;
                        flex-direction: column;
                        a {
                            align-self: flex-start;
                            white-space: normal;
                            line-height: normal;
                            &:first-child {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
