.product-series-page-wrapper {
    .product-overview {
        margin-bottom: 45px;
        width: 100%;
        &.row {
            display: flex;
            @include bp-max-tablet {
                flex-direction: column;
            }
        }
        .product-general {
            height: 100%;
            display: flex;
            flex-direction: column;
            h1 {
                color: #505050;
                font-family: $NotoBold;
                font-size: 22px;
                margin-bottom: 0
            }
            .product-images {
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                @include bp-max-tablet {
                    margin: 30px 0;
                }
                .thumbnail-images {
                    overflow: hidden;
                    height: auto !important;
                    @include bp-tablet {
                        margin: 0 15px;
                        z-index: 1;
                        top: 50%;
                        position: absolute;
                        transform: translateY(-50%);
                        width: 64px;
                        .slick-list {
                            max-height: 296px;
                            height: auto !important;
                        }
                        .slick-track {
                            width: 64px !important;
                        }
                    }
                    @include bp-max-smartphone {
                        margin: 0 auto;
                        position: static;
                        width: auto;
                        max-width: 296px;
                        display: flex;
                        justify-content: center;
                        .slick-track {
                            display: flex;
                            justify-content: center;
                            min-width: 296px !important;
                            height: 64px !important;
                        }
                        .slick-list {
                            height: 64px !important;
                        }
                    }
                }
                .thumbnail-image {
                    width: 64px !important;
                    height: 64px;
                    cursor: pointer;
                    border: 1px solid #787878;
                    margin: 0 0 10px;
                    position: relative;
                    @include bp-max-smartphone {
                        margin: 0 10px 0 0;
                    }
                    &.slick-current {
                        border: 2px solid #009c82;
                    }
                    img {
                        max-width: 90%;
                        max-height: 90%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: auto;
                        transform: translate(-50%, -50%);
                    }
                    &:nth-child(n+5) {
                        display: none;
                    }
                    &.slick-slide {
                        display: block;
                    }
                }
                .slider-images {
                    width: 100%;
                    padding-left: 94px;
                    @include bp-desktop {
                        .slick-list {
                                height: 320px;
                        }
                        .slick-track {
                            width: auto !important;
                        }
                    }
                    @include bp-max-smartphone {
                        padding-left: 0;
                    }
                    &>div {
                        display: none;
                        position: relative;
                        &:first-child {
                            display: flex;
                        }
                        &.slick-list {
                            display: block;
                        }
                        img {
                            max-height: 275px;
                            max-width: 100%;
                            width: auto;
                            margin: auto;
                            left: 50%;
                            top: 50%;
                            @include bp-desktop {
                                max-height: 228px;
                            }
                            @include bp-max-smartphone {
                                // max-height: unset;
                                max-width: 320px;
                                max-height: 250px;
                            }
                        }
                        iframe {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .slick-slide {
                        height: 320px;
                        display: flex;
                        position: absolute !important;
                        left: 0 !important;
                        align-items: center;
                        background-color: #fff;
                        @include bp-max-smartphone {
                            height: 300px;
                        }
                    }
                }
            }
        }
        .product-technical {
            background-color: #787878;
            padding: 30px;
            * {
                color: #fff;
                margin-bottom: 10px;
                :last-child {
                    margin-bottom: 0;
                }
            }
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    padding-left: 20px;
                    &:before {
                        font-family: $WiloIcons !important;
                        font-size: 10px;
                        padding-right: 10px;
                        margin-left: -20px;
                        content: "\e903";
                        padding-top: 3px;
                    }
                }
            }
        }
    }
    .tab-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: #f1f1f1;
        z-index: 100;
        -webkit-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
        .container {
            display: flex;
            .series-tabs {
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                li {
                    display: inline-block;
                    a {
                        padding: 22px 22px 17px;
                        border-bottom: 3px solid transparent;
                        text-decoration: none;
                        display: block;
                        color: #363640;
                        line-height: 1;
                        transition: none;
                        &:hover {
                            background-color: #f8f8f8;
                        }
                    }
                    &.active {
                        a {
                            background-color: rgba(255, 255, 255, 0.5);
                            border-bottom: 3px solid #009c82;
                        }
                    }
                    &.tab--special {
                        a {
                            background-color: #009c82;
                            border-bottom: 3px solid #009c82;
                            color: #fff;
                        }
                        &.active a {
                            border-bottom: 3px solid #006C5A;
                        }
                    }
                }
                @include bp-max-tablet {
                    display: none;
                }
            }
            .tab-burger {
                display: none;
                position: relative;
                cursor: pointer;
                @include bp-max-tablet {
                    position: static;
                }
                .hamburger {
                    display: none;
                    cursor: pointer;
                    padding: 15px 20px 15px 22px;
                    @include bp-max-tablet {
                        padding-left: 0;
                        display: block
                    }
                    .hamburger-box {
                        height: auto;
                        .hamburger-inner,
                        .hamburger-inner::before,
                        .hamburger-inner::after {
                            height: 1px;
                            background-color: #363640;
                        }
                        .hamburger-inner:after {
                            bottom: -9px;
                        }
                        .hamburger-inner:before {
                            top: -9px;
                        }
                    }
                }
                &>p {
                    @include bp-max-tablet {
                        display: none;
                    }
                    margin: 0;
                    padding: 22px 22px 17px;
                    line-height: 1;
                    border-bottom: 3px solid transparent;
                    color: #363640;
                    &.active {
                        background-color: rgba(255, 255, 255, 0.5);
                        border-bottom: 3px solid #009c82;
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                }
                ul {
                    position: absolute;
                    display: none;
                    padding: 0;
                    margin: 0;
                    top: 100%;
                    list-style-type: none;
                    background-color: #f1f1f1;
                    li {
                        text-align: center;
                        &.hidden {
                            display: none;
                        }
                        &.active {
                            a {
                                color: #fff;
                                background-color: #009c82;
                                transition: none;
                            }
                            @include bp-max-tablet {
                                text-align: left;
                            }
                        }
                        &:hover {
                            background-color: rgba(255, 255, 255, 0.5);
                        }
                        @include bp-max-tablet {
                            text-align: left;
                        }
                        a {
                            border-bottom: 3px solid transparent;
                            display: block;
                            padding: 15px 15px 12px;
                            color: #363640;
                        }
                    }
                    @include bp-max-tablet {
                        transform: none;
                        left: 0;
                        width: 100vw;
                        li {
                            display: block !important;
                        }
                    }
                }
                @include bp-max-tablet {
                    display: block !important;
                }
            }
            .features {
                margin-left: auto;
                display: flex;
                a {
                    font-size: 24px;
                    color: #363640;
                    padding: 15px 20px;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                    &.icon-sales {
                        background: $clr-technical-yellow;
                        &:hover {
                            background: $clr-technical-yellow-darker;
                        }
                    }
                    &.icon-wilosolutionfinder {
                        @include bp-max-tablet {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    h2 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    h3 {
        font-size: 18px;
    }
    .tab-content {
        padding-top: 15px;
        @include bp-max-smartphone {
            padding-top: 13px;
        }
        p,
        li {
            font-size: 16px;
        }
        .information-block {
            margin-top: 30px;
            display: inline-block;
            &:first-child {
                margin-top: 0;
            }
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                li {
                    text-indent: -24px;
                    margin-left: 24px;
                    margin-bottom: 5px;
                    max-width: 92%;
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                    &:before {
                        font-family: $WiloIcons !important;
                        font-size: 12px;
                        padding-right: 10px;
                        content: "\e92f";
                        font-weight: bold;
                        color: #009c82;
                        padding-top: 3px;
                    }
                    ul {
                        margin-top: 5px;
                        li:before {
                            content: "-";
                            font-family: unset;
                            padding-top: 0;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .series-information {
            .information-wrapper {
                column-count: 1;
                @include bp-desktop {
                    column-count: 2;
                    column-gap: 30px;
                    margin-bottom: 30px;
                }
            }
        }
        .downloads {
            .download-tiles {
                column-count: 1;
                @include bp-desktop {
                    column-gap: 30px;
                    column-count: 2;
                }
                .download-tile-wrapper {
                    display: inline-block;
                    width: 100%;
                    .download-tile {
                        display: flex;
                        padding: 30px;
                        background-color: #F1F1F1;
                        margin-bottom: 40px;
                        .image-wrapper {
                            max-width: 150px;
                            max-height: 200px;
                            margin-right: 15px;
                        }
                        .information {
                            flex-grow: 1;
                            h3 {
                                color: #505050;
                            }
                            table {
                                width: 100%;
                                tr {
                                    border-bottom: 1px solid #009c82;
                                    td {
                                        width: 50%;
                                        padding: 10px 10px 10px 0;
                                        color: #363640;
                                    }
                                }
                            }
                            .download {
                                display: flex;
                                align-items: center;
                                margin-top: 15px;
                                justify-content: space-between;
                                @include bp-smartphone-only {
                                    margin-top: 20px;
                                }
                                p {
                                    display: flex;
                                    color: #505050;
                                    margin: 0;
                                    align-items: center;
                                    span {
                                        color: #363640;
                                        margin-right: 15px;
                                        font-size: 24px;
                                        @media (max-width: 1199px) and (min-width: 992) {
                                            margin-right: 8px;
                                        }
                                    }
                                }
                                @media (max-width: 399px) {
                                    flex-direction: column;
                                    align-items: initial;
                                    p {
                                        margin-bottom: 15px;
                                    }
                                }
                            }
                        }
                        @media (max-width: 599px) {
                            flex-direction: column;
                            align-items: center;
                            .information {
                                width: 100%;
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
        .curves {
            margin-bottom: 30px;
            .images {
                &.slick-initialized {
                    width: calc(100% - 150px);
                    margin: 0 auto;
                    @include bp-smartphone-only {
                        width: 100%;
                        .slick-arrow {
                            display: none;
                        }
                    }
                }
                .slick-arrow {
                    background: #009c82;
                    width: 50px;
                    height: 50px;
                    top: 50%;
                    opacity: 1;
                    color: #fff;
                    .wilo-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #fff;
                    }
                }
                .arrow-left {
                    left: -75px
                }
                .arrow-right {
                    right: -75px;
                }
                .image-content {
                    display: flex;
                    flex-direction: column;
                    p {
                        margin-bottom: 15px;
                    }
                    img {
                        margin: 0 auto 30px;
                        max-width: 990px;
                    }
                    .sub-title {
                        text-align: right;
                        margin-top: 15px
                    }
                    .information-block {
                        width: 100%;
                    }
                }
            }
            .thumbnails {
                margin-top: 15px;
                margin-bottom: 30px;
                .slick-track {
                    display: flex;
                    justify-content: center;
                    transform: none !important;
                    .slick-slide {
                        cursor: pointer;
                        width: 64px !important;
                        height: 64px;
                        margin-right: 10px;
                        padding: 5px;
                        border: 1px solid #787878;
                        display: flex;
                        align-items: center;
                        &:last-child {
                            margin-right: 0;
                        }
                        &.slick-current {
                            border: 2px solid #009c82;
                        }
                    }
                }
            }
        }
        .productlist {
            .container {
                width: auto;
                padding: 0;
            }

            .list {
                table {
                    width: 100%;

                    td {
                        font-size: 14px;
                    }

                    thead {
                        tr {
                            border-bottom: 3px solid #009c82;
                            color: #009c82;
                            font-family: $NotoBold;
                            height: 50px;

                            th {
                                padding: 5px;
                                vertical-align: top;
                            }
                        }
                    }

                    tbody {
                        tr {
                            height: 50px;

                            &:nth-child(odd) {
                                background-color: #F1F1F1;
                            }

                            td {
                                padding: 5px;
                                text-align: center;
                                vertical-align: top;

                                a {
                                    color: #009c82;
                                    font-family: $NotoBold;
                                }

                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    color: #333333;
                                }
                            }
                        }
                    }

                    &.v-table-active-bp-xxs,
                    &.v-table-active-bp-xs,
                    &.v-table-active-bp-sm,
                    &.v-table-active-bp-md {
                        tr {
                            border-bottom: 1px solid #009c82;
                        }
                    }

                    &.v-table-active-bp-xxs {
                        tr {
                            height: auto;
                        }

                        th, td {
                            display: none;
                        }

                        .v-table-column {
                            display: table-cell;

                            tr {
                                border-bottom: 0;
                            }
                        }
                    }

                    &.v-table-active-bp-xs {
                        th, td {
                            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                            &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                display: none;
                            }
                        }

                        .v-table-column {
                            display: table-cell;

                            tr {
                                display: none;

                                &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-row;
                                }
                            }

                            td {
                                &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-cell;
                                }
                            }
                        }
                    }

                    &.v-table-active-bp-sm {
                        th, td {
                            &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                            &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                display: none;
                            }
                        }

                        .v-table-column {
                            display: table-cell;

                            tr {
                                display: none;

                                &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-row;
                                }
                            }

                            td {
                                &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6),
                                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-cell;
                                }
                            }
                        }
                    }

                    &.v-table-active-bp-md {
                        th, td {
                            &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7),
                            &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                display: none;
                            }
                        }

                        .v-table-column {
                            display: table-cell;

                            tr {
                                display: none;

                                &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7),
                                &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-row;
                                }
                            }

                            td {
                                &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7),
                                &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                                    display: table-cell;
                                }
                            }
                        }
                    }

                    .v-table-column {
                        padding: 0;
                        display: none;

                        table {
                            tbody tr {
                                height: auto;
                                border-bottom: 0;

                                &:nth-child(odd) {
                                    background-color: #F1F1F1;
                                }

                                &:nth-child(even) {
                                    background-color: #fff;
                                }
                            }

                            th, td {
                                padding: 5px;
                                vertical-align: top;
                                display: table-cell;
                            }
                        }
                    }
                }
            }
        }

        #webshop {
            .add-to-cart-btn .wilo-btn {
                width: 100%;
                margin: 0;
            }
            tbody {
                tr {
                    &:last-child {
                        border-bottom: 2px solid #009c82;
                    }
                }
            }
        }
        .tab-pane {
            .list {
                table {
                    tbody {
                        td {
                            text-align: left !important;
                        }
                    }
                }
            }
            .wilo-typekey-table {
                tbody {
                    tr {
                        td {
                            vertical-align: baseline;
                            padding-bottom: 10px;
                            &:first-child {
                                padding-right: 30px;
                                white-space: nowrap;
                            }
                        }
                        &:last-child {
                            td {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.generate-pdf-form-wrapper {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 30px 30px 0 30px;
    width: 600px;
    max-height: 60vh;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility, opacity 0.3s ease-in-out;
    &.active {
        visibility: visible;
        opacity: 1;
    }
    @include bp-smartphone-only {
        width: 75vw;
        max-height: 60vh;
        overflow: auto;
    }
    background-color: #fff;
    .icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 12px;
        cursor: pointer;
        &:before {
            color: $clr-dark-grey;
        }
    }
    form {
        margin-bottom: 30px;
        h3,
        h4 {
            color: $clr-text-darkblue;
        }
        p {
            color: #505050;
        }
        h2 {
            font-family: $NotoBold;
            margin-top: 0;
        }
        &>p,
        h2 {
            margin-bottom: 15px;
        }
        h3 {
            font-size: 16px;
            cursor: pointer;
        }
        &>div {
            margin-bottom: 15px;
        }
        .input-wrapper {
            margin-bottom: 15px;
        }
        .checkbox-wrapper {
            line-height: 1;
            p {
                top: 50%;
            }
        }
    }
    .toggle-optional {
        &:before {
            font-family: $WiloIcons !important;
            content: "\e904";
            color: #363640;
            margin-right: 10px;
            font-weight: normal;
            font-size: 14px;
        }
        &.active {
            &:before {
                content: "\e901";
            }
        }
    }
    .optional-content {
        display: none;
    }
    #downloadpdf {
        margin-top: 15px;
    }
}

.informationArea {
    h2 {
        font-size: 34px;
    }
}

.iframe--360 {
    width: 100%;
    height: 400px;
    @include bp-max-tablet {
        display: none;
    }
}

.informationArea__txtImg {
    text-align: center;
}

.img--360 {
    width: auto;
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;
}

.video--fullsize {
    width: 100%;
}

.container---boxed {
    max-width: 940px;
}

.citeBox {
    position: relative;
}

.citeBox__textWrapper {
    position: absolute;
    top: 60px;
    @include bp-max-tablet {
        right: 0;
        top: 20px;
    }
    @include bp-max-smartphone {
        position: static;
        top: 0;
    }
}

.citeBox__textWrapper--left {
    @include bp-max-tablet {
        left: 0;
        top: 20px;
    }
}

.citeBox__text {
    padding: 30px;
    color: #fff;
    background-color: #363640;
    font-size: 28px;
    line-height: 30px;
    @include bp-max-smartphone {
        font-size: 18px;
        line-height: 22px;
    }
}

.citeBox__author {
    font-family: $NotoBold;
    font-size: 12px;
    white-space: nowrap;
}

.headline--big {
    font-size: 34px;
}

.spaceBottom--big {
    margin-bottom: 90px;
    @include bp-max-tablet {
        margin-bottom: 30px;
    }
}

.orderedList {
    padding-left: 15px;
}

.orderedList__item--space {
    margin-bottom: 15px;
}

.bg--grey {
    background-color: #f1f1f1;
    padding: 60px 0;
    margin-bottom: 90px;
    @include bp-max-tablet {
        padding: 30px 0;
    }
}

.bg--greyLast {
    margin-bottom: -60px;
}

.content--centered {
    display: flex;
    align-items: center;
    @include bp-max-tablet {
        &>div {
            width: 50%;
        }
    }
    @include bp-max-smartphone {
        flex-direction: column-reverse;
        &>div {
            width: auto;
        }
    }
}

.carouselPromo__wrapper {
    @include bp-max-tablet {
        padding: 0;
    }
}

.carousel__element {
    display: flex !important;
    height: 500px;

    &--reverse {
        flex-direction: row-reverse;
    }
    @include bp-max-smartphone {
        flex-direction: column-reverse;
        height: auto;
    }
}

.carouselPromo {
    .slick-dots   {
        bottom: -40px;
        li button:before {
            font-size: 12px;
        }
    }
    .slider-arrow {
        @include bp-max-tablet {
            top: 30vw;
        }
    }
}

.carousel__content {
    width: 50%;
    @include bp-max-smartphone {
        width: auto;
    }

    &--green {
        background-color: $clr-wilo-green;

        >* {
            color: $clr-white !important;
        }
    }

    &--lightgrey {
        background-color: $clr-lighter-grey;

        >* {
            color: $clr-gunmetal !important;
        }
    }

    &--darkgrey {
        background-color: $clr-text-darkblue;

        >* {
            color: $clr-white !important;
        }
    }
}

.carousel__inner {
    display: flex;
}

.carousel__image {
    background-position: center;
    background-size: cover;
    @include bp-max-smartphone {
        height: 70.25vw;
    }
}

.image--sectionStarter {
    margin-bottom: 45px;
    @include bp-max-smartphone {
        margin: 30px 0;
    }
}

.container--large {
    max-width: 1440px;
    margin: 0 auto 90px auto;
}

.product-series-page-wrapper {
    .tab-content {
        .carousel__information {
            padding: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include bp-max-tablet {
                padding: 60px;
            }
            @include bp-max-smartphone {
                padding: 30px 15px;
            }
        }
        .modal-video {
            padding-top: 0;
            padding-bottom: 0;
            .video-player-control {
                span {
                    display: none;
                }
            }
            .video-player-module .video-player-wrap img {
                opacity: 0.3;
            }
        }
    }
}

.promoImg {
    padding: 0 90px;
    @include bp-max-smartphone {
        img {
            max-height: 60vw;
            width: auto;
            margin-bottom: 30px;
        }
    }
}
