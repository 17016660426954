// World Explorer
.world-explorer-image {
    max-height: 450px;
    min-height: 250px;
}

.explore-it {
    &:hover,
    &:focus {
        .over-image-text {
            background-color: $clr-wilo-green;
            color: $clr-white;
        }
    }
    &:hover,
    &:focus,
    &:link,
    &:visited,
    &:active {
        h4 {
            color: $clr-white;
        }
        color: $clr-white;
    }
    .over-image-text {
        max-width: 40%;
        position: absolute;
        right: 40px;
        bottom: 40px;
        background-color: $clr-text-darkblue;
        padding: 10px 50px 10px 20px;
        color: $clr-light-grey-text;
        @include prefix((
          transition: all 0.5s
        ), webkit moz);
        h4 {
            color: $clr-white;
        }
        @include bp-max-smartphone {
            max-width: 80%;
        }
        &:after {
            font-family: $WiloIcons !important;
            content: "\e903";
            display: inline-block;
            margin-right: 5px;
            font-size: 24px;
            position: absolute;
            right: 5px;
            top: 50%;
            @include prefix((
              transform: translateY(-50%)
            ), webkit ms moz o);
        }
    }
}

.explorer-image {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: 100%;
    img {
        vertical-align: middle;
        width: initial;
        display: table-cell;
    }
}

.wilo-sf-gs-wrap {
    overflow: hidden;
    position: relative;
    height: 400px;
    .wilo-sf-gs {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix((
          transform: translate(-50%, -50%)
        ), webkit ms moz o);
        @include bp-desktop-large {
            width: 485px;
        }
        @include bp-desktop-only {
            width: 485px;
        }
        .wilo-sf-gs-heading {
            color: $clr-white;
            background-color: $clr-wilo-green;
            padding: 15px 15px;
            width: 100%;
            @include font-size(18px);
            margin-bottom: 2px;
        }
        .wilo-sf-gs-content {
            background-color: $clr-white;
            padding: 15px;
        }
    }
    select {
        min-width: 260px;
    }
    .wilo-submit,
    .wilo-select {
        @include bp-max-tablet {
            width: 100%;
            margin-top: 10px;
        }
    }
}

.world-explorer-section {
    .custom-formgroup {
        .select2-container {
            width: 60% !important;
            margin-right: 5px;
        }
        .wilo-btn {
            width: calc(40% - 9px);
            @include bp-max-tablet {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}
