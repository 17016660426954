.page-module {
    margin-bottom: 30px;
    &:first-child {
        @include bp-smartphone-only {
            margin-top: 15px;
        }
    }
    &:last-child {
        @include bp-smartphone-only {
            margin-bottom: 15px;
        }
    }
    &.background-grey {
        background-color: $clr-crystal-white;
        padding: 30px;
    }
    p,
    a {
        color: $clr-gunmetal;
    }
    .media-downloads-list {
        .download-text {
            margin: 0;
            .wilo-icon {
                font-size: 32px;
            }
        }
    }

    /* Headlines changed to H2 & H3, Backup Only*/
    .text-sub-headline {
        margin: 0 0 10px 0;
        font-family: $NotoBold;
        @include font-size($font-size-base);
        color: $clr-text-darkblue;
    }
    .text-headline, h2 {
        margin: 0 0 10px 0;
        font-family: $NotoBold;
        @include font-size(20px);
        color: $clr-text-darkblue;
        &.wilo-green-text{
            color: $clr-wilo-green;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 15px 0;
        li {
            &:last-child {
                div {

                    a {
                        margin-bottom: 0;
                    }
                }
            }
            div {
                // display: inline-block;
                &:first-child {
                    &.contact-inline-info {
                        min-width: 80px;
                    }
                }
                p.marginal-text,
                a.marginal-text {
                    display: inherit;
                    margin-bottom: 0;
                    font-size: 16px;
                }
            }
        }
    }
    .background-grey {
        background-color: $clr-crystal-white;
        padding: 25px;
    }
}

.page-module{
    &:last-child{
        margin-bottom: 0;
    }
}

.multi-col-section {
    .page-module {
        margin-bottom: 15px;
    }
}
