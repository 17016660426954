// News Pages (Overview & Details)
.news-page-headline,
.news-overview-headline,
.read-more-button,
.news-detail-page-content-headline,
.publish-information{
    font-family: $NotoBold;
}

.news-page-headline {
    font-size: 20px;
    color: $clr-black;
    margin-bottom: 30px;

    margin-top: 0;
}

.news-overview-page {
    margin-bottom:30px;
    .tab-title-wrapper {
        margin-top: 15px;
        .tab-title {
            font-size: 18px;
            line-height: 34px;
            cursor: pointer;
            font-family: $NotoBold;
            color: $clr-text-darkblue;
        }
    }
    .products-per-page {
        margin-top: 15px;
        @media screen and (max-width: 1200px) {
            float: right;
            margin-top: 15px;
        }
    }
}

.news-overview-tabs > li > a {
    background-color: #dddddd;
    color: $clr-white !important;
    border-radius: 0;
    border: none;
}

.news-overview-tabs > li.active > a,
.news-overview-tabs > li.active > a:focus,
.news-overview-tabs > li > a:hover,
.news-overview-tabs > li > a:active {
    background-color: $clr-light-grey;
}

.tabs-grey-border {
    border-bottom: 1px solid $clr-light-grey;
}

.news-overviews {
    padding: 20px 0;
    list-style: none;
}

.news-overviews > .news-overview {
    width: 100%;
    min-height: 125px;
    color: #a0a0a0;
    border-bottom: 1px solid #dddddd;
    padding: 30px 0;
    a.btn.btn-default {
        margin: 10px 0 0 0;
    }
    &:last-child {
        border-bottom: 0 solid transparent;
        padding-bottom:15px;
    }
}

.news-detail-page-content {
    padding: 15px 0;
}

.news-overview-headline {
    @include font-size($font-size-base);
    color: $clr-mega-menue-grey;
    margin-bottom: 10px
}

.news-detail-page-content-headlinee {
    font-size: 14px;
    color: $clr-mega-menue-grey;
}

.news-overview-text,
.read-more-button {
    margin-bottom: 0;
}

.news-page-text,
.news-detail-page-content-headline,
.news-overview-text,
.read-more-button {
    color: $clr-mega-menue-grey;
}

.read-more-button {
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    display: inline-block;
    line-height: 1;
}

.news-overview-image-wrapper {
    display: inline-block;
}

.news-overview-image {
    float: left;
    @include bp-max-tablet {
        margin-bottom: 10px;
    }
}

.news-detail-page-content-image {
    width: 100%;
    margin: 15px 0;
}

.news-detail-page-content-introdcution {
    margin-bottom: 0;
}

.grey-button {
    background-color: $clr-mega-menue-grey;
    color: $clr-white;
}

.span-button span {
    font-size: 12px;
    margin-right: 5px;
}
