.bsr-icon-teaser {
    // styles partly copied from defaultTeaser
    background-color: white;
    border: 1px solid #E9E9E9;

    &__inner {
        padding: 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
            width: 64px;
        }

        p, img {
            margin-bottom: 1rem;
        }
    }

    &__headline {
        font-size: 20px;
        font-weight: 600;
    }

    @media (min-width: 768px) {
        position: relative;
        padding-top: 100%;

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media (min-width: 1200px) { 
        padding-top: 90%;
    }
}