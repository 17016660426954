.wilo-config-box {
    background-color: $clr-text-darkblue;
    color: $clr-white;
    padding: 30px;
    margin-bottom: 45px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
            font-size: 1.5em;
        }
        span {
            font-size: 18px;
            letter-spacing: 1px;
            margin: 0 15px;
        }
    }
    .bot-row {
        a.btn {
            margin-bottom: 0;
            &:before {
                font-family: $WiloIcons !important;
                content: "\e903";
            }
        }
    }
    @media (max-width: 768px) {
        padding: 25px;
        flex-direction: column;
        .top-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }
        .bot-row {
            width: 100%;
            margin-top: 15px;
            .btn {
                display: block;
            }
        }
    }
}

@media (min-width: 993px) {
    .wilo-config-box-mobile {
        display: none;
    }
    .wilo-config-box-desktop {
        display: block;
    }
}

@media (max-width: 991px) {
    .wilo-config-box-mobile {
        display: block;
    }
    .wilo-config-box-desktop {
        display: none;
    }
}