.download-tile{
    display: flex;
    &-content{
        display: flex;
        width: 100%;
        gap: 30px;
        
        &__image-wrapper{
            flex-basis: 25%;
            min-width: 150px;
        }
        &__text-wrapper{
            flex-basis: 75%;
            .headline{
                font-size: 18px;
                line-height: 1;
                margin-bottom: 10px;
                font-family: $NotoBold;
                color: $clr-text-darkblue;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    &-button{
        margin: auto 0 0 30px;
        &__information-text{
            font-family: $NotoBold;
            color: $clr-text-darkblue;
        }
    }   
    @include bp-smartphone-only{
        flex-direction: column;

        &-button{
            margin: 15px 0 0;
            a {
                width: 100%;
            }
        }
    }
    @media (max-width: 600px){
        &-content{
            gap: 15px;
            flex-direction: column;
        }
    }
}
.marginal-page-content .download-tile{
    @include bp-max-tablet{
        flex-direction: column;
        &-button{
            margin: 15px 0 0;
            a {
                width: 100%;
            }
        }
    }
}
