.teaser__personal-contact-teaser {
  position: relative;
  padding-top: 90%;
  background-color: #e9eaea;
  color: #363640;

  .teaser-inner {
    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h3 {
      font-size: 2rem;
      text-transform: uppercase;
      font-family: $NotoBold;
      &.color-green {
        color: #009c82;
      }
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1.25rem;
    }

    ul {
      list-style: none;
      padding-left: 0;
      a {
        color: black;
      }
    }

    i {
      color: #009c82;
      padding-right: 0.75rem;
      font-family: $WiloIcons;
    }

    .icon-chat {
      &::before {
        content: "\e906";
      }
    }

    .icon-fax {
      &::before {
        content: "\e8d1";
      }
    }

    .icon-mail {
      &::before {
        content: "\e8d4";
      }
    }
  }
}
