.contentSlider,
.bsrContentSlider {
.slick-slide {
    margin: 0 2rem;
}
.item-slide {
    display:flex;
    flex-wrap:wrap;
    width:20%;float:left;
    padding:0 10px;
    height:inherit
}
.item-slide .image-wrapper{
    height:200px;
    display:flex;
    flex-direction:column
    }
    .item-slide .image-wrapper img{
        max-height:200px;
        max-width:100%;
        height:auto;
        width:auto;
        margin:auto;
        display:block;
        transform:scale(.85);
        transition:all .5s;
        align-self:center;
        flex-shrink:0
    }
    .item-slide:hover .image-wrapper img{
        transform:scale(.95)
    }
    .item-slide:nth-child(n+6){
        display:none
    }
    .item-slide.slick-slide:nth-child(n+6){
        display:flex
    }
    @media (max-width:1200px){
        .item-slide{
            width:25%
        }
        .item-slide:nth-child(n+5){
            display:none
        }
        .item-slide.slick-slide:nth-child(n+5){
            display:flex
        }
    }
    @media (max-width:991px){
        .item-slide{
            width:33.333%
        }
        .item-slide:nth-child(n+4){
            display:none
        }
        .item-slide.slick-slide:nth-child(n+4){
            display:flex
        }
    }
    @media (max-width:768px){
        .item-slide{
            width:50%;
            padding-left:35px;
            padding-right:35px
        }
        .item-slide:nth-child(n+3){
            display:none
        }
        .item-slide.slick-slide:nth-child(n+3){
            display:flex
        }
    }
    @media (max-width:480px){
        .item-slide{
            width:100%
        }
        .item-slide:nth-child(n+2){
            display:none
        }
        .item-slide.slick-slide:nth-child(n+2){
            display:flex
        }
    }
}
.contentSlider.page-module {
    margin-bottom: 30px !important;
}

.bsrContentSlider {
    &.slick-dotted {
        margin-bottom: 70px !important;
    }

    .slick-dots {
        bottom: -70px;
        li {
            button::before {
                color: $clr-wilo-green;
                font-size: 12px;
            }
        }
    }
}
