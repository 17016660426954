// Mega Menu

.sub-menu {
    display: none;
    @include bp-max-tablet {
        display: block;
        &>.mega-dropdown-menu {
            position: absolute;
            top: 0;
            left: 100%;
            height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            width: 340px !important;
            display: block;
        }
    }
}

.sub-menu-inner {
    ul {
        list-style: none;
        padding:0;

    }
}


.mega-dropdown-menu.row {
    border-radius: 0;
    background-color: $clr-crystal-white;
    display: block;
    border: 0;
    margin: 0;
    padding: 30px 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0 3px 6px 0;
}

.mega-dropdown-menu-arrow {
    width: 18px;
    content: "";
    border-bottom: 9px solid $clr-crystal-white;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    position: absolute;
    bottom: 0;
    left: 500px;
    z-index: 10;
}

.container>.mega-dropdown-menu>li>ul {
    padding: 0;
    margin: 0;
}

.container>.mega-dropdown-menu>li>ul,
.sub-menu-link {
    list-style: none;
    font-family: $NotoSans;
    color: $clr-gunmetal;
    @include font-size($font-size-base);
    line-height: 1.3;
    display: block;
    padding: 5px 0;
    clear: both;
    white-space: normal;
}

.sub-menu-title {
    display: none;
}

.sub-menu-link:hover:before {
    font-family: $WiloIcons;
    content: "\e903";
    margin-left: -17px;
    @include font-size(12px);
    padding-right: 5px;
    padding-top: 2px;
    position: absolute;
}

.sub-menu-link:hover,
.sub-menu-link:before:hover {
    display: block;
}

.mega-dropdown-menu a:hover {
    color: $clr-wilo-green;
}

.mega-dropdown-menu a:active,
.mega-dropdown-menu a:focus {
    color: $clr-wilo-green;
    text-decoration: none;
}

.container>.mega-dropdown-menu>li>ul>.divider {
    background-color: $clr-gunmetal;
    padding: 0;
    margin: 15px 0;
}

.container .mega-dropdown-menu .dropdown-header, .container .mega-dropdown-menu .dropdown-header a {
    font-family: $NotoBold;
    color: $clr-gunmetal;
    @include font-size(18px);
    padding: 0 0;
    white-space: normal; // initial is not a valid property
}

.container .mega-dropdown-menu .dropdown-header a:hover {
    color: $clr-wilo-green;
}

.mega-menu-teaser {
    max-width: 385px;
    background-color: $clr-white;

    p {
        max-width: 90%;
        margin: 0;
        color: $clr-mega-menue-grey;
        @include font-size(15px);
        font-family: $NotoSans;
        &.mega-menu-teaser-title {
            max-width: none;
            margin-bottom: 10px;
            font-family: $NotoBold;
            color: $clr-text-darkblue;
            @include font-size($font-size-base);
        }
    }
}

.mega-menu-teaser-img {
    height: 115px;
}

.mega-menu-teaser-text-area {
    position: relative;
    padding: 18px;
    height: auto;
}

.mega-menu-teaser-arrow {
    font-size: 28px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    color: $clr-light-grey;
    &:hover {
        color: $clr-light-grey;
    }
}
