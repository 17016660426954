@media (min-width: 992px) {
    .wilo-service-image {
        margin-top: 15px;
        max-height: 200px;
    }
}

.service-slider {
    h3 {
        text-align: center;
    }
    .service-slider-item {
        .sub {
            color: $clr-grey;
            font-family: $NotoBold;
            margin-top: 10px;
        }
        p {
            color: $clr-super-navigation-grey;
        }
    }
    .slick-arrow {
        background-color: transparent;
        top: 50%;
        width: 35px;
        z-index: 10;
        &::before {
            border: 0;
            color: $clr-grey;
            font: normal normal normal 20px/1 FontAwesome, sans-serif;
            height: 20px;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            right: 0;
            top: 20%;
            width: 18px;
        }
        &.slick-prev {
            left: 0;
        }
        &.slick-next {
            right: 0;
        }
        &.slick-prev::before {
            content: '\f053';
            width: 20px;
        }
        &.slick-next::before {
            content: '\f054';
            width: 20px;
        }
    }
}

.slick-list {
    padding: 0 !important;
}

.service-slider-item {
    width: 100%;
}

@media (max-width: 767px) {
    .service-slider .slick-next:before {
        font-family: $WiloIcons !important;
        content: "\e903" !important;
    }
    .service-slider .slick-prev:before {
        font-family: $WiloIcons !important;
        content: "\e902" !important;
    }
}
