// Toolbar

$toolbar-height: 113px !default;
$toolbar-width: $toolbar-height !default;

.toolbar-section {
    background-color: $clr-white;
    font-family: $NotoBold;
    padding-top: 30px;


    @include bp-smartphone-only {
        padding-top: 30px;
    }
}

.toolbar .section-divide {
    @media #{$max-smartphone} {
        display: none;
    }
}

.toolbar-row {
    color: $clr-text-darkblue;
    .searchbar {
        z-index: 999;
    }
    & > div[class*="col-"] {
        height:38px;
        position: relative;
        background-color: $clr-white;
        z-index: 9;
    }
    & > div[class*="searchbar"] {
        z-index: 999;
        padding:0;

    }

}

.toolbar-spacer {
    width: 100%;
    height: 30px;
    background: white;
    position: relative;
    z-index: 9;
}

.toolbar-item {
    overflow: hidden;
    @include font-size($font-size-base);
    font-family: $NotoBold;
    width: $toolbar-width;
    height: $toolbar-height;
    background-color: $clr-wilo-green;
    color: $clr-white;
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    position: relative;

    @media #{$max-tablet} {
        width: 70%;
        display: inline-block;
    }
    @media #{$max-smartphone} {
        display: inline-block;
    }
}

.toolbar-item-text {
    position: absolute;
    bottom: 10px;
    margin-bottom: 0;
    padding: 10px 10px 0 10px;
    width: 100%;
}

.toolbar-item .inner {
    font-family: $NotoBold;
    color: $clr-text-darkblue;
    display: block;
    width: 100%;
    height: 59px;
    padding: 5px 10px 10px 10px;
    position: absolute;
    bottom: -59px;
    @include font-size(14px);
}

.toolbar-text {
    @include font-size(18px);
    line-height: 34px;
    cursor: pointer;
    font-family: $NotoBold;
}

.toolbar-toggle-icon {
    @include font-size($font-size-base);
    margin-right: 5px;
    display: inline-block;
    @include prefix((
      transition: all 0.5s
    ), webkit moz o ms);
}

.toolbar {
    margin-bottom: 30px;
    @if variable-exists("toolbar-height") {
      height: $toolbar-height;
    }
    a:hover,
    a:focus,
    a:link,
    a:visited,
    a:active {
        .toolbar-item {
            color: $clr-white;
        }
        color: $clr-white;
    }
}

body.frontofpage {
    .toolbar-wrapper {
        display: block;
    }
    .toolbar-toggle-icon {
        @include prefix((
          transform: rotate(0deg)
        ), webkit ms);

        &.rotate-half {
          @include prefix((
            transform: rotate(180deg)
          ), webkit ms);
        }
    }
}

.toolbar .section-divide {
    margin-top: 28px !important;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: visibility, opacity 0.3s ease-in-out;
    &.active{
        visibility: visible;
        opacity: 1;
    }
}

.inner-addon .wilo-icon {
    position: absolute;
    padding: 11px;
    pointer-events:auto;
    background-color: $clr-text-darkblue;
    color: $clr-white;

    &.icon-search {
      cursor: pointer;
    }
}

.left-addon .wilo-icon {
    left: 0;
}

.searchbar-wrapper {
    form {
        margin-bottom: 0;
    }
}

.right-addon .wilo-icon {
    right: 0;
}

.left-addon input {
    padding-left: 30px;
}

.right-addon input {
    padding-right: 30px;
}


/* ===============
       Wishlist
    =============== */

.wishlist-wrapper {
    cursor: pointer;
}

.wishlist-text {
    margin-left: 5px;
}

body.frontofpage .toolbar-toggle-icon {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

body.frontofpage .toolbar-toggle-icon.rotate-half {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
