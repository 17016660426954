#my-wilo-content table {
    border-collapse: separate;
}

.table.table-grey {
    > thead > tr > th {
        color: #03998a;

        &:first-child {
            padding-left: 0;
        }
    }

    > tbody > tr {
        > td, > th {
            background: $clr-crystal-white;
            border-color: white;
            padding: 18px 22px;
            color: #777777;
        }

        &:first-child th,
        &:first-child td {
            position: relative;
            border-top: 3px solid $clr-white;
            &:before {
                content: '';
                position: absolute;
                top: -6px;
                left: 0;
                width: 100%;
                background: $clr-wilo-green;
                height: 3px;
            }
        }

        > th {
            color: #03998a;
        }

        > td.td-dark-grey {
            background: $clr-text-darkblue;
            color: white;
            transition: 0.15s all ease-in-out;
            cursor:pointer;
            padding:0;
            a {
                color: white;
                padding: 18px 22px;
                display:block;
            }
            &:hover {
                background: $clr-wilo-green;
                cursor:pointer;
            }
        }

        > td.td-white {
            background: white;
        }
    }

    &.table-bordered {
        border: 0;

        .no-border-left {
            border-left: 0;
        }

        .no-border-right {
            border-right: 0;
        }

        > thead > tr > th,
        > tbody > tr > td,
        > tbody > tr > th {
            border-width: 3px;
            border-color: white;
        }

        > thead > tr:last-child > th {
            border-bottom: 0 solid transparent;
        }
    }
}
