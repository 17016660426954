.survey-module {
    .survey-module-question-container,
    .survey-module-success-message-container {
        background: #f1f1f1;
        padding: 30px;
        margin-bottom: 30px;
    }

    .question-counter {
        font-family: $NotoBold;
        font-size: 18px;
        margin-bottom: 25px;
        margin-top: 0;
    }

    .question-title {
        @extend .question-counter;
        margin-bottom: 20px;
        @include font-size($font-size-base);

        @include bp-max-smartphone {
            margin-top: 20px;
        }
    }

    @include bp-max-smartphone {
        .form .submit-button {
            margin-top: 20px;
        }
    }

    .survey-module-success-message-container {
        padding: 90px 30px;

        .success-message {
            background: url(/resources/img/survey-module-success-icon.png) no-repeat top center;
            text-align: center;
            padding-top: 120px;
            font-family: $NotoBold;
        }
    }
}
