/** TODO: compare to actual implementation **/
.teaser__download-teaser {
  position: relative;
  padding-top: 90%;
  background-color: #e9eaea;
  color: white;

  .teaser-inner {
    display: block;
    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    h3 {
      font-size: 1.5rem;
      font-family: $NotoBold;
      text-transform: uppercase;
      color: white;
      &.color-dark {
        color: #363640;
      }
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.25rem;
      color: white;
      &.color-dark {
        color: #363640;
      }
    }

    .download-list {
      list-style: none;
      padding-left: 12px;

      li {
        position: relative;
        padding: 6px 0 0 12px;

        &:before {
          content: "\E8D2";
          font-family: $WiloIcons;
          position: absolute;
          display: inline-block;
          left: -12px;
          color: #009c82;
        }

        &:first-child {
          padding-top: 12px;
        }
      }

      a {
        color: #009c82;
        font-size: 15px;
      }

      i {
        font-size: 22px;
        padding-right: 12px;
      }
    }
  }
}
