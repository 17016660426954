.table-module {
    width: 100%;
    overflow: auto;
    table {
        display: table;
        width: 100%;
        tr {
            td {
                padding: 15px;
                border: 3px solid $clr-white;
                text-align: center;
                min-width: 150px;
                @include bp-max-tablet {
                    min-width: 150px;
                    width: auto;
                    padding: 0px;
                    height: 75px;
                }
                a {
                    font-family: $NotoBold;
                }
                @media screen and (max-width: 498px) {
                    padding: 5px;
                }
                &.wilo-align-left {
                    text-align:left;
                }

                &.wilo-align-right {
                    text-align:right;
                }

                &.wilo-align-center {
                    text-align:center;
                }

                &.wilo-align-justify {
                    text-align:justify;
                }
            }
        }
        tbody {
            tr {
                td {
                    background-color: #f1f1f1;
                    border-top: 3px solid $clr-wilo-green;
                    a,
                    p {
                        color: $clr-wilo-green;
                        margin: 10px;
                    }
                }
            }
        }
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
    }
    thead {
        th {
            border-bottom: none;
            padding: 0 15px 15px 15px;
            font-family: $NotoBold;
            color: $clr-wilo-green;
            text-align: center;
            &.wilo-align-left {
                text-align:left;
            }
            &.wilo-align-right {
                text-align: right;
            }
        }
    }
}
