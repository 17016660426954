// Index Page (Menu Overview)
.joy-of-img {
  &:hover {
    figure {
      img {
        // opacity: 0.6;
        @include prefix(( transform: scale(1.15)), webkit moz ms o);
      }
    }
  }
  figure {
    position: relative;
    float: left;
    overflow: hidden;
    background: $clr-white;
    text-align: center;
    cursor: pointer;
    @include bp-max-smartphone {
      display: inline-block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
    img {
      position: relative;
      opacity: 1;
      transition: all .5s ease-in-out;
    }
  }
}

.button-module.page-module {
  div {
    &:last-child {
      .wilo-btn {
        margin-bottom: 0;
      }
    }
    .wilo-btn {
      margin-bottom: 5px;
    }
  }
}

.joy-of-img {
  overflow: hidden;
  &:hover {
    img {
      transform: scale(1.15);
    }
  }
  img {
    transition: all .5s ease-in-out;
  }
}
