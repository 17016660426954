@import "~@wilo/common/css/fonts.css";
@import "~@wilo/common/css/icons.css";
// Wilo korea fonts

@font-face {
  font-family: 'NotoSansKrBold';
  src: url('#{$wilo-font-path}notosanskr-bold.woff2') format('woff2'),
    url('#{$wilo-font-path}notosanskr-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansKrRegular';
  src: url('#{$wilo-font-path}notosanskr-regular.woff2') format('woff2'),
    url('#{$wilo-font-path}notosanskr-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansKrLight';
  src: url('#{$wilo-font-path}notosanskr-light.woff2') format('woff2'),
    url('#{$wilo-font-path}notosanskr-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Wilo Fonts

@font-face {
  font-family: 'WILOPlusFMRegular';
  src: url(#{$wilo-font-path}WILOPlusFMReg.woff);
}

@font-face {
  font-family: 'WILOPlusFMBold';
  src: url(#{$wilo-font-path}WILOPlusFMBol.woff);
}

@font-face {
  font-family: 'WILOPlusFMBoldItalic';
  src: url(#{$wilo-font-path}WILOPlusFMBolIta.woff);
}

@font-face {
  font-family: 'WILOPlusFMItalic';
  src: url(#{$wilo-font-path}WILOPlusFMIta.woff);
}

$NotoSans: "Noto Sans", sans-serif;
$NotoBold: "NotoBold", sans-serif;
$NotoSemiBold: "NotoSemiBold", sans-serif;
$NotoSemiBoldItalic: "NotoSemiBoldItalic", sans-serif;
$NotoLight: "NotoLight", "Noto Sans", sans-serif;
$NotoLightItalic: "NotoLightItalic", "Noto Sans", sans-serif;
$NotoBoldItalic: "NotoBoldItalic", sans-serif;
$NotoItalic: "NotoItalic", sans-serif;
$NotoSansKrBold: "NotoSansKrBold", sans-serif;
$NotoSansKrRegular: "NotoSansKrRegular", sans-serif;
$NotoSansKrLight: "NotoSansKrLight", sans-serif;
$WILOPlusFMRegular: "WILOPlusFMRegular", sans-serif;
$WILOPlusFMBold: "WILOPlusFMBold", sans-serif;
$WILOPlusFMBoldItalic: "WILOPlusFMBoldItalic", sans-serif;
$WILOPlusFMItalic: "WILOPlusFMItalic", sans-serif;
$WiloIcons: "wilo-icons", sans-serif;


// Icon Font on Fontello
// @font-face {
//   font-family: $WiloIcons;
//   src: url(#{$wilo-font-path}wilo-icons.eot?tupp9o);
//   src: url(#{$wilo-font-path}wilo-icons.eot?tupp9o#iefix)
//       format('embedded-opentype'),
//     url(#{$wilo-font-path}wilo-icons.woff2?tupp9o) format('woff2'),
//     url(#{$wilo-font-path}wilo-icons.ttf?tupp9o) format('truetype'),
//     url(#{$wilo-font-path}wilo-icons.woff?tupp9o) format('woff'),
//     url(#{$wilo-font-path}wilo-icons.svg?tupp9o#wilo-icons) format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@import 'iconmoon-font/style';
