@use "sass:math";

.display-none {
  display: none;
}

.position-relative {
  position: relative;
}

.three-column-gallery {

  ul.glide__slides {
    margin: 0;
  }
  
  button {
    box-sizing: border-box;
    border-width: 0;
    border-color: currentColor;
  }

  .glide__slide {

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      img {
        opacity: 0.3;
      }
      .overlay {
        opacity: 1;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.3s ease;
      pointer-events: none;
      z-index: 10;

      .magnifying-glass {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        &::before {
          border-radius: 100%;
          content: "\e90f";
          font-family: $WiloIcons !important;
          background-color: #363640;
          color: white;
          width: 3rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .gallery__slide--uninitialized {
    width: 33%;
    margin: 0 10px;
  }

  @media (max-width: 1000px) {
    .gallery__slides--uninitialized {
      flex-wrap: wrap;
    }
    .gallery__slide--uninitialized {
      width: 46%;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 600px) {
    .gallery__slides--uninitialized {
      display: block;
    }
    .gallery__slide--uninitialized {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  $glide-arrow-size: 40px;
  $container-padding: 24px;

  .glide__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $glide-arrow-size;
    height: $glide-arrow-size;
    background: rgba(54, 54, 64, 0.9);
    border-radius: 100%;


    &-left,
    &-right {
      line-height: $glide-arrow-size;
      color: #fff;
      font-size: 1rem;
      font-family: $WiloIcons !important;
    }

    &-left {
      left: #{math.div($glide-arrow-size, 2) - $container-padding};

      &::before {
        left: 0;
        content: "\e902";
      }
    }

    &-right {
      right: #{math.div($glide-arrow-size, 2) - $container-padding};

      &:before {
        content: "\e903";
      }
    }

    span::before {
      content: none;
    }
  }

  .modal {
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);

    &-content {
      // display: inline-block;
      position: relative;
      margin: auto;
      display: block;
      width: 80%;
      max-width: 1440px;

      &__container {
        display: none;
        position: relative;

        .image-wrapper {
          position: relative;

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }

          .slide-count {
            position: absolute;
            left: 50%;
            bottom: 1rem;
            transform: translateX(-50%);
            letter-spacing: 0.2rem;
            color: white;
            &.color-dark {
              color: black;
            }

            @media (max-width: 991px) {
              bottom: 0.5rem;
              font-size: 0.8rem;
              letter-spacing: 0.15rem;
            }
          }
        }
      }

      .caption {
        position: absolute;
        background-color: #333333;
        color: white;
        bottom: 3rem;
        left: 3rem;
        padding: 1.75rem;
        max-width: 40%;
        opacity: 0.95;
        font-size: 0.75rem;
        @media (max-width: 991px) {
          position: static;
          max-width: 100%;
          bottom: 0;
          left: 0;
          padding: 1rem;
        }
      }
    }

    .modal-controls {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;

      .modal-arrow {
        $arrow-dimension: 40px;
        position: absolute;
        width: $arrow-dimension;
        height: $arrow-dimension;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        background-image: none;
        padding: 0;
        

        @media (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        &.prev,
        &.next {
          &::before {
            border-radius: 100%;
            font-family: $WiloIcons !important;
            background-color: #363640;
            color: white;
            width: calc($arrow-dimension + 0.5rem);
            height: calc($arrow-dimension + 0.5rem);
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
              width: 34px;
              height: 34px;
              font-size: 0.8rem;
            }
          }
        }

        &.prev {
          left: 0;
          margin-left: -#{math.div($arrow-dimension, 2)};
          &::before {
            content: "\e902";
            font-family: $WiloIcons !important;
          }

          @media (max-width: 767px) {
            margin-left: -17px;
          }
        }

        &.next {
          right: 0%;
          margin-right: -#{math.div($arrow-dimension, 2)};
          &::before {
            content: "\e903";
            font-family: $WiloIcons !important;
          }

          @media (max-width: 767px) {
            margin-right: -17px;
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 15px;
      right: 35px;
      color: #f1f1f1;
      font-size: 40px;
      font-weight: bold;
      transition: 0.3s;
      opacity: 1;
    }

    .close:hover,
    .close:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
