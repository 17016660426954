.hero-teaser {
  background-color: #f1f1f1;
  margin-left: unset !important;
  margin-right: unset !important;
  width: 100%;
  height: 370px;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}

.hero-teaser-wrapper {
  height: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  background-color: #f1f1f1;
}

.hero-teaser-image {
  width: 50%;
  height: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > img {
    z-index: 99 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    width: 100%;
    height: 370px;
    -o-object-position: center;
    object-position: center;
    -o-object-fit: scale-down;
    object-fit: scale-down;
  }
}

.hero-teaser-text {
  width: 50% !important;
  position: relative;

  h2 {
    font-size: 36px !important;
  }

  p {
    font-weight: 300;
    font-size: 16px !important;
    padding-bottom: 25px;
    margin-bottom: 25px;
    white-space: pre-line;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-teaser-text {
    h2 {
      font-size: 24px !important;
    }
  }

  .hero-teaser-image {
    padding-top: 0;

    > img {
      -o-object-position: center;
      object-position: center;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .full-button {
    padding: 10px 20px 10px 10px;
    line-height: 16px;
    font-family: $NotoBold;
    position: relative;
    cursor: pointer;
    border: none;
    font-size: 16px;
    text-align: left;
    height: 38px;
    transition: 0.15s background-color ease-in-out;
    display: inline-block;
    margin: 0 5px 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hero-teaser-text {
    width: 100% !important;
    padding: 0 15px;
    -o-object-position: bottom;
    object-position: bottom;

    h2 {
      font-size: 18px !important;
    }

    p {
      font-size: 16px !important;
      margin:unset !important;
      white-space: pre-line;
    }
  }

  .hero-teaser-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-teaser-image {
    max-width: 39vw !important;
    padding-top: 0 !important;

    > img {
      width: 200% !important;
      height: 25vmin;
      -o-object-position: center;
      object-position: center;
      margin-top: unset;
    }
  }
}

@media (max-width: 319px) {
  .full-button {
    padding: 10px 20px 10px 10px;
    line-height: 16px;
    font-family: $NotoBold;
    position: relative;
    cursor: pointer;
    border: none;
    font-size: 16px;
    text-align: left;
    height: 38px;
    transition: 0.15s background-color ease-in-out;
    display: inline-block;
    margin: 0 5px 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hero-teaser-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-teaser-image {
    padding-top: 0 !important;
    
    > img {
      width: 200% !important;
      height: 15vmin;
      -o-object-position: center;
      object-position: center;
      margin-top: unset;
    }
  }

  .hero-teaser-text {
    width: 100% !important;
    padding: 0 15px;
    -o-object-position: bottom;
    object-position: bottom;

    h2 {
      font-size: 18px !important;
      margin: unset !important;
    }

    p {
      font-size: 16px !important;
      margin: unset !important;
    }
  }
}