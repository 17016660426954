// Footer

// Frontpage rm Margin
.frontofpage {
  footer {
    margin-top: 0px;
  }
}

// General Footer Stylings
footer {
  font-family: $NotoSans;
  @include font-size(15px);
  background-color: $clr-text-darkblue;
  color: #c3c3c6;
  padding: 50px 75px;

  hr {
    border-top: 1px solid $clr-super-navigation-grey;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      font-size: 15px;
      padding-bottom: 12px;
      list-style: none;
      text-decoration: none;
    }
  }

  a,
  button {
    color: $clr-lighter-grey;

    &:hover,
    &:focus {
      color: $clr-white;
    }
  }

  button {
    text-decoration: none !important;
    border: none;
    background-color: transparent;
  }
}

// Main Footer Stylings
.main-footer {
  display: flex;
  flex-direction: row;
  padding-top: 55px;
  margin-bottom: 40px;

  ul > :last-child > li {
    padding-bottom: 0px;
  }

  ul > a,
  button > li {
    line-height: 1.1;
    padding-bottom: 7px;
    margin-bottom: 12px;
  }

  a.main-footer__header {
    opacity: unset;
  }

  a.main-footer__header:hover {
    color: $clr-lighter-grey;
    opacity: 0.7;
  }

  &__contact {
    margin-left: 25px;
    margin-right: 50px;
    min-width: 200px;

    &--main {
      font-size: 36px;
      margin-bottom: 24px; // same size as logo before
    }

    &--sub {
      margin-bottom: 5px;
      margin-top: 11px;
      font-family: NotoBold,sans-serif;;
    }
  }

  &__sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
  }

  &__section {
    padding-right: 0px;
  }

  &__header {
    color: $clr-crystal-white;
    font-family: $NotoBold;
    cursor: pointer;
    overflow: hidden;
  }

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    margin-top: 22px;

    @include bp-tablet {
      gap: 18px;
    }

    @include bp-max-desktop {
      margin: 0;
    }

    &-icon {
      height: 32px;
      width: 32px;
    }

    &-item {
      padding-bottom: 0px;

      a,
      img {
        transform: scale3d(1, 1, 1);
        transition: all 0.8s ease-in-out;
        opacity: 1;
        color: #f1f1f1;

        &:hover,
        &:focus {
          color: $clr-lighter-grey;
          transform: scale3d(0.9, 0.9, 0.9);
          opacity: 0.7;
        }
      }
    }
  }
}

// Sub Footer Stylings
.sub-footer {
  margin: 40px 0 20px 0;
  align-items: center;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;

  @include bp-smartphone-only {
    margin: 0px;

    a,
    button {
      opacity: 1;
    }
  }

  @include bp-max-smartphone {
    margin-top: 30px;
  }

  @include bp-max-desktop {
    ul:first-child {
      margin-bottom: 30px;
    }
  }

  @include bp-desktop-large {
    flex-direction: row;
  }

  ul {
    float: right;
    color: $clr-white;

    li {
      display: inline-block;
    }
  }

  &__entry {
    font-family: $NotoBold;
    margin-left: 25px;

    @include bp-smartphone-only {
      margin-left: 0px;
      font-size: 18px;
      width: 100%;
      padding: 25px 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      &:first-child {
        border-top: 0;
      }

      a,
      button {
        color: #c3c3c6;
        font-family: $NotoBold;
      }
    }
  }
}


@media (max-width: 767px) {
  .footer hr {
    display: none;
  }
}

@media (max-width: 991px) {
  .main-footer {
    display: none;
  }

  footer > .container {
    padding: 30px 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-footer__header {
    min-height: 38px;
  }
}
