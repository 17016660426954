.countdown-container {
    text-align: center;
    margin-top: 30px;

    .countdown-title {
        font-size: 36px;
        margin: 20px 0;
        font-weight: bold;
    }

    .countdown-info {
        p {
            font-size: 24px;
        }
    }

    .countdown-circles {
        margin: 35px 0 15px 0;

        .circle {
            display: inline-block;
            margin-right: 12px;
            margin-left: 13px;
            margin-bottom: 15px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

           @media(max-width: 381px) {
                &.third {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                }
            }

            .circle-value {
                font-size: 28px;
                display: inline-block;
                width: 72px;
                height: 72px;
                padding-top: 17px;
                border-radius: 50%;
                box-shadow: 0 0 0 3px #009a8b;
                text-align: center;
                border: 3px #fff solid;
                color: #fff;
                padding-left: 1px;
                background: #009a8b;
                margin-bottom: 10px;
            }
        }
    }
}