.ecolution {
    body {
        scroll-snap-align: start;
    }
    .footer {
        scroll-snap-align: end;
    }
    

    .content {
        margin-bottom: 0;
        
        
        .gradient-bg {
            background: linear-gradient(0deg, #009C82 6.56%, #03314B 92.95%);
            padding-top: 64px;
            --text-color: #{$clr-white};

            @media #{$desktop} {
                padding-top: 120px;
            }
        }
    }

    .webforms-form {
        color: var(--text-color);
        padding-bottom: 56px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 750px;
        @media #{$desktop} {
            max-width: 970px;
        }

        @media #{$desktop-large} {
            max-width: 1170px;
        }
        
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: var(--text-color); 
        }

        .mwf-form__actions-next {
            .wilo-btn.wilo-btn-primary {
                background-color: $clr-white !important;
                color: #363640 !important;
                display: flex;
                align-items: center;
                
                &::before {
                    display: none;
                }
            }
        }

        .mwf-form {
            margin-top: -56px;
            max-width: unset;
        }

        .mwf-layout, 
        .mwf-form__actions {
            max-width: 750px;
        }

        .select2-selection.select2-selection--single {
            background-color: $clr-white;
            color: #363640;

            .select2-selection__arrow {
                background: url('/resources/img/dropdown-arrow.svg') no-repeat center;
            }
        }

        .mwf-select {
            flex-flow: column-reverse nowrap;
        }

        .mwf-fieldset__options {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .mwf-checkbox__input {
                display: none;
            }

            .mwf-checkbox__text {
                &::before {
                    display: none;
                }
            }

            .mwf-radio {
                background: #fff;
                padding: 4px;
                color: #363640;
                max-width: 100%;

                &:before {
                    display: block;
                    content: '';
                    max-width: 100%;
                    width: 352px;
                    aspect-ratio: 16 / 8;
                    margin-bottom: 8px;
                }

                &__text::before {
                    display: none;
                }

                &:nth-child(1) {
                    &:before {
                        background: url('/resources/img/ecolution_radio_1.png') no-repeat center;
                    }
                }
                &:nth-child(2) {
                    &:before {
                        background: url('/resources/img/ecolution_radio_2.png') no-repeat center;
                    }
                }
                &:nth-child(3) {
                    &:before {
                        background: url('/resources/img/ecolution_radio_3.png') no-repeat center;
                    }
                }
            }

        }

        .mwf-textinput__input {
            color: #363640;
            -webkit-text-fill-color: #363640 !important; // fixing edge browser autofill bug
            font-weight: 600;
        }
        .mwf-textinput__input:-webkit-autofill,
        .mwf-textinput__input:-webkit-autofill:hover,
        .mwf-textinput__input:-webkit-autofill:focus {
            -webkit-text-fill-color: #363640 !important;
            -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
        }
        .mwf-paragraph {
            padding-top: 56px;

            p:first-child {
                font-size: 22px;
                line-height: 32px;
            }
        }

        .mwf-row {
            margin-bottom: 0;

            > div {
                margin-bottom: 24px;
            }
        }

        .mwf-checkbox__text {
            a {
                color: var(--text-color);
                text-decoration: underline !important;
            }
        }

        .mwf-file {
            &__uploaded-actions {
                display: none;
            }

            &__li {
                display: grid;
                grid-template-columns: auto 1fr auto;
                grid-template-areas: 'icon info button'
                                     'progress progress progress';
                justify-content: flex-start;
                align-items: center;
                column-gap: 16px;
            }


            &__file-progress {
                grid-area: progress;
                border-bottom: 1px solid rgba(255,255,255, 0.5);
                margin: 0 24px 0 4px;

                div {
                    font-size: 0px;
                    border-bottom: 3px solid white;
                }
            }

            &__file-info {
                display: grid;
                grid-area: info;
                align-items: center;
                grid-template-areas: 'name name size'
                                    'error error size';
            }

            &__file-name {
                grid-area: name;
            }

            &__file-size {
                grid-area: size;
                text-align: end;
            }

            &__file-error {
                grid-area: error;
            }

            &__thumbnail {
                grid-area: icon;
                img,
                canvas {
                    display: none;
                }

                &:before {
                    display: block;
                    content: url('/resources/img/image_icon.svg');
                    height: 24px;
                }
            }

            &__file-actions {
                grid-area: button;
            }

            &__file-button {
                font-size: 0;
                background-color: transparent !important;
                padding-bottom: 0;
                padding-top: 0;

                &:after {
                    display: inline-block;
                    content: url('/resources/img/delete_icon.svg');
                }
            }

            &__dropzone {
                padding: 24px;
                border: 2px dashed white;
                background: #009c82;
                border-radius: 16px;
                display: flex;
                justify-content: stretch;

                .mwf-file__button {
                    background: transparent;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 16px;

                    .wilo-btn {
                        margin-left: auto;
                        text-wrap: wrap;
                        line-height: 24px;
                        height: auto;
                    }

                    &:before {
                        display: inline-block;
                        content: url('/resources/img/upload_icon.svg');
                        height: 32px;
                    }
                }
            }


        }
        
        @media #{$desktop} {
            .mf-form {
                margin-top: -72px;
            }

            .mwf-paragraph {
                padding-top: 72px;
            }
            .mwf-row {
                margin-bottom: 40px;
    
                > div {
                    margin-bottom: unset;
                }
            }
            .mwf-fieldset__options {
                flex-direction: row;
                flex-wrap: wrap;
                width: 940px;
            }
        }

        @media #{$desktop-large} {
            .mwf-fieldset__options {
                flex-wrap: nowrap;
            }
        }
    }

}