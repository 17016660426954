@import '../general_vars.scss';

.fs--sm,
.hl--sm,
.input,
textarea {
  font-size: 0.875rem;
}

.fs--md,
.hl--md,
.btn {
  font-size: 1rem;
}

.fs--lg,
.hl--lg {
  font-size: 1.25rem;
}

.fs--xl,
.hl--xl {
  font-size: 1.5rem;
}

.fs--xxl,
.hl--xxl {
  font-size: 2rem;
}

.fs--xxxl {
  font-size: 3rem;
}

.fw--r {
  font-weight: 400;
}

.fw--b,
.hl {
  font-weight: 700;
}

.lh--1 {
  line-height: 1;
}

@media (max-width: 575.98px) {
  .fs--xxl,
  .hl--xxl,
  .fs--xl,
  .hl--xl {
    font-size: 1.25rem;
  }
  .fs--xxxl {
    font-size: 1.5rem;
  }
}

.m--0 {
  margin: 0;
}

.m--1 {
  margin: 7.5px;
}

.m--2 {
  margin: 15px;
}

.m--4 {
  margin: 30px;
}

.mt--0 {
  margin-top: 0;
}

.mt--1 {
  margin-top: 7.5px;
}

.mt--2 {
  margin-top: 15px;
}

.mt--4 {
  margin-top: 30px;
}

.mr--0 {
  margin-right: 0;
}

.mr--1 {
  margin-right: 7.5px;
}

.mr--2 {
  margin-right: 15px;
}

.mr--4 {
  margin-right: 30px;
}

.mb--0 {
  margin-bottom: 0;
}

.mb--1 {
  margin-bottom: 7.5px;
}

.mb--2 {
  margin-bottom: 15px;
}

.mb--4 {
  margin-bottom: 30px;
}

.ml--0 {
  margin-left: 0;
}

.ml--1 {
  margin-left: 7.5px;
}

.ml--2 {
  margin-left: 15px;
}

.ml--4 {
  margin-left: 30px;
}

@media (min-width: 768px) {
  .mb--md0 {
    margin-bottom: 0;
  }
  .mr--md2 {
    margin-right: 15px;
  }
}

.p--0 {
  padding: 0;
}

.p--1 {
  padding: 7.5px;
}

.p--2 {
  padding: 15px;
}

.p--4 {
  padding: 30px;
}

.pt--0 {
  padding-top: 0;
}

.pt--1 {
  padding-top: 7.5px;
}

.pt--2 {
  padding-top: 15px;
}

.pt--3 {
  padding-top: 22.5px;
}

.pt--4 {
  padding-top: 30px;
}

.pr--0 {
  padding-right: 0;
}

.pr--1 {
  padding-right: 7.5px;
}

.pr--2 {
  padding-right: 15px;
}

.pr--4 {
  padding-right: 30px;
}

.pb--0 {
  padding-bottom: 0;
}

.pb--1 {
  padding-bottom: 7.5px;
}

.pb--2 {
  padding-bottom: 15px;
}

.pb--4 {
  padding-bottom: 30px;
}

.pl--0 {
  padding-left: 0;
}

.pl--1 {
  padding-left: 7.5px;
}

.pl--2 {
  padding-left: 15px;
}

.pl--4 {
  padding-left: 30px;
}

.bc--primary {
  background-color: #009c82;
}

.bc--secondary {
  background-color: #363640;
}

.bc--lightgrey {
  background-color: #f1f1f1;
}

.fc--primary {
  color: #009c82;
}

.fc--secondary {
  color: #363640;
}

.fc--lightgrey {
  color: #f1f1f1;
}

.fc--grey {
  color: #ddd;
}

.fc--white {
  color: #fff;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.p--r {
  position: relative;
}

.jc--sb {
  justify-content: space-between;
}

.jc--fe {
  justify-content: flex-end;
}

.jc--c {
  justify-content: center;
}

.ai--bl {
  align-items: baseline;
}

.ai--fs {
  align-items: flex-start;
}

.ai--c {
  align-items: flex-start;
}

.push {
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

@media (max-width: 991.98px) {
  .push--nav {
    -webkit-transform: translateX($side-menu-width-negative);
    transform: translateX($side-menu-width-negative);
  }

  @media (min-width: $side-menu-width-max-bp) {
    .push--nav {
      -webkit-transform: translateX($side-menu-width-max-negative);
      transform: translateX($side-menu-width-max-negative);
    }
  }
}

.stretch--w {
  width: 100%;
}

.stretch--h {
  height: 100%;
}

.stretch--hMargin {
  height: calc(100% - 30px);
}

.cover {
  background-size: cover;
  background-position: center;
}

.ta--c {
  text-align: center;
}

.text:before {
  font-family: $WiloIcons !important;
  font-size: 0.625rem;
  padding-right: 10px;
  vertical-align: top;
  line-height: 1.5rem;
}

.text--chevron:before {
  content: "\E903";
}

.text--arrow:before {
  content: "\E92F";
}

.list {
  padding: 0;
}

.list__item {
  list-style-type: none;
  margin-bottom: 7.5px;
  line-height: 1.5;
}

.list__item--inline {
  margin-bottom: 0;
  line-height: 1;
}

.list__item--large {
  margin-bottom: 15px;
}

.list__item:last-child {
  margin: 0;
}

.list__item--big {
  margin-bottom: 22.5px;
  padding-left: 22.5px;
  font-weight: 700;
}

.list__green:before {
  color: #009c82;
  font-size: 12px;
  display: inline-flex;
  vertical-align: middle;
  margin-left: -22.5px;
}

.link__chevron {
  font-size: 12px;
}

.teaser {
  position: relative;
  display: block;
}

.teaser:before {
  content: '';
  display: block;
  padding-top: 85%;
}

.teaser:hover .teaser__img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.teaser--news {
  background-color: #009c82;
}

.teaser--blog {
  background-color: #787878;
}

.teaser--training {
  background-color: #ffb400;
}

.teaser--craft:before {
  padding-top: 60%;
}

.teaser--lg:before {
  padding-top: calc(85% + (7.5px/2));
}

.teaser__inner {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.teaser__info {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  color: #363640;
  min-width: 80%;
  padding: 7.5px 15px;
  margin-bottom: 0;
}

.teaser__info1:after {
  content: ' |';
}

.teaser__imgWrapper {
  position: relative;
  overflow: hidden;
}

.teaser__imgWrapper--sm {
  height: 56.25%;
}

.teaser__imgWrapper--lg {
  height: calc(78.125% + 7.5px);
}

.teaser__imgWrapper--full {
  height: 100%;
}

.teaser__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: 0.5s -webkit-transform ease-in-out;
  transition: 0.5s transform ease-in-out;
  transition: 0.5s transform ease-in-out, 0.5s -webkit-transform ease-in-out;
}

.teaser__content {
  padding: 22.5px 15px;
  height: 43.75%;
}

.teaser__content--headline {
  display: flex;
  align-items: flex-start;
  line-height: 1.1;
  color: #fff;
  margin-bottom: 0;
}

.teaser__content--text, p.teaser__content--text {
  margin-top: 7.5px;
  margin-bottom: 0;
  color: #fff;
}

.teaser__content--info {
  padding: 15px 15px 15px 37.5px;
  bottom: 30px;
  background-color: rgba(0, 156, 130, 0.9);
}

.teaser__content--md {
  padding: 22.5px 22.5px 22.5px 52.5px;
}

.teaser__content--plain {
  padding: 22.5px;
}

.teaser__content--lg {
  height: 21.875%;
}

.teaser__arrow {
  font-size: 0.875rem;
  margin-top: 5px;
  margin-left: -22.5px;
  margin-right: 7.5px;
}

.teaser__arrow--large {
  font-size: 1rem;
  margin-top: 10px;
}

@media (max-width: 1199.98px) {
  .teaser:before {
    padding-top: 100%;
  }
  .teaser__imgWrapper--sm {
    height: 46.875%;
  }
  .teaser__imgWrapper--lg {
    width: 100%;
    padding-top: 75%;
  }
  .teaser__content {
    padding: 15px;
    height: 53.125%;
  }
  .teaser__content--headline {
    font-size: 1.5rem;
    word-break: break-all;
  }
  .teaser__content--info {
    height: auto;
    padding: 15px 15px 15px 45px;
  }
  .teaser__content--plain {
    padding: 22.5px;
  }
  .teaser__content--large {
    height: 25%;
  }
  .teaser__arrow {
    font-size: 0.875rem;
    margin-top: 5px;
  }
  .teaser__img {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 768px) {
  .teaser__content--info {
    height: auto;
    position: absolute;
    left: 0;
    max-width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .teaser__inner {
    display: flex;
  }
  .teaser__imgWrapper--sm {
    padding-top: 22.5%;
    width: 45%;
  }
  .teaser__imgWrapper--full {
    padding-top: 35%;
    width: 100%;
  }
  .teaser__info--bottom {
    top: 0;
    bottom: auto;
  }
}

@media (max-width: 991.98px) {
  .teaser:before {
    padding-top: 0;
  }
  .teaser__inner {
    position: static;
  }
}

@media (max-width: 767.98px) {
  .teaser__imgWrapper--sm,
  .teaser__imgWrapper--full {
    padding-top: 56.25%;
  }
  .teaser__content {
    padding: 15px;
  }
  .teaser__content--info {
    padding-left: 37.5px;
  }
  .teaser__content--headline {
    font-size: 1.25rem;
  }
}

.tgTeaser {
  height: 150px;
  display: flex;
  align-items: center;
  padding-left: 10%;
  &:hover,
  &:active {
    color: #009c82;
  }
}

.tgTeaser__text {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .tgTeaser {
    height: 75px;
    background-color: #009c82;
    color: #fff;
    background-image: none !important;
    padding-left: 15px;
  }
  .tgTeaser__text:before {
    font-size: 1rem;
  }
}

.footer__main {
  background-color: #363640;
  padding: 45px 15px;
  .d-none {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.footer__title {
  color: #ddd;
  font-size: 1.25rem;
  margin-bottom: 22.5px;
  line-height: 1.2;
}

.footer__item {
  color: #b5b5b5;
  margin-bottom: 7.5px;
}

.footer__item:last-child {
  margin-bottom: 0;
}

.footer__item:hover {
  color: #ddd;
}

.footer__arrow {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  color: #fff;
}

.footer__sub {
  background-color: #2a2a32;
  padding: 22.5px 0;
  color: #b5b5b5;
}

.footer__subInner {
  display: flex;
  justify-content: space-between;
}

.footer__subItem {
  margin: 0 15px 0 0;
}

.footer__subItem:last-child {
  margin: 0;
}

@media (max-width: 991.98px) {
  .footer__subInner {
    flex-direction: column;
    align-items: center;
  }
  .footer__subList {
    flex-flow: wrap;
  }
}

.stage__slide {
  width: 100vw;
}

.stage__inner {
  height: 30vw;
  position: relative;
}

.stage__title {
  position: absolute;
  bottom: 50px;
  height: auto;
  width: auto;
  background-color: #363640;
  color: #fff;
  padding: 30px;
}

// Stage
.tgStage {
  height: 400px;
}

.tgStage__element {
  position: relative;
  overflow: hidden;
  transition: 0.3s colo ease-in-out;
  height: 100%;
}

@media (max-width: 767px) {
  .tgStage__element {
    height: 50%;
  }
}

.tgStage__element:hover::before {
  opacity: 0.5;
}

.tgStage__element:hover .tgStage__title {
  color: #fff;
}

.tgStage__element::before {
  transition: 0.3s opacity ease-in-out;
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #009c82;
  opacity: 0;
}

.tgStage__title {
  position: absolute;
  top: 30%;
  left: 10%;
  display: flex;
  align-items: center;
}

.tgStage__title:before {
  font-size: 1.5rem;
}

.tgStage__nav {
  position: absolute;
  top: calc(30% + 45px + 3rem);
  left: calc(10% + 34px);
  padding: 0;
  list-style-type: none;
}

.tgStage__navItem {
  transition: 0.3s margin-left ease-in-out, 0.2s opacity ease-in-out;
  margin-left: -300px;
  opacity: 0;
  &>a {
    color: #fff;
  }
}

.tgStage__navItem--active {
  margin-left: 0;
  opacity: 1;
}

@media (max-width: 991.98px) {
  .tgStage {
    height: 400px;
  }
  .tgStage__element {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tgStage__element:before {
    opacity: .5;
  }
  .tgStage__nav,
  .tgStage__title {
    position: relative;
    z-index: 10;
  }
  .tgStage__title {
    font-size: 2rem;
    top: 0;
  }
  .tgStage__title:before {
    font-size: 1rem;
  }
  .tgStage__nav {
    top: 0;
    left: calc(10% + 26px);
  }
  .tgStage__navItem {
    margin-left: 0;
    opacity: 1;
  }
}

@media (max-width: 767.98px) {
  .tgStage {
    height: 60vw;
    min-height: 500px;
  }
  .tgStage__element {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tgStage__element:before {
    opacity: .5;
  }
}

@media (max-width: 575.98px) {
  .tgStage {
    height: 60vw;
    min-height: 400px;
  }
  .tgStage__title {
    font-size: 1.25rem;
    margin-bottom: 7.5px;
    top: 0;
  }
  .tgStage__title:before {
    font-size: 11px;
  }
  .tgStage__nav {
    left: calc(10% + 21px);
  }
  .tgStage__navItem {
    font-size: 1.125rem;
  }
}

.image__large {
  display: block;
}

.btn {
  padding: 11px 20px 11px 13px !important;
  display: inline-block;
  transition: .15s background-color ease-in-out, .15s border ease-in-out;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  border: none;
  line-height: 1;
  color: #fff;
  background-color: #363640;
  font-weight: 700;
  border-radius: 0;
}

.btn:hover,
.btn:focus,
.btn:active {
  background-color: #009c82;
  outline: 0;
}

.btn:disabled {
  cursor: default;
  font-style: italic;
  font-weight: 400;
}

.btn:disabled,
.btn:disabled:hover {
  background-color: #ddd;
  color: #505050;
}

.btn:before {
  font-family: $WiloIcons;
  content: '\E903';
  margin-right: 7.5px;
  font-size: 12px;
}

.btn--xl {
  padding: 15px;
  display: block;
}

.btn--xl:before {
  margin-right: 15px;
  font-size: 1rem;
  vertical-align: top;
  line-height: 1.5rem;
}

.btn--grey {
  color: #fff;
  background-color: #505050;
}

.btn--grey:hover,
.btn--grey:focus,
.btn--grey:active {
  background-color: #434343;
}

.btn--yellow {
  color: #363640;
  background-color: #ffb400;
}

.btn--yellow:hover,
.btn--yellow:focus,
.btn--yellow:active {
  background-color: #e6a200;
}

.btn--white {
  background-color: #fff;
  color: #363640;
}

.btn--white:hover,
.btn--white:focus,
.btn--white:active {
  background-color: #009c82;
  border-color: #009c82;
  color: #fff;
}

.btn--green, a.btn--green {
  color: #fff;
  background-color: #009c82;
}

.btn--green:hover,
.btn--green:focus,
.btn--green:active {
  background-color: #363640;
  color: #fff;
}

.btn--none:before {
  display: none;
}

.btn--shop:before {
  content: '\E923';
}

.btn--back:before {
  content: '\E902';
}

.btn--upload:before {
  content: '\E94E';
}

.input,
textarea {
  display: block;
  height: 38px;
  padding: 7.5px 15px;
  color: #505050;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  width: 100%;
}

.input:focus,
textarea:focus {
  border-color: #009c82;
  box-shadow: none;
  outline: 0;
}

.input:disabled,
textarea:disabled {
  cursor: default;
  background-color: #f1f1f1;
  box-shadow: none;
  border: 1px solid #f1f1f1;
  font-style: italic;
}

.input--icon {
  padding-right: 40px;
}

.input--wrong,
.input--wrong:focus {
  border-color: #f54100;
}

.input--right,
.input--right:focus {
  border-color: #009c82;
}

::-moz-placeholder {
  color: #ddd;
}

::-webkit-input-placeholder {
  color: #ddd;
}

::-ms-input-placeholder {
  color: #ddd;
}

.input__wrapper {
  position: relative;
}

.input__icon:before {
  font-family: $WiloIcons;
  font-size: 1rem;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input__icon--wrong:before {
  content: '\E949';
  color: #f54100;
}

.input__icon--right:before {
  content: '\E910';
  font-size: 12px;
  color: #009c82;
}

// Fixes

.teaser__wrapper--full {
  display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
}

.teaser__image--full {
  padding: 30px;
}

.brand-footer {
  width: auto;
  opacity: 1;
  img {
    width: auto;
  }
}

.faq__teaser {
  font-family: $NotoBold;
}

.videoStage {
  padding: 0;
}
