.breadcrumb {
    background-color: $clr-white;
    padding: 0;
    margin: 0 0 30px 0;

    > li {
        display: inline-flex;
    }


    .breadcrumb-item {
        a {
            color: $clr-light-grey;
        }
        &.home-link a {
            font-style: italic;
            font-weight: 800;
            color: #9e9ea3;
        }
        &.active {
            color: $clr-light-grey;
        }
    }
    @include bp-max-tablet {
            display: none;
    }
}