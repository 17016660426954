.atcBox {
  background-color: $clr-white;
  border: 2px solid $clr-wilo-green;
  display: flex;
  flex-direction: row;
  position: relative;
  @include bp-max-smartphone {
    flex-direction: column;
  }
  .text-sub-headline,
  .text-text {
    margin-bottom: 0;
  }
  .add-to-cart-product,
  .add-to-cart-price {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
    padding: 28px;
    @include bp-max-tablet {
      padding: 18px;
    }
  }
  .add-to-cart-product {
    background-color: $clr-wilo-green;
    position: relative;
    flex-grow: 10;
    p {
      color: $clr-white;
    }
    .box-arrow {
      width: 0;
      height: 0;
      position: absolute;
      @include bp-max-smartphone {
        left: 48%;
        bottom: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $clr-wilo-green;
      }
      @include bp-tablet {
        right: -10px;
        left: auto;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $clr-wilo-green;
        border-right: inherit;
      }
    }
    .product-name {
      font-family: $NotoBold;
    }
  }
  .add-to-cart-price {
    @include bp-tablet {
      padding-left: 40px;
    }
    @include bp-desktop {
      padding-left: 60px;
    }
    .text-sub-headline {
      @include font-size(20px);
      line-height: 1.2;
      margin: 0;
    }
    .text-text {
      color: $clr-mega-menue-grey;
    }
  }
  .add-to-cart-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0 18px 18px 18px;
    flex-grow: 3;
    @include bp-smartphone {
      flex-direction: row;
      justify-content: space-between;
    }
    @include bp-tablet-only {
      flex-direction: column;
      padding-top: 18px;
    }
    @include bp-desktop {
      align-items: center;
      padding: 28px;
    }
  }
  .add-to-cart-counter {
    padding-bottom: 20px;
    @include bp-smartphone {
      padding-bottom: 0;
    }
    @include bp-tablet-only {
      padding-bottom: 10px;
    }
    .quantity-module {
      .button {
        display: flex;
        &.disabled {
          background-color: #eee;
          cursor: default;
        }
      }
      input {
        width: 50px;
      }
    }
  }
  .add-to-cart-btn {
    @include bp-max-s-smartphone {
      width: 100%;
    }
    @include bp-smartphone {
      width: auto;
    }
    @include bp-tablet-only {
      width: 100%;
    }
    @include bp-desktop {
      width: auto;
    }
    a {
      display: block;
      width: 100%;
    }
  }
}

.atcBox__load {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atcBox__loadImg {
  height: 50px;
}

.wilo-btn-check {
  position: relative;
  &:after {
    background-color: #009c82;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $WiloIcons;
    content: "\e910";
    font-size: 14px;
    margin: 0;
  }
}
