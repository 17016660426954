.news-teaser {
    // missing classes from corporate / bootstrap 4

    .my-3 {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .d-flex {
        display: flex;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @include bp-small {
        .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @include bp-medium {
        .col-lg-4 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

.news-teaser-tile {
    // additional / custom stylings

    .tile {
        &--image > img {
            aspect-ratio: 4/3;
        }
    }

    &.tile {
        width: 100%;
    }

    @media (min-width: 500px) and (max-width: 689px) {
        &.tile {
            padding-top: 0;
        }
    }
}