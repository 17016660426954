// Paths
$wilo-font-path: "../fonts/";
$wilo-image-path: "../img/";

@import "global-wilo-colours";

// Media Queries

// Min-Width
$smartphone: "(min-width: 480px)";
$tablet: "(min-width: 768px)";
$desktop: "(min-width: 992px)";
$desktop-large: "(min-width: 1200px)";

// Max-Width
$max-small-smartphone: "(max-width: 479px)";
$max-smartphone: "(max-width: 767px)";
$max-tablet: "(max-width: 991px)";
$max-desktop: "(max-width: 1199px)";

// Device Only
$smartphone-only: "(max-width: 767px)";
$tablet-only: "(min-width: 768px) and (max-width: 991px)";
$desktop-only: "(min-width: 992px) and (max-width: 1199px)";

// Special
$stage-only: "(min-width: 599px) and (max-width: 767)";


// Plugins

// CSS Hamburgers Variables
$hamburger-types: (
    squeeze // define which type of hamburgers shall be included
);
$hamburger-layer-color: $clr-wilo-green;
$hamburger-layer-width: 30px;
$hamburger-layer-spacing: 7px;
$hamburger-layer-height: 5px;
$hamburger-padding-y: 20px;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 0px;

// Slick Variables
$slick-font-path: $wilo-font-path;
$slick-loader-path: $wilo-image-path;

// Bootstrap Variables
$font-size-base: 16px;
$icon-font-path: $wilo-font-path;

// z-index Variables
$z-index-navigation: 1000;
$z-index-navigation-active: 1400;
$z-index-flyout: 1200;
$z-index-flyout-mobile: 999;

