.text-module {
  .text-module-wrapper {
    .text-wrapper {
      h3 {
        @include font-size(16px);
        color: $clr-text-darkblue;
      }
      *:last-child {
        margin-bottom: 0;
      }
      .text-wrapper .wilo-btn {
        margin-right: 5px;
        margin-bottom: 5px;
      }
      p {
        a {
          color: $clr-wilo-green;
          border-bottom: solid 1px transparent;
          &:hover {
            border-bottom: solid 1px $clr-wilo-green;
          }
        }
      }
    }
  }
  .wilo-btn {
    display: table;
    margin-bottom: 5px;
  }
}

ul.arrow-list {
  padding: 0;
  margin-top: 2px;
  margin-bottom: 10px;
  line-height: 1.7;
  counter-reset: foo;
  li {
    a {
      color: #009c82;
      border-bottom: solid 1px transparent;
      &:hover {
        border-bottom: solid 1px #009c82;
      }
    }
    list-style: none;
    color: $clr-gunmetal;
    @include font-size(16px);
    @include bp-max-smartphone {
      padding-bottom: 5px;
      width: 100%;
    }
    p {
      display: inline-block;
    }
    div[class*="col-"] {
      &:first-child {
        padding-left: 0;
      }
    }
    ul {
      width: 100%;
      display: block;
      li {
        &:before {
          content: "-";
          font-family: unset;
          padding-top: 0;
        }
      }
    }
  }
  li {
    text-indent: -28px;
    margin-left: 28px;
    &:before {
      font-family: $WiloIcons !important;
      @include font-size(12px);
      padding-right: 15px;
      content: "\e92f";
      font-weight: bold;
      color: $clr-wilo-green;
      padding-top: 3px;
    }
  }
}

.page-module {
  .text-module-wrapper {
    .text-wrapper {
      ul {
        @extend ul;
        @extend .arrow-list;
      }
    }
  }
}

body > .page-module {
  background: $clr-white;
}

.link-list-item-text-wrapper {
  display: initial;
}
