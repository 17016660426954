.download_page {
  > .page-module.text-module {
    margin: 10px 0 50px 0;
  }
  .page-module {
    margin: 20px 0;
  }
  .section-divide {
    margin: 40px 0;
  }
  .paging-list {
    margin: 60px auto 75px;
  }
}
