.order-confirmation {
    h1, p {
        margin-bottom: 15px;
    }
    p {
        span {
            display: block; 
        }
    }
    .wilo-btn {
        margin: 30px 0 0 0;
    }
}