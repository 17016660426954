.bsr-product-slider {
    .slick-track {
        display: flex;
        align-items: stretch;

        .slick-slide {
            > div {
                height: 100%;

                @media #{$tablet} {
                    .bsr-product-card {
                        height: 100%;
                    }
                }
            }
        }
    }
}
 
.bsr-product-card {
    $self: &;
    position: relative;
    border: 1px solid $wilo-lightgrey-box;
    box-shadow: 0 0 5px #0000001a;
    background-color: $clr-white;
    padding: 30px 16px;

    &--highlight {
        border: 2px solid $clr-wilo-green;
        border-top: 6px solid $clr-wilo-green;
        padding-top: 26px;

        &::before {
            content: attr(data-highlight-label);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            inset: -1px auto auto -1px;
            height: 40px;
            padding: 0 24px;
            color: $clr-white;
            background-color: $clr-wilo-green;
            font-size: 1rem;
            font-family: $NotoBold;
            line-height: 1.42;
            clip-path: polygon(0 0%, 100% 0%, 90% 100%, 0% 100%);
        }
    }

    #{$self}__picture {
        display: block;
        margin: 12px 0 26px;
    }

    #{$self}__name {
        &, * {
            font-family: $NotoBold;
            font-size: 1.1rem;
            line-height: 1.1;
            margin-bottom: 8px;

            @media #{$tablet} {
                font-size: 1.5rem;
                margin-bottom: 10px;
            }
        }
    }

    #{$self}__category {
        &, * {
            font-family: $NotoLight;
            font-size: 1rem;
        }
        * {
            &:last-child {
                margin: 0;
            }
        }
        margin-bottom: 16px;

        @media #{$tablet} {
            margin-bottom: 24px;
        }
    }

    #{$self}__text {
        
        &, * {
            font-size: 1rem;
            display: -webkit-box;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        * {
            &:last-child {
                margin: 0;
            }
        }
    }

    #{$self}__cta {
        align-self: flex-end;
        line-height: 1rem;
        margin-top: 32px;
        .wilo-yellow {
            color: $clr-dark-grey;
        }
        .bsr-chevron-link {
            display: inline-block;
            padding: 10px 0 8px;
        }
    }
    
    #{$self}__devider {
        border-bottom: 1px solid $clr-crystal-white;
        margin-top: 33px;
    }

    #{$self}__features,
    #{$self}__highlights {

        .faq-block {
            padding: 12px 0 ;
        }
        .faq-question {
            &, * {
                font-family: $NotoBold;
            }
        }
        .faq-answer {
            margin-left: 0;
            padding-top: 16px;

            ul {
                padding-left: 24px;
                margin-bottom: 0;
                li::marker {
                    color: $clr-wilo-green;
                }
            }
        }
        @media #{$tablet} {
            .faq-block {
                padding: 32px 0 0 0;
                border-bottom: none;

                .faq-question {
                    cursor: default;
                    pointer-events: none;
                }
                .toggle-icon {
                    display: none;
                }
                .faq-answer {
                    display: block !important;
                    padding-top: 16px;
                }
            }
        }
    }

}
