// Colors
$clr-white: #ffffff;
$clr-black: #000000;
$clr-crystal-white: #f1f1f1;
$clr-brown-dark: #4c4338;
$clr-grey: #252525;
$clr-light-grey: #bcbcbc;
$clr-lighter-grey: #c3c3c6;
$clr-border-grey: #dddddd;
$clr-light-grey-text: #aaaaad;
$clr-super-navigation-grey: #73737A;
$clr-mega-menue-grey: #787878;
$clr-wilo-join-grey: #AFAEB3;
$clr-dark-grey: #2b2b33;
$clr-text-regular: #333333;
$clr-icons: #979797;
$clr-table-head: #e6e6e6;
$clr-neon-orange: #f24100;
$clr-neon-orange-dark: #b53100;
$clr-neon-orange-light: #F56833;
$clr-wilo-green: #009c82;
$clr-wilo-green-dark: #026756;
$clr-wilo-green-darker: #016656;
$clr-wilo-green-transparent: rgba(1,96,80,0.2);
$clr-text-green: #009a8b;
$clr-text-yellow: #ffb200;
$clr-text-yellow-hover: #E6A000;
$clr-text-darkblue: #363640;
$clr-text-red: #f24100;
$clr-text-error: #f76733;

$clr-technical-yellow: #ffb400;
$clr-technical-yellow-darker: #eba500;
$clr-gunmetal: #505050;

$wilo-lightgrey-box: #e9eaea;

$bsr-grey: #F7FAF9;
$bsr-darkgreen: #006655;