.teaser__key-figures {
  position: relative;
  padding-top: 90%;

  &.lightgrey {
    background-color: #e9eaea;
  }

  &.green {
    background-color: #009c82;
  }

  &.dark {
    background-color: #363640;
  }

  .teaser-inner {
    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h4 {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-family: $NotoBold;
    &.color-dark {
      color: #363640;
    }
  }

  .stats {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.color-dark {
      p {
        color: #363640 !important;
      }
    }

    p {
      font-family: $NotoBold;
      line-height: 1.5;
      color: white;

      &.number {
        font-size: 54px;
      }

      &.desc {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
}
