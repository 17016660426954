.bsr-blog-teaser-slider {

    &__textContent {
        p, h2 {
            margin-bottom: 24px;
        }
    }

    @media #{$desktop} {
        &__textContent {
            padding-bottom: 8px;
        }
    }

    .slick-track {
        display: flex;
        align-items: stretch;

        .slick-slide {
            padding: 1px 0;
            // height: fit-content;

            > div {
                height: 100%;

                .bsr-blog-teaser-card {
                    height: 100%;
                }
            }
        }
    }


    .slick-dots,
    &__cta {
        padding: 0 2rem;
    }

    &__cta {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 48px;
    }
    
    @media #{$tablet} {    
        &__cta {
            padding-top: 16px;
            justify-content: flex-end;
        }
    }
}

.bsr-blog-teaser-card {
    height: 100%;
    border: 1px solid $wilo-lightgrey-box;
    box-shadow: 0 0 5px #0000001a;
    display: grid !important;
    background-color: $clr-white;
    grid-template-rows: min-content auto;

    &:hover {
        border-color: $clr-wilo-green;
        outline: 1px solid $clr-wilo-green !important; // override !important global styling
    }

    a {
        color: $clr-dark-grey;
    }

    &__picture {
        position: relative;
    }

    &__tag {
        position: absolute;
        inset: 16px auto auto 16px;
        padding: 2px 8px;
        font-size: 1rem;
        background-color: $clr-white;
        border: 1px solid $clr-crystal-white;
        border-radius: 4px;
    }

    &__textContent {
        padding: 32px 24px;
        display: grid;
        row-gap: 24px;
    }

    &__title {
        font-size: 1.25rem;
        font-family: $NotoBold;
        margin: 0;        
    }

    &__abstract {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        
        &, * {
            font-size: 0.875rem;
            margin: 0;
        }
    }

    &__text {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        height: fit-content;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__abstract + &__text {
        -webkit-line-clamp: 4;
    }

    

    &__cta {
        align-self: flex-end;
    }
}
