.highlighted-content {
    background-color: $clr-crystal-white;
    padding: 15px 30px;
    ul,
    h5 {
        padding: 0;
    }
    ul {
        margin-bottom: 0;
    }
    ul li {
        min-width: 200px;
    }
    h2,
    h3,
    h4,
    h5,
    .content-section-title {
        font-family: $NotoBold;
        font-size: 14px;
        color: $clr-black;
        margin-top: 10px;
        display: inline-block;
        margin-bottom: 10px;
    }
    a {
        color: $clr-mega-menue-grey;
        text-decoration: none;
        margin-bottom: 5px;
    }
    a:before {
        font-family: $WiloIcons !important;
        content: "\e903";
        margin-right: 5px;
        font-size: 10px;
    }
    .section-divide {
        width: 95%;
        margin: 15px auto;
    }
    * {
        display: block;
    }
    + .section-divide {
        margin: 30px 0;
    }
    div {
        p {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 480px) {
    .arrow-down {
        +.highlighted-content {
            a {
                position: relative;
                top: 0;
                width: 95%;
                margin: 0 auto;
            }
        }
    }
}

.dark-box {
    background-color: $clr-text-darkblue;
    padding: 30px;
    p, h5 {
        color: $clr-white !important;
    }
    h5 {
        font-family: $NotoBold;
        margin-bottom: 10px;
        @include font-size(18px);
    }
    strong {
        display: inline-block;
        margin-left: 15px;
    }
}

.arrow-down {
    text-align: center;
    > div {
        border-top: 10px solid $clr-text-darkblue;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        width: 20px;
        display: inline-block;
        position: absolute;
    }
    + .highlighted-content {
        margin-top: 0;
        padding: 30px;
        > div {
            width: 95%;
            position: relative;
        }
        h4 {
            margin: 0;
            @include font-size($font-size-base);
            margin-bottom: 5px;
        }
        h4:before {
            font-family: $WiloIcons !important;
            content: "\e910";
            color: #15a295;
            margin-right: 10px;
            font-size: 12px;
        }
        p {
            margin-left: 33px;
        }
        a {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            color: $clr-white;
            background-color: $clr-text-darkblue;
            font-weight: bold;
        }
    }
}
