.dot-list {
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;
    display: table;
}

.dot-list-item {
    counter-increment: section;
    padding: 5px 0;
    display: table-row;

    &:before {
        content: counter(section);
        margin-right: 10px;
        display: inline-block;
        background-color: $clr-technical-yellow;
        color: $clr-white;
        font-weight: 700;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        line-height: 1.7;
        text-align: center;
    }
}

.dot-list-item-inner {
    display: table-cell;
    padding-bottom: 10px;
}
