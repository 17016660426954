// Text Colors

.wilo-green-text {
    color: $clr-text-green;
}

.wilo-yellow-text {
    color: $clr-text-yellow;
}

.wilo-dark-blue-text {
    color: $clr-text-darkblue;
}

.wilo-red-text {
    color: $clr-text-red;
}
