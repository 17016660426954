@import '../general_vars.scss';

// General
*:focus,
* {
  outline: none !important;
  /* Blue border fix*/
}

label {
  font-weight: normal;
}

textarea {
  resize: none;
}

html {
  -ms-overflow-style: scrollbar;
  @include font-size($font-size-base); // overwrite Bootstrap 10px
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.transition-all {
  transition: all 0.5s;
}

body {
  font-family: $NotoSans;
  position: relative;
  min-height: 100vH;
  max-width: 100vw;
  overflow-x: hidden;
  &:before {
    display: none;
    @include bp-smartphone-only {
      content: 'small';
    }
    @include bp-tablet-only {
      content: 'medium';
    }
    @include bp-desktop-only {
      content: 'large';
    }
    @include bp-desktop-large {
      content: 'xlarge';
    }
  }
}

img {
  @include prefix(
    (
      user-select: none
    ),
    webkit moz ms
  );
}

a[disabled='disabled'] {
  pointer-events: none;
  cursor: default;
}

a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $NotoSans;
}

h1 {
  @include font-size(24px);
  font-family: $NotoBold;
}

h2 {
  margin: 0 0 15px 0;
  font-family: $NotoBold;
  @include font-size(18px);
  color: $clr-text-darkblue;
  &.wilo-green-text {
    color: #009a8b;
  }
}

h3 {
  margin: 0 0 10px 0;
  font-family: $NotoBold;
  @include font-size(14px);
  color: $clr-text-darkblue;
}

strong,
b {
  font-family: $NotoBold;
}

.container h1 {
  margin-bottom: 30px;
  margin-top: 0;
}

a {
  cursor: pointer;
  @include prefix(
    (
      transition: all 0.5s
    ),
    webkit moz
  );
  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    text-decoration: none !important;
    border: none;
  }
  &.icon-link {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: $clr-gunmetal;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}

.page-wrapper {
  min-height: 100vH;
  display: flex;
  flex-direction: column;
}

.push-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  @include prefix(
    (
      transition: all 0.5s
    ),
    webkit moz
  );
}

.content {
  flex: 1 0 100%;
  margin-bottom: 30px;
}

.clearfix {
  clear: both;
}

.fixed {
  position: fixed !important;
  top: 0;
}

span.wilo-icon {
  font-family: $WiloIcons;
}

.section-headline {
  font-family: $NotoBold;
}

.page-headline {
  font-family: $NotoBold;
  margin-bottom: 0;
  margin-top: 20px;
  @include font-size(22px);
}

.nav-level1-element-text {
  @include font-size(18px);
  font-family: $NotoBold;
  margin: 0;
  .wilo-icon {
    @include font-size(14px);
  }
}

img {
  width: 100%;
}

header {
  height: 470px;
  overflow: hidden;
  -webkit-transition: margin-top 0.5s;
}

.header-small {
  margin-top: 80px;
}

select::-ms-expand {
  display: none;
}

.form-control,
input {
  border-radius: 0;
  height: 38px;
}

input.btn-default {
  padding: 5px 17px;
}

.center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.fixed {
  position: fixed !important;
}

.page-push-right {
  transform: translateX($side-menu-width-negative) !important;
  @include prefix(
    (
      transition: all 0.5s
    ),
    webkit moz
  );
}
@media (min-width: $side-menu-width-max-bp) {
  .page-push-right {
    transform: translateX($side-menu-width-max-negative) !important;
  }
}

.page-fade-out {
  opacity: 0 !important;
  @include prefix(
    (
      transition: all 0.5s
    ),
    webkit moz,
    true
  );
}

.page-stop-scrolling {
  height: 100vh;
  overflow: hidden;
}

body.frontofpage {
  .section-divide {
    border-top: 1px solid #dddddd;
    width: 100%;
    content: '';
    display: block;
    margin: 0;
    padding-bottom: 30px;
    background-color: $clr-white;
  }
  .container {
    > .news-section,
    > .product-slider-section,
    > .multi-col-section,
    > .download-items-wrapper,
    > .newsletter-box-section,
    > .world-explorer-section {
      background-color: $clr-white;
    }
  }
  .container {
    > .multi-col-section,
    > .world-explorer-section,
    > .news-section,
    > .newsletter-box-section {
      margin-bottom: 0;
      padding-bottom: 30px;
      position: relative;
      z-index: 20;
    }
  }
  .stage-header,
  .toolbar-section,
  .product-slider-section,
  .section-divide,
  .download-section,
  footer {
    position: relative;
    z-index: 20;
  }
  footer {
    .section-divide {
      background-color: transparent;
      border: 0 solid transparent;
      @include bp-max-smartphone {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 20px;
      }
    }
  }
}

.section-front {
  padding-bottom: 30px;
}

.slider-arrow {
  cursor: pointer;
  opacity: 0.4;
}

.slider-arrow.slider-arrow-dark-grey {
  opacity: 1;
  background: rgba(54, 54, 64, 0.9);
  width: 50px;
  height: 54px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  .icon-arrow-left,
  .icon-arrow-right {
    line-height: 54px;
    color: #fff;
    @include font-size(26px);
  }
  @include bp-max-smartphone {
    width: 35px;
    height: 39px;
    .icon-arrow-left,
    .icon-arrow-right {
      @include font-size($font-size-base);
      line-height: 39px;
    }
  }
}

.slick-dots-big .slick-dots li {
  margin: 0;
  button:before {
    height: 20px;
    width: 25px;
    line-height: 25px;
    @include font-size(11px);
  }
}

// Col Fix
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-half-offset {
  margin-left: 4.166666667%;
}

.bordered-section {
  @extend .page-section;
  border-bottom: 1px #dddddd solid;
  margin-bottom: 30px;
  &.bordered-section-top {
    border-top: 1px #dddddd solid;
    padding-top: 30px;
  }
}

//
.vertical-centered {
  margin-top: 50%;
  @include prefix(
    (
      transform: translateY(-50%)
    ),
    webkit ms
  );
}

// Rotate
.rotate-half {
  @include prefix(
    (
      transform: rotate(-180deg)
    ),
    webkit ms
  );
  @include prefix(
    (
      transition: all 0.5s
    ),
    webkit moz
  );
}

@include bp-tablet-only {
  .plp-features [class*='col-'] {
    min-height: 80px;
  }
}

// Height Alignment
@media only screen and (min-width: 992px) {
  .is-table-row {
    display: table;
  }
  .is-table-row [class*='col-'] {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}

// overview
.project-overview {
  margin-top: 30px !important;
  .wilo-spacer {
    margin-top: 30px;
  }
}

// Design Helper
.grey-underline {
  border-bottom: solid 1px $clr-light-grey;
}

.valign-me {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

footer {
  .footer-section-header {
    //color: $clr-technical-yellow;
    @include font-size(18px);
  }
}

body.mywilo-logged-in .mywilo-show-logged-out {
  display: none !important;
}

body.mywilo-logged-out .mywilo-show-logged-in {
  display: none !important;
}

// country specific font style
/* WILO KOREA FONT */

.wilo-lang-ko {
  font-family: $NotoSansKrRegular !important;
  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $NotoSansKrRegular;
  }
  h2 {
    font-family: $NotoSansKrBold;
  }
  h3 {
    font-family: $NotoSansKrBold;
  }
  .section-headline {
    font-family: $NotoSansKrBold;
  }
  .page-headline {
    font-family: $NotoSansKrBold;
  }
  .nav-level1-element-text {
    font-family: $NotoSansKrBold;
  }
  a,
  p,
  span,
  article,
  b,
  i,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  figure,
  figcaption,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  div,
  thead,
  tbody,
  table,
  td,
  tr {
    font-family: $NotoSansKrRegular !important;
  }
  i {
    &:before {
      font-family: $WiloIcons !important;
    }
  }
}

:lang(ko) {
  body {
    font-family: $NotoSansKrRegular !important;
  }
  font-family: $NotoSansKrRegular !important;
}

/* WILO CHINA FONT */

.wilo-lang-zh {
  font-family: Verdana, Arial, sans-serif !important;
  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Verdana, Arial, sans-serif !important;
  }
  h2 {
    font-family: Verdana, Arial, sans-serif !important;
    font-weight: 400;
  }
  h3 {
    font-family: Verdana, Arial, sans-serif !important;
    font-weight: 400;
  }
  .section-headline {
    font-family: Verdana, Arial, sans-serif !important;
    font-weight: 400;
  }
  .page-headline {
    font-family: Verdana, Arial, sans-serif !important;
    font-weight: 400;
  }
  .nav-level1-element-text {
    font-family: Verdana, Arial, sans-serif !important;
    font-weight: 400;
  }
  a,
  p,
  span,
  article,
  b,
  i,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  figure,
  figcaption,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  div,
  thead,
  tbody,
  table,
  td,
  tr {
    font-family: Verdana, Arial, sans-serif !important;
  }
  i {
    &:before {
      font-family: $WiloIcons !important;
    }
  }
}

:lang(zh) {
  body {
    font-family: Verdana, Arial, sans-serif !important;
  }
  font-family: Verdana, Arial, sans-serif !important;
}
