.pump-finder-teaser-module {
    padding: 30px;
    background-color: $clr-crystal-white;
    .pump-finder-teaser-module-header{
        .pump-finder-teaser-title{
            @include font-size(18px);
            font-family: $NotoBold;
            color: $clr-text-darkblue;
            margin-bottom: 0;
        }
        .pump-finder-teaser-sub-title{
            @include font-size(16px);
            color: $clr-text-darkblue;
        }
    }
    .pump-finder-teaser-module-dropdown {
        margin-bottom: 10px;
        label{
            font-family: $NotoBold;
            color: $clr-mega-menue-grey;
            @include font-size(16px);
        }
    }
    .pump-finder-teaser-lists{
        .pump-finder-teaser-module-dropdown {
            display: none;
            &:first-child{
                display: block;
            }
        }
    }
    button.btn.btn-default{
        margin-bottom: 0;
    }
}

.module-divider{
    border-top: 1px solid #ddd;
}
