.ecolution-accordion {
    --color-text: var(--text-color);
    padding-bottom: 56px;
         
    @media #{$desktop} {
        padding-bottom: 140px;
    }

    &__intro {
        color: var(--color-text);
        margin-bottom: 56px;

        p:last-child {
            margin: 0;
        }
    }

    &__headline {
        color: var(--color-text);
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        margin-bottom: 32px;

        @media #{$desktop} {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }

    &__accordion-pannels {
        border-top: 1px solid var(--color-text);
     
        @media #{$desktop} {
            max-width: 813px;
        }
    }

    .accordion-pannel {
        border-bottom: 1px solid var(--color-text);
        color: var(--color-text);
        padding-block: 16px;
        
        &__button {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            background: none;
            border: none;
            font-size: 1.25rem;
            line-height: 1.625rem;
            font-weight: 700;
            padding: 0;
        }

        &__wrapper {
            display: grid;
            grid-template-rows: 0fr;
            visibility: collapse;
            transition: grid-template-rows 400ms ease-in-out, padding 400ms ease-in-out, visibility 400ms 0ms ease-in-out;
        }
        
        &__content {
            overflow: hidden;
        }
        
        &__button[aria-expanded="true"]{
            .wilo-icon {
                rotate: -180deg
            }
            &~.accordion-pannel__wrapper {
                visibility: visible;
                grid-template-rows: 1fr;
                padding-block: 12px 0;
            }
        }
    }
}