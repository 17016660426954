 .locator-module {
   .col-80 {
     width: 80%;
     float: left;
     padding-right: 20px;
   }
   .marginal-page-content {
     padding-left: 0;
   }
   .marginal-col {
     padding-right: 0;
   }
   .input-wrapper input,
   .input-wrapper button {
     width: 100%;
   }
   .input-wrapper .col-xs-20 {
     padding-right: 0;
   }
   .input-wrapper input {
     background-color: $clr-white;
     border: solid 1px $clr-border-grey;
     box-shadow: none;
     display: block;
     height: 38px;
     padding: 6px 12px;
     font-size: 14px;
     color: #555555;
   }
   .select-module label {
     margin-top: 0;
   }
   .locator-map-wrapper {
     position: relative;
     #locator-map,
     #locator-map-light {
       width: 100%;
       height: 700px;
       margin: 30px 0;
       background: #e5e3e1;
     }
     .locator-content-toggle {
       position: absolute;
       top: 10px;
       right: 10px;
       width: 30px;
       height: 30px;
       background-color: #fff;
       z-index: 100;
       display: block;
       @include prefix(( border-radius: 100%, transition: all 0.5s), webkit moz);
     }
     .locator-content-toggle:before {
       font-family: $WiloIcons;
       content: "\e904";
       color: $clr-text-darkblue;
       margin-left: 8px;
       line-height: 30px;
     }
     .locator-content-box {
       position: absolute;
       top: 30px;
       right: 45px;
       width: 330px;
       @include bp-max-smartphone {
         top: initial;
         right: initial;
         width: 100%;
       }
       .locator-address-headline,
       .locator-routing-headline {
         font-size: 18px;
         font-family: $WILOPlusFMBold;
       }
       .locator-address-box {
         background-color: $clr-text-darkblue;
         color: #c0c0c2;
         padding: 30px;
         margin-bottom: 15px;
         @include bp-max-smartphone {
           margin-bottom: 0;
         }
         .icon-marker:before {
           content: "\e925";
           margin-right: 10px;
         }
         .locator-address-header {
           color: $clr-white;
           margin-bottom: 20px;
         }
         p {
           margin: 5px 0;
         }
         p.locator-address-zip {
           float: left;
           margin: 0;
           margin-right: 5px;
         }
         .locator-address-phone {
           margin-top: 15px;
         }
         .address-label {
           display: inline-block;
           width: 80px;
         }
       }
       .locator-routing-box {
         padding: 0 30px;
         border-top: solid 30px $clr-white;
         border-bottom: solid 30px $clr-white;
         color: $clr-text-darkblue;
         background-color: $clr-white;
         overflow: auto;
         .icon-route {
           background-image: url(/resources/img/icon-route.png);
           background-repeat: no-repeat;
           background-size: contain;
           display: inline-block;
           width: 13px;
           height: 16px;
           margin-right: 10px;
         }
         input {
           margin: 10px 0;
         }
         .route-box {
           margin-top: 20px;
           display: none;
         }
       }
     }
   }
   @media (max-width: 991px) {
     .select-module label {
       margin-top: 10px;
     }
   }
   @include bp-max-smartphone {
     .locator-adress-href {
       margin-top: 10px;
     }
     .col-80,
     .col-xs-20 {
       width: 100%;
       padding: 0;
       margin-bottom: 10px;
     }
     .locator-map-wrapper {
       #locator-map,
       #locator-map-light {
         height: 500px;
       }
       .locator-content-box {
         margin-top: -200px;
         position: relative;
         margin-bottom: 50px;
       }
     }
   }
 }
