
/* .webshop-wrapper {
  .stage-content-divider {
    display: none;
  }
} */

/*.info-boxes-container {
  background-color: $clr-crystal-white;
  margin-bottom: 30px;
  padding: 13px 0;
}*/

.webshop-series-overview {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  list-style: none;
  padding: 0;

  .webshop-collapse-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    margin-bottom: 30px;
    display: none;

    .product-overview {
      margin-bottom: 0;

      .price {
        @include bp-max-smartphone {
          text-align: left;
          margin-right: 0;
          width: 100%;
          padding: 8px 0 5px 0;
        }
      }

      .add-to-cart-btn {
        .wilo-btn {
          margin: 0;

          @include bp-max-tablet {
            width: 46px;
            height: 38px;
          }

          @include bp-desktop {
            width: 100%;
          }
        }
      }
    }

    .close {
      font-size: 32px;
      color: $clr-text-darkblue;
      opacity: 1;
      filter: alpha(opacity=100);
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      @include bp-max-smartphone {
        font-size: 22px;
      }

      &:hover, &:focus {
        opacity: 0.7;
        filter: alpha(opacity=70);
      }
    }

    .collapse-container-inner {
      padding: 20px 30px 20px 30px;
      border: 3px solid $clr-technical-yellow;

      @include bp-max-s-smartphone {
        padding: 20px;
      }
    }

    .arrow {
      border-bottom: 12px solid $clr-technical-yellow;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      top: -12px;
      left: 50%;
    }

    @include bp-tablet-only {
      &.arrow-left:before {
        left: 25%;
        right: auto;
      }

      &.arrow-right:before {
        right: 25%;
        left: auto;
        margin-left: 0;
      }
    }

    @include bp-desktop {
      &.arrow-left:before {
        left: 15%;
        margin-left: 10px;
      }

      &.arrow-right:before {
        right: 15%;
        left: auto;
        margin-left: -10px;
      }
    }

    .content-wrapper {
      display: flex;
      margin-bottom: 10px;

      @include bp-max-s-smartphone {
        flex-direction: column;
      }

      .series-image {
        margin-right: 20px;

        img {
          max-height: 100px;
          max-width: 100%;
          width: auto;
        }

        @include bp-max-s-smartphone {
          align-self: center;
          margin: 0 0 10px 0;
        }
      }

      .series-info {
        align-self: center;

        @include bp-max-s-smartphone {
          text-align: center;
        }

        .series-title {
          margin-bottom: 0;
          @include font-size(22px);
          font-family: $NotoBold;
        }
      }
    }
  }
}

.webshop-series-item {
  width: 33.33333%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.2s ease-in-out;

  &.js-disabled {
    pointer-events: none;
    opacity: 0.6;
    filter: alpha(opacity=60);
  }

  @include bp-max-smartphone {
    width: 100%;
  }

  @include bp-tablet-only {
    width: 50%;
  }

  .webshop-series-item-inner {
    padding: 30px;
    border: 1px solid $clr-border-grey;
    display: flex;
    width: 100%;
    flex-direction: row;
    min-height: 238px;
    @include prefix ((
    transition: all 0.5s
    ), webkit moz o);

    &:hover {
      border: 1px solid $clr-wilo-green;
      z-index: 1;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    }

    @include bp-max-s-smartphone {
      padding: 20px;
      flex-direction: column;
    }
  }

  .series-image {
    padding-right: 20px;
    width: 40%;
    display: flex;
    align-items: center;

    @include bp-max-s-smartphone {
      padding-right: 0;
      margin-bottom: 10px;
      width: 60%;
      align-self: center;
    }
  }

  .series-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;

    @include bp-max-s-smartphone {
      width: 100%;
    }

    .series-title {
      margin-bottom: 0;
      @include font-size(18px);
      font-family: $NotoBold;

      @include bp-max-s-smartphone {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .series-buttons {
      a {
        width: 100%;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.active {
    .webshop-series-item-inner {
      border: 1px solid $clr-technical-yellow;
    }
  }
}
