.stage__slider.container-md {
  font-family: $NotoSans;

  .main__slider {
    width: 100vw;
    height: 76vh;
    position: relative;

    .stageSlide {
      z-index: 1;
      overflow: hidden;
      width: 100vw;
      height: 76vh;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      opacity: 0;
      
      &.active {
        z-index: 3;
        opacity: 1;
        -webkit-transition: 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -webkit-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;

        img {
          -webkit-animation: zoom 12s ease-in-out 1;
          animation: zoom 12s ease-in-out 1;
        }
      }

      img {
        z-index: 1;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }

      .info {
        z-index: 3;
        background: #363640;
        opacity: 0.95;
        position: absolute;
        bottom: 11vh;
        padding: 30px;
        width: 35%;
        white-space: wrap;
        min-height: 200px;
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;

        h2 {
          color: #fff;
          font-size: 24px;
          font-weight: 800;
          letter-spacing: 2px;
          margin-bottom: 5px;
        }

        p {
          color: #bcbcbc;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }

    .blocks__navigation {
      z-index: 3;
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      top: 70vh;
      left: 50vw;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

      .stageButton {
        background: #e9eaea;
        width: 50px;
        height: 5px;
        margin: 3px;
        border-radius: unset;
        cursor: pointer;

        &.active {
          background: #009c82;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .main__slider .stageSlide .info {
      width: 80%;
      height: 30vmin;
      top: unset;
      bottom: 0 !important;
      left: 10%;
      right: auto;
    }
  }

  @media (max-width: 767px) {
    .main__slider {
      -o-object-fit: contain;
      object-fit: fill;
      -o-object-position: center;
      object-position: center;

      .stageSlide {
        img {
          height: 50%;
          z-index: 1;
        }

        .info {
          overflow: scroll;
          width: 100%;
          top: 50%;
          opacity: 1 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          
          h2 {
            font-size: 18px;
          }

          p {
            font-size: 12px;
          }

          .wilo-btn-inverted {
            font-size: 12px;
          }
        }
      } 

      .blocks__navigation {
        bottom: 30vmin;

        .stageButton {
          width: 30px;
        }
      }
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100%{
      transform: scale(1.1);
    }
  }
}