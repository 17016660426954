.cart-checkout-content {
    .text-headline {
        margin-bottom: 20px;
    }
    .section-headline {
        color: $clr-text-darkblue;
        @include font-size(18px);
        .icon-link {
            color: $clr-text-darkblue;
            @include font-size(16px);
            padding-right: 30px;
        }
    }
    h1 {
        margin-bottom: 15px;
        color: $clr-text-darkblue;
    }
    .to-login {
        margin-bottom: 0;
        a {
            margin-left: 15px;
        }
    }
    .form-section {
        margin-bottom: 0;
    }
    @include bp-max-tablet {
        .form-section {
            margin-bottom: 30px;
        }
    }
    .page-module.formpage-module .form-progress .progress-steps-content {
        min-height: auto;
    }
    .payment-selection {
        .payment-item {
            padding: 30px 30px 15px;
            border: 1px solid $clr-border-grey;
            .radio-module {
                margin-top: 0;
                label {
                    font-family: $NotoBold;
                    color: $clr-gunmetal;
                }
            }
            .payment-text {
                margin: 5px 0 15px 30px;
            }
            .payment-additional {
                margin: 0 0 15px 30px;
                border-top: 1px solid $clr-border-grey;
                .icon-hint {
                    margin: 2px 0 0 0;
                }
                @include bp-max-s-smartphone {
                    margin-left: 0;
                }
            }
        }
        .radio-logo-box {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .radio-wrapper {
                flex: 1 auto;
                align-self: center;
            }
            .payment-logo {
                align-self: flex-end;
                width: 100px;
                margin-bottom: 2px;
            }
        }
    }
    .last-address .select2-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .checkout-buttons {
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        .next {
            margin: 0;
        }
        @include bp-max-s-smartphone {
            padding-top: 30px;
            flex-direction: column;
            .wilo-btn {
                width: 100%;
                margin-top: 15px;
                &:last-child {
                    margin-top: 5px;
                }
            }
        }
    }
    table.product-overview {
        @include bp-max-s-smartphone {
            .price {
                padding: 0;
            }
        }
        th:first-child p {
            padding-left: 10px;
        }
        .quantity {
            label {
                display: none;
            }
            @include bp-max-smartphone {
                flex: initial;
                margin: inherit;
                label {
                    display: inline-block;
                }
            }
        }
    }
}

.customer-info {
    .checkbox-module {
        margin-bottom: 15px;
    }
}

.edit-box {
    padding: 30px;
    margin-bottom: 20px;
    border: 1px solid $clr-border-grey;
    position: relative;
    color: $clr-gunmetal;
    @include bp-max-tablet {
        padding: 20px;
    }
    address,
    p,
    &.last-item {
        margin-bottom: 0;
    }
    strong {
        margin-bottom: 5px;
        display: inline-block;
        color: $clr-text-darkblue;
    }
    .wilo-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        color: $clr-text-darkblue;
        @include bp-max-tablet {
            right: 20px;
            top: 20px;
        }
    }
}

.checkout-overview {
    @include bp-max-tablet {
        margin-bottom: 30px;
    }
    .cart-cost {
        background-color: $clr-crystal-white;
        padding: 30px;
        color: $clr-gunmetal;
        @include bp-max-tablet {
            padding: 20px;
        }
        ul {
            padding: 0;
            list-style-type: none;
            li {
                display: flex;
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
            .product-image {
                width: 65px;
                min-width: 65px;
                height: 65px;
                border: 1px solid $clr-border-grey;
                margin-right: 10px;
                padding: 10px;
                display: flex;
                align-self: flex-start;
                img {
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
                @include bp-max-tablet {
                    width: 80px;
                }
            }
            span {
                margin-right: 10px;
                align-self: center;
                &:last-child {
                    margin-right: 0;
                    margin-left: auto;
                }
            }
        }
        p {
            &.total {
                margin: 15px 0 0;
                color: $clr-text-darkblue;
            }
            &.title {
                font-size: 20px;
                font-family: $NotoBold;
                line-height: 1;
                color: $clr-text-darkblue;
            }
        }
        ul,
        p.title {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $clr-border-grey;
        }
    }
}
