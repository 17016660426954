.st-wish-list {
    .st-wish-list-title {
        margin-top: 0;
        font-size: 18px;
        font-family: $NotoBold;
    }
    .wish-list-row-img {
        width: auto;
    }
    .st-wish-list-content,
    .teaser-recommendations {
        margin-bottom: 30px;
    }
    .subsection-name,
    .category-name {
        font-size: 18px;
        font-family: $NotoBold;
    }
    .category-name .delete-category {
        color: #03998a;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        padding-left: 5px;
    }
    .st-wishlist-category-section {
        .st-wishlist-category-section-inner {
            padding-top: 20px;
            border-bottom: 1px #dddddd solid;
        }
        .st-wishlist-category-section-inner.first-section {
            padding-top: 0;
        }
    }
    .st-wishlist-category-subsection,
    .st-wishlist-notes-subsection {
        margin-bottom: 25px;
    }
    .teaser-save-your-wishlist,
    .dark-box.teaser-save-your-wishlist {
        @include font-size($font-size-base);
        font-family: $NotoBold;
        color: #c0c0c2;
        padding-top: 30px;
        padding-bottom: 30px;
        .wish-list-head-img {
            width: auto;
            margin-top: 8px;
        }
        .wilo-icon {
            padding-top: 10px;
            @include bp-max-smartphone {
                padding-top: 0;
                margin: 0;
            }
        }
        .action-buttons {
            text-align: right;
            @include bp-max-smartphone {
                margin-top: 10px;
                text-align: center;
            }
        }
        @include bp-max-smartphone {
            .teaser-body {
                margin-bottom: 10px;
                text-align: center;
            }
        }
        p {
            color: #c0c0c2;
            font-family: $NotoBold;
        }
        .teaser-title {
            color: white;
            @include font-size($font-size-base);
            margin-top: 17px;
        }
        .btn {
            min-width: 140px;
        }
    }
    .st-wishlist-notes-subsection {
        textarea {
            width: 100%;
            resize: vertical;
            background: $clr-white;
            color: #7f7f7f;
            padding: 10px;
            border: 1px #eaeaea solid;
        }
        .notes-body {
            background: $clr-crystal-white;
            color: #a9a9a9;
            padding: 15px;
            font-size: 14px;
            margin-bottom: 15px;
            min-height: 100px;
        }
    }
    .st-wishlist-category-section .green-link {
        color: $clr-text-green;
        font-family: $NotoBold;
    }
    .table.table-series>tbody>tr>td {
        padding-top: 3px;
        padding-bottom: 3px;
        &.td-product-image {
            padding-left: 3px;
            width: 66px;
            padding-right: 0;
        }
    }
    .table.td-all-vertical-align-middle>tbody>tr>td {
        vertical-align: middle;
    }
    table>tbody>tr>td {
        &.td-configure-now a,
        &.td-add-to-project a {
            font-family: $NotoBold;
            white-space: nowrap;
        }
        &.td-series-name,
        &.td-article-name,
        &.td-item-name {
            min-width: 200px;
        }
        &.td-configure-now {
            width: 185px;
            @include bp-max-tablet {
                width: 125px;
            }
        }
        &.td-add-to-project {
            width: 175px;
            @include bp-max-tablet {
                width: 100px;
            }
        }
        @include bp-max-smartphone {
            &.td-add-to-project,
            &.td-configure-now {
                text-align: center;
            }
        }
        &.td-trash {
            width: 100px;
            text-align: center;
        }
    }
    .teaser-wish-list-recommendations {
        .recommendation-col {
            margin-bottom: 10px;
            img {
                max-width: 100%;
            }
            .btn {
                margin-bottom: 10px;
            }
        }
        .teaser-description {
            color: $clr-mega-menue-grey;
        }
        .teaser-col-title {
            margin-top: 0;
            font-family: $NotoBold;
            @include bp-max-smartphone {
                margin-top: 10px;
            }
        }
        .teaser-title {
            font-size: 18px;
            font-family: $NotoBold;
            margin-bottom: 20px;
        }
    }
}

.add-new-category-lightbox {
    .trash-button-container {
        background: none;
        border-radius: 0;
        border: 0;
        padding: 0 0 0 10px;
    }
    .add-new-category-button {
        background: $clr-text-darkblue;
        color: $clr-white;
        cursor: pointer;
        border-radius: 0;
        border: 1px $clr-text-darkblue solid;
        /* font-family: $NotoBold; */
        height: 33px;
        font-size: 32px;
        line-height: 26px;
        padding: 0 10px;
        width: 42px;
        margin-right: 52px;
    }
}

.mfp-content>.add-to-project-not-logged-in-lightbox {
    .wilo-icon.icon-mywilo {
        padding-top: 40px;
    }
    .actions-footer {
        .join-mywilo-title {
            margin-bottom: 15px;
        }
        .btn-register-now {
            margin-right: 10px;
        }
    }
}

.add-to-project-no-projects-lightbox {
    @include bp-max-smartphone {
        .btn-create-project {
            margin-top: 15px;
        }
    }
}

.wish-list-trash-button,
.wish-list-trash-button:active,
.wish-list-trash-button:focus {
    background: url(/resources/img/wish-list-trash.png) no-repeat transparent center;
    border: 0;
    width: 42px;
    height: 20px;
}

.st-wish-list-content {
    .td-product-image {
        padding: 10px;
        img {
            max-height: 40px;
            width: auto;
            max-width: 100px;
            height: auto;
        }
    }
}

.wilo-wl-textarea {
    width: 100%;
    @include bp-max-smartphone {
        margin-bottom: 20px;
    }
}
