.ecolution-thank-you {
    $self: &;
    
    .container {
        display: flex;
        flex-direction: column;
        gap: 34px;
        align-items: center;
    }
    
    &__headline {
        color: var(--text-color);
        text-align: center;
        font-size: 2.25rem;
        line-height: 2.625rem;
        font-weight: 700;
    }

    &__subheadline {
        color: var(--text-color);
        text-align: center;
        font-size: 1.125rem;
        line-height: 2.125rem;
    }

    &__image {

    }

}