.ecolution-tripple-text-image {
    padding-bottom: 56px;

    @media #{$desktop} {
        padding-bottom: 140px;
    }

    &__headline {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        padding: 0;
        color: var(--text-color, #{$clr-black});
        margin-bottom: 32px;
        
        @media #{$desktop} {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 58px;
        color: var(--text-color, #{$clr-black});

        @media #{$desktop} {
            gap: 96px;
        }
    }

    .ecolution-text-video {
        padding: 0;

        @media #{$desktop} {
            &__content {
                flex-direction: row;
            }
            &:nth-child(odd) {
                .ecolution-text-video__content {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}