// My Wilo
.toolbar-wrapper {
    display: none;
}

@include bp-smartphone-only {
    .full-button {
        width: 100%;
        margin-bottom: 5px;
    }
}

.dashboard-tab {
    .dashboard-tab-content-wrapper {
        .btn.wilo-dark-blue {
            margin-bottom: 0;
        }
    }
}

.icon-teaser {
    width: 100%;
    background-color: $clr-crystal-white;
    padding: 20px;
    display: block;
    font-size: 40px;
    text-align: center;
    color: $clr-text-darkblue;
    &:before {
        opacity: 0.7;
    }
}

.messenger-tab {
    ul {
        li {
            padding: 5px 0;
            span {
                font-family: $NotoBold;
            }
        }
    }
}

.mywilo-service-adm-section,
.mywilo-project-planning-section {
    th {
        font-family: $NotoBold;
    }
}

.mywilo-bonus-section {
    h5 {
        font-family: $NotoBold;
    }
    .bonus-teaser-box {
        background-color: $clr-wilo-green;
        color: #FFFFFF;
        padding: 20px;
        text-align: center;
        display: block;
        span {
            font-size: 24px;
        }
        p {
            font-size: 14px;
            color: #FFFFFF;
            font-family: $NotoBold;
            margin-bottom: 0;
        }
    }
}

@include bp-max-tablet {
    .dashboard-tab-content-wrapper {
        display: none;
    }
}

a.btn.btn-default.white-button {
    background-color: $clr-white;
    font-size: 14px;
    color: $clr-text-yellow;
}

.dashboard-tab-content-wrapper {
    color: $clr-mega-menue-grey;
}

.btn.dashboard-tab-button {
    font-size: 14px;
    text-align: left;
    transition: all 0.4s ease-in-out;
}

.dashboard-tab-button:before,
.read-more-button:before,
.projekt-administration-tab-tab-list-element:before {
    font-family: $WiloIcons;
    content: "\e903";
    margin: 0 5px 0 0;
    font-size: 10px;
}

select.dashboard-select {
    width: 100%;
    min-width: 0;
    height: 38px;
    color: $clr-white;
}

.read-more-button {
    display: block;
    cursor: pointer;
    &:before {
        font-size: 8px;
    }
}

// Postbox Tab

.post-box-messages,
.wish-list-elements {
    list-style: none;
    padding: 0;
}

.message-wrapper,
.wish-list-element {
    overflow: auto;
    margin-bottom: 8px;
}

.wish-list-tab {
    .wish-list-element {
        overflow: hidden;
    }
}

// Events Tab
.mywilo-events-section {
    .dashboard-tab-button {
        @include bp-smartphone-only {
            margin-top: 10px;
        }
    }
}

// Chatbox Tab
.mywilo-chatbox-section {
    .chatbox-header,
    .chatbox-textarea {
        resize: none;
        margin-bottom: 15px
    }
    .chat-box-availiability-text {
        margin: 10px 0 0;
    }
    .chat-box-header {
        color: $clr-black;
    }
    @include bp-max-tablet {
        .chat-box-select-wrapper {
            margin-bottom: 10px;
        }
        .chatbox-header {
            margin-bottom: 0;
        }
        .chat-box-availiability-text {
            margin-bottom: 10px;
        }
    }
}

// Event Invitation Tab
.mywilo-event-invitation-section {
    .event-invitation-tab-tile {
        width: 100%;
        background-color: $clr-text-green;
        height: 200px;
        text-align: center;
        padding: 15px;
        @include bp-smartphone-only {
            margin-top: 15px;
        }
        span,
        a,
        p {
            color: $clr-white;
        }
        .icon-iconticket:before {
            content: "\e91f";
            font-size: 30px;
        }
        .invitation-tab-header {
            margin-bottom: 15px
        }
        .invitation-tile-name {
            margin: 15px 0 0 0;
        }
        .invitation-tile-date {
            margin-bottom: 0;
        }
    }
}

// Wishlist Tab
.mywilo-wishlist-section {
    .wish-list-configure-wrapper,
    .wish-list-details-wrapper {
        padding-left: 0;
        @include bp-max-tablet {
            padding-left: 15px;
            margin-top: 5px;
        }
        a.btn {
            width: 100%;
        }
    }
    .wishlist-see-all {
        margin-top: 10px;
    }
    .wishlist-button>a {
        @include bp-max-tablet {
            width: 100%;
            margin-top: 20px;
        }
    }
}

// Project Administration Tab
.mywilo-projekt-administration-section {
    .grey-tabs>li.active>a,
    .grey-tabs>li.active>a:hover,
    .grey-tabs>li.active>a:focus {
        background-color: $clr-light-grey;
    }
    .grey-tabs {
        border-bottom-color: $clr-light-grey;
        margin-bottom: 20px;
    }
    .grey-tabs>li>a {
        background-color: $clr-lighter-grey;
    }
    .grey-tabs>li>a:hover {
        background-color: $clr-light-grey;
    }
    .projekt-administration-tab-tab-list {
        list-style: none;
        padding-left: 0;
    }
    .projekt-administration-tab-tab-list-title {
        margin-bottom: 10px;
        color: $clr-black;
    }
    .projekt-administration-tab-tab-box {
        background-color: $clr-text-yellow;
        padding: 10px 15px;
        margin-bottom: 10px;
        p {
            margin-bottom: 5px;
            color: $clr-white;
        }
    }
    .white-button {
        margin-top: 5px;
    }
}

.projekt-administration-tab-tab-content {
    &last-child {
        margin-bottom: 0;
    }
}

// Product News Tab
.mywilo-product-news-section {
    .dashboard-tab-button {
        margin-right: 10px;
        @include bp-smartphone-only {
            margin-top: 10px;
        }
    }
}

// Service Administration Tab
.mywilo-service-administration-section {
    .service-administration-select-wrapper {
        padding-right: 0;
        @include bp-smartphone-only {
            padding-right: 15px;
            margin-bottom: 10px;
        }
    }
}

// Campaign Material Tab
.mywilo-campaign-material-section {
    .campaign-material-headline {
        margin-bottom: 30px;
    }
    .campaign-button {
        margin-top: 30px;
        float: right;
        margin-bottom: 30px;
        @include bp-smartphone-only {
            margin-top: 0;
        }
    }
    .campaign-image-wrapper {
        @include bp-smartphone-only {
            margin-bottom: 30px;
        }
    }
}

// Training Offering Tab
.mywilo-training-offering-section {
    .dashboard-tab-button {
        margin-right: 10px;
        @include bp-smartphone-only {
            margin-top: 10px;
        }
    }
}

//
.tab-headline,
.event-invitation-tab-event-headline,
.message-sender,
.upcoming-events-tab-event-headline,
.chat-box-availiability-text,
.read-more-button,
.text-picutre-tab-text-headline,
.campaign-material-headline,
.projekt-administration-tab-tab-list-title,
.projekt-administration-tab-tab-box-title,
.invitation-tile-name {
    font-family: $NotoBold;
}

.tab-headline {
    font-size: 20px;
    position: relative;
    @include bp-max-tablet {
        cursor: pointer;
        margin-bottom: 10px;
    }
}

.tab-headline-wrapper {
    position: relative;
}

.event-invitation-tab-event-headline,
.upcoming-events-tab-event-headline,
.text-picutre-tab-text-headline {
    color: $clr-black;
    margin-bottom: 5px;
}

div[class*="mywilo-"] {
    .toggle-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        transition: all 0.5s;
        &.rotate-centered {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    @include bp-max-tablet {
        &:first-of-type {
            .dashboard-tab-content-wrapper {
                display: block;
            }
        }
        .toggle-icon {
            display: block;
        }
    }
}

.join-wilo-box {
    @include bp-max-smartphone {
        margin: 30px 0 0;
    }
}

.join-wilo-box a.btn.btn-default:before {
    font-family: $WiloIcons !important;
    content: "\e903";
}

// Welcome Message Popup
#welcome-popup {
    position: relative;
    background: #FFF;
    padding: 30px;
    width: 930px;
    max-width: 100%;
    margin: 20px auto;
    .top-modal-info {
        background: #f1f1f1;
        padding: 30px;
        font-size: 18px;
        line-height: 14px;
        color: $clr-text-darkblue;
        display: table-cell;
        .wilo-icon {
            vertical-align: middle;
            font-size: 22px;
            margin-right: 15px;
            font-weight: bold;
        }
        span {
            vertical-align: middle;
            margin: 5px 0 0 0;
            display: inline-block;
        }
    }
    .modal-video {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        .welcome-video-player {
            background: #FFFFFF;
            position: relative;
            min-height: 490px;
            color: $clr-wilo-green;
            @include bp-max-smartphone {
                min-height: 350px;
            }
            @include bp-max-s-smartphone {
                min-height: 300px;
            }
            .video-player-wrap {
                video {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.2;
                    @include prefix(( transition: all 0.3s ease-in-out), webkit moz o);
                }
            }
            .video-player-control {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 99;
                pointer-events: none;
                .wilo-icon {
                    font-size: 75px;
                    margin-bottom: 20px;
                }
                span {
                    display: block;
                    font-size: 18px;
                    font-family: $NotoBold;
                    padding-top: 15px;
                }
            }
        }
    }
    .modal-text {
        span {
            font-family: $NotoBold;
            font-size: 22px;
            color: $clr-text-darkblue;
        }
        p {
            font-size: 18px;
            color: #787878;
        }
    }
}

.video-player-module-wrapper {
    .modal-video {
        padding: 0;
    }
}

.modal-video {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    .video-player-module {
        background: $clr-white;
        position: relative;
        color: $clr-wilo-green;
        .video-player-wrap {
            min-height: 100px;
            video,
            img {
                width: 100%;
                opacity: 0.2;
                @include prefix(( transition: all 0.3s ease-in-out), webkit moz o);
            }
        }
        .video-player-control {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 99;
            pointer-events: none;
            .wilo-icon {
                font-size: 75px;
                margin-bottom: 20px;
            }
            span {
                display: block;
                font-size: 18px;
                font-family: $NotoBold;
                padding-top: 15px;
            }
        }
    }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    /* ideally, transition speed should match zoom duration */
    @include prefix(( transition: all 0.3s ease-in-out), webkit moz o);
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.open-welcome-popup {
    display: none;
}
