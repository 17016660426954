// NEw Blog overrides for missing bootstrap grid
#wilo-blog-app {
    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .my-3, .mb-3 {
        margin-bottom: 1rem;
    }

    .mt-3, .my-3 {
        margin-top: 1rem;
    }

    .blogTeaserTile {
        height: 100%;
        
        &--image {
            aspect-ratio: 4/3;
        }
    }
}

// TODO Remove old
.blog-entry-row {
    @extend .clearfix;

    &:nth-child(odd) .blog-entry {
        &:first-child {
            width: 55%;
            padding-right: 15px;
        }

        &:last-child {
            width: 45%;
            padding-left: 15px;
        }
    }

    &:nth-child(even) .blog-entry {
        &:first-child {
            width: 45%;
            padding-right: 15px;
        }

        &:last-child {
            width: 55%;
            padding-left: 15px;
        }
    }
}

.blog-entry {
    height: 415px;
    float: left;
    margin-bottom: 30px;

    .teaser {
        height: 100%;
    }
}

.blog__filter.blog__filter--active {
    color: #009c82;
}



.blog-content {
    h2,
    h3,
    .text-module .text-module-wrapper .text-wrapper h2,
    .text-module .text-module-wrapper .text-wrapper h3 {
        color: #009c82;
    }

    &.page-module .blog-headline {
        color: #009c82;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 1.5rem;
        margin-top: 10px;
    }
}
