.pump-replacement-teaser-module {
  padding: 1.875rem 1.875rem 3.25rem 1.875rem;
  background: #2d2d36; // TODO: not defined in global colors for now

  &, p {
    color: #fff;
  }

  label {
    margin-bottom: 0.5rem;
  }
}

.pump-replacement-teaser-icon {
  font-size: 2.25rem;
  margin-right: 2rem;
}

.pump-replacement-teaser-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.25rem;
}

.pump-replacement-teaser-header {
  display: flex;
  margin-bottom: 2.5rem;
}

.pump-replacement-teaser-form {
  display: flex;
}

.pump-replacement-teaser-form-submit-button {
  border-radius: 0;

  &:before {
    content: "\e90f";
  }
}

.pump-replacement-teaser-form-input {
  margin-right: 1.375rem;
}

.pump-replacement-teaser-text {
  margin: 3.75rem 0 1.25rem 0;
}
