.content.fullpage.fullpage-wrapper {
  margin-top: 0 !important;
}

.fullpage-desc {
  font-family: $NotoSans;
  font-size: 1.5rem;
  p {
    display: inline-block;
    margin-bottom: 30px;
  }
}

.wilo-plp-footer {
  background: rgba(241, 241, 241, 0.48);
  background: -moz-linear-gradient(
    top,
    rgba(241, 241, 241, 0.48) 0%,
    rgba(54, 54, 64, 1) 68%,
    rgba(54, 54, 64, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(241, 241, 241, 0.48)),
    color-stop(68%, rgba(54, 54, 64, 1)),
    color-stop(100%, rgba(54, 54, 64, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(241, 241, 241, 0.48) 0%,
    rgba(54, 54, 64, 1) 68%,
    rgba(54, 54, 64, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(241, 241, 241, 0.48) 0%,
    rgba(54, 54, 64, 1) 68%,
    rgba(54, 54, 64, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(241, 241, 241, 0.48) 0%,
    rgba(54, 54, 64, 1) 68%,
    rgba(54, 54, 64, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(241, 241, 241, 0.48) 0%,
    rgba(54, 54, 64, 1) 68%,
    rgba(54, 54, 64, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$clr-crystal-white', endColorstr='$clr-text-darkblue', GradientType=0);
}

.fullpage-slide-container {
  position: relative;
  height: auto; //min-height:100vh;
  overflow: hidden;
  &.fullpage-video-grey {
    background-color: $clr-crystal-white;
  }
  &.smaller-section {
    height: auto;
    min-height: 1px;
  }
}

.wilo-cover-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.fullpage-video-grey {
    background-size: contain;
    .scroll-down {
      a {
        color: $clr-text-darkblue;
        span {
          border-left: 1px solid $clr-text-darkblue;
          border-bottom: 1px solid $clr-text-darkblue;
        }
      }
    }
  }
}

.video-js .vjs-tech {
  top: -2px;
}

.video-js {
  overflow: hidden;
}

.mobile-fallback {
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: $clr-crystal-white url(/resources/img/wilo-hq.jpg) no-repeat center
      center;
    background-size: contain;
    opacity: 0.6;
  }
}

.fallback-box {
  background-color: $clr-wilo-green;
  width: 80%;
  padding: 15px;
  margin: 0 auto;
  font-size: 20px;
  opacity: 0.9;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translateY(-50%) translateX(-50%);
}

.fallback-box-text {
  color: $clr-white;
}

.box-shadow-bottom {
  @include prefix(
    (
      box-shadow: inset 0 -2rem 4rem -1rem rgba(0, 0, 0, 0.75),
    ),
    webkit moz
  );
}

.wilo-cover-images {
  position: absolute;
  width: auto;
  &.wilo-yonos-engineer {
    bottom: 0;
    right: 25%;
    height: 70vh;
    z-index: 100;
    @include bp-max-desktop {
      right: 10%;
      height: 60vh;
    }
  }
  &.wilos-yonos-product-image {
    right: 10%;
    height: 45vh;
    bottom: 25%;
    @include bp-max-tablet {
      z-index: 999;
    }
    @include bp-smartphone-only {
      position: absolute;
      margin: 0 auto;
      left: 25%;
      right: 25%;
      bottom: 15%;
      height: 250px;
      width: auto;
    }
  }
}

.wilo-video {
  height: 100%;
  width: 100%;
}

#scrollr.scroll-down {
  z-index: 999;
  display: block;
  @include bp-max-tablet {
    margin-bottom: 0;
  }
  a {
    @extend #scrollr;
    @extend .scroll-down;
    padding-top: 70px;
    span {
      @extend .scroll-down;
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 1px solid $clr-white;
      border-bottom: 1px solid $clr-white;
      @include prefix(
        (
          transform: rotate(-45deg),
          animation: sdb05 1.5s infinite,
        ),
        webkit moz
      );
      box-sizing: border-box;
    }
    &.dark {
      span {
        border-left: 1px solid $clr-black;
        border-bottom: 1px solid $clr-black;
        color: $clr-black;
      }
      color: $clr-black;
    }
  }
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.centered {
  position: absolute;
  top: 45%;
  left: 50%;
  /* bring your own prefixes */
  @include prefix(
    (
      transform: translate(-50%, -50%),
    ),
    webkit moz o
  );
}

.is-table-row [class*="col-"] {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

h1.productHeading {
  font-family: $NotoBold;
  color: $clr-wilo-green;
  font-size: 64px;
}

.feature-container {
  padding-top: 4rem;
  @include bp-smartphone-only {
    width: 100%;
    padding-top: 0;
  }
  .feature-text-box {
    padding: 5px 0;
  }
}

.mg-btm-30 {
  margin-bottom: 30px;
}

h1.feature-heading {
  @extend h1;
  @extend .productHeading;
  color: $clr-text-darkblue;
  font-size: 4rem;
  margin: 0;
  text-align: left;
  @include bp-smartphone-only {
    font-size: 2.5rem;
  }
}

.feature-heading-centered {
  @extend h1;
  @extend .productHeading;
  color: $clr-text-darkblue;
  font-size: 4rem;
  text-align: left;
}

h3.feature-subheading {
  font-size: 2rem;
  margin: 0;
  h1.feature-heading ~ & {
    margin: 0;
  }
  &.boxed {
    width: 30%;
    @include bp-max-tablet {
      width: 100%;
    }
  }
}

.wilo-video-teaser-text {
  text-align: center;
  h1 {
    @extend h1;
    @extend .productHeading;
    font-family: $NotoBold;
    color: $clr-wilo-green;
    font-size: 4rem;
  }
  h5 {
    color: $clr-black;
    font-size: 2rem;
  }
  h1,
  h5 {
    margin: 0;
    & ~ .icon-playbutton {
      margin-top: 2rem;
      display: block;
    }
  }
  .icon-playbutton {
    font-size: 10rem;
    color: $clr-wilo-green;
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: $clr-wilo-green-darker;
    }
  }
}

.fullpage-section {
  background: $clr-white;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  #wilo-360 & {
    top: 40%;
    height: 65%;
    transform: translateY(-50%);
  }
}

.scroll-down a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: inline-block;
  @include prefix(
    (
      transform: translate(-50%, -50%),
    ),
    webkit
  );
  color: $clr-white;
  font: "NotoSansBold", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}

.scroll-down a {
  opacity: 0.8;
  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    opacity: 1;
  }
}

video {
  background-color: transparent !important;
}

.xplode-frame {
  overflow: hidden;
  width: 80%;
}

// CSS Hack for explosion Video
video#explode-video {
  max-width: 102%;
  width: 102%;
  height: auto;
  background: white;
  margin-left: -10px;
  margin-bottom: -10px;
}

a.wilo-explore-button {
  @extend .btn;
  @extend .btn-default;
  color: $clr-wilo-green;
  background-color: $clr-white;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: $NotoBold;
  border-radius: 4px;
  @include bp-smartphone-only {
    font-size: 1.2rem;
  }
  span {
    position: relative;
    top: 0.1em;
    margin-right: 0.5em;
  }
}

.wilo-box-transparent {
  background-color: $clr-wilo-green;
  color: $clr-white;
  opacity: 0.9;
  padding: 2rem;
  display: inline-block;
  @include bp-smartphone-only {
    display: block;
  }
  h1 {
    margin: 0;
    font-family: $NotoBold;
    font-size: 4rem;
    @include bp-smartphone-only {
      font-size: 3rem;
      display: inline-block;
    }
  }
  h5 {
    font-family: $NotoSans;
    margin-top: 0;
    font-size: 2rem;
  }
  .slide-simply-installed & {
    position: absolute;
    left: 5%;
    top: 25vh;
    padding: 2rem;
    @include bp-smartphone-only {
      text-align: center;
      top: inherit;
      bottom: 50%;
      right: 5%;
    }
    .icon-tick {
      position: absolute;
      background-color: $clr-white;
      color: $clr-wilo-green;
      font-size: 2.5rem;
      padding: 1.4rem 1rem;
      border-radius: 10rem;
      margin: 0 auto;
      left: calc(50% - 1.5rem);
      top: -40%;
      @include bp-smartphone-only {
        position: static;
        top: auto;
        margin: auto;
        left: auto;
        // position: relative;
        bottom: 10px;
        display: inline-block;
        font-size: 0.8rem;
        padding: 0.7rem 0.55rem;
        border-radius: 5rem;
        margin-right: 1rem;
      }
    }
  }
}

.quotation-section {
  a {
    color: $clr-wilo-green;
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: $clr-white;
    }
  }
}

.wilo-yonos-checked-list-box {
  @extend .wilo-box-transparent;
  position: absolute;
  left: 5vw;
  bottom: 25%;
  @include bp-tablet-only {
    left: auto;
    bottom: auto;
    top: 30%;
    margin: 1rem;
    width: 80%;
    padding-right: 25vw;
  }
  @include bp-smartphone-only {
    text-align: center;
    width: auto;
    position: static;
    margin: 15px;
  }
  & > div {
    margin: 0 auto;
    @include bp-smartphone-only {
      margin: 0;
      width: 100%;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 2rem;
      @include bp-smartphone-only {
        display: none;
      }
      li {
        font-family: $NotoSans;
        font-size: 1.5rem;
        @include bp-tablet-only {
          font-size: 1.3rem;
        }
      }
      li > span[class^="icon-"] {
        margin-right: 1em;
        font-size: 0.75em;
        @include bp-smartphone-only {
          font-size: inherit;
        }
      }
    }
  }
}

.section-redbutton {
  background-size: contain;
  background-color: $clr-crystal-white;
  .redbuttonheader {
    position: absolute;
    top: 10vh;
    left: 10vw;
    z-index: 9;
    h1.feature-heading {
      text-align: left;
    }
    @include bp-max-tablet {
      top: 5vh;
    }
    @include bp-max-smartphone {
      top: 2vh;
      left: 0;
    }
  }
  .wilo-maxo-yonos-center {
    position: absolute;
    height: auto;
    width: auto;
  }
  .wilo-box-transparent {
    h3 {
      font-family: $NotoBold;
      margin: 0 0 15px;
      @include font-size(24px);
    }
    p {
      @include font-size($font-size-base);
    }
    &.left-box {
      top: 50vh;
      left: 10vw;
      position: absolute;
      z-index: 99;
      width: 30%;
      @include bp-max-tablet {
        width: 80%;
        top: 15vh;
        padding: 20px;
      }
      @include bp-max-smartphone {
        display: none;
        bottom: 22vh;
      }
    }
    &.right-box {
      bottom: 50vh;
      right: 10vw;
      position: absolute;
      z-index: 99;
      width: 28%;
      @include bp-max-tablet {
        width: 60%;
        bottom: 22vh;
        right: 50%;
        transform: translateX(50%);
        padding: 20px;
      }
      @include bp-max-smartphone {
        width: 80%;
      }
    }
  }
}

.footer-yonos-img {
  width: auto;
  position: absolute;
  z-index: 9;
  height: 30vh;
  left: 50vw;
  margin-top: 8vh;
  transform: translate(-50%);
}

.red-button-img {
  cursor: pointer;
  @include bp-max-smartphone {
    width: 40% !important;
  }
}

@media (max-width: 480px) {
  .fullpage-section {
    .heading-group-360 > div h2,
    .wilo-video-teaser-text h1,
    h1.feature-heading-centered,
    .wilo-box-transparent h1,
    .wilo-yonos-checked-list-box h1 {
      font-size: 2rem !important;
    }
  }
  .wilo-cover-images.wilos-yonos-product-image {
    height: 190px;
  }
}

@media (max-width: 767px) {
  .fullpage-section {
    .heading-group-360 > div h2,
    .wilo-video-teaser-text h1,
    h1.feature-heading-centered,
    .wilo-box-transparent h1,
    .wilo-yonos-checked-list-box h1 {
      font-size: 2.5rem !important;
    }
  }
  .faq-placeholder-img {
    display: none;
  }
  .plp-features > div {
    margin: 0 15px;
  }
  .plp-features .slick-slide {
    padding: 0 40px !important;
    margin-top: 30px;
  }
  .feature-container .col-sm-3 {
    width: 33.33%;
  }
  .slide-simply-installed .wilo-box-transparent {
    padding: 2rem 1rem;
  }
}

@media (max-width: 480px) {
  .wilo-box-transparent h1 {
    font-size: 2rem;
  }
  .slide-simply-installed .wilo-box-transparent .icon-tick {
    top: -5px;
  }
}

.feature-container .feature-text-box p {
  font-size: 1.5rem;
}

.faq-desc {
  overflow: hidden;
}

.faq-placeholder-img {
  margin-top: 45px;
}

span.wilo-icon {
  font-family: $WiloIcons !important;
}

@media (max-width: 991px) {
  .wilo-plp-footer {
    background: $clr-text-darkblue;
  }
  .wilo-plp-footer-desktop {
    display: none;
  }
  .wilo-plp-footer-mobile {
    display: block;
    .footer-section-header {
      padding: 5px 0 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      margin: 0 0 10px 15px;
      a {
        font-family: $NotoBold;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 993px) {
  .wilo-plp-footer-desktop {
    display: block;
  }
  .wilo-plp-footer-mobile {
    display: none;
  }
  .wilo-plp-footer {
    footer {
      position: absolute;
      bottom: 0;
      background: transparent;
    }
  }
}

.wilo-video-teaser-text {
  width: 100vw;
  height: 90vh;
  margin-top: 10vh;
  @include bp-max-smartphone {
    margin-top: 5vh;
  }
}

iframe {
  border: 0;
}

// fullpage.js dot navigation
.content #fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
}
