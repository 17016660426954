/// Mixin to prefix several properties at once
/// @author Hugo Giraudel
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print

@mixin prefix($declarations, $prefixes: (), $important: false) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      @if $important == false {
        #{'-' + $prefix + '-' + $property}: $value;
      } @else {
        #{'-' + $prefix + '-' + $property}: $value !important;
      }
    }

    // Output standard non-prefixed declaration
    @if $important == false {
      #{$property}: $value;
    } @else {
      #{$property}: $value !important;
    }
  }
}

/*Usage

.selector {
  @include prefix((
    column-count: 3,
    column-gap: 1.5em,
    column-rule: 2px solid hotpink
  ), webkit moz);
}

END */

// Mixin for Font-Sizes
@function calculateRem($size) {
  $remSize: $size / $font-size-base;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
 /* font-size: calculateRem($size); */
}

/* Usage
 @include font-size(14px)
*/

// Mixin for Font-Sizes End


//Mixin for Breakpoints

@mixin bp-smartphone {
  @media #{$smartphone} {
    @content;
  }
}

@mixin bp-tablet {
    @media #{$tablet} {
    @content;
  }
}

@mixin bp-desktop {
    @media #{$desktop} {
    @content;
  }
}

@mixin bp-desktop-large {
    @media #{$desktop-large} {
    @content;
  }
}

@mixin bp-max-s-smartphone {
  @media #{$max-small-smartphone} {
    @content;
  }
}

@mixin bp-max-smartphone {
    @media #{$max-smartphone} {
    @content;
  }
}

@mixin bp-max-tablet {
    @media #{$max-tablet} {
    @content;
  }
}

@mixin bp-max-desktop {
    @media #{$max-desktop} {
    @content;
  }
}

@mixin bp-smartphone-only {
    @media #{$smartphone-only} {
    @content;
  }
}

@mixin bp-tablet-only {
    @media #{$tablet-only} {
    @content;
  }
}

@mixin bp-desktop-only {
    @media #{$desktop-only} {
    @content;
  }
}

@mixin bp-stage-only {
  @media #{$stage-only} {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin bp-small {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin auto-word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-hyphens: auto;
}

/*Usage
 @include bp-small {
    width: 100%;
    float: none;
    margin: 0;
  }
*/
//End
