@import "~flatpickr/dist/flatpickr.css";

.flatpickr-calendar {
    border-radius: 0;

    .flatpickr-day {
        border-radius: 0;
        
        &.selected {
            background-color: $clr-wilo-green;
            border-color: $clr-wilo-green;
        }
    }
}