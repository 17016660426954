#language_switcher {
  display: none;
  .container {
    position: relative;
    .icon-search {
      position: absolute;
      right: 15px;
      bottom: 10px;
      font-size: 20px;
      cursor: pointer;
      color: $clr-wilo-green;
    }
    .search-input {
      background-color: $clr-white;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $clr-border-grey;
      box-shadow: none;
      transition: all 0.2s ease-in-out;
      &:hover,
      &:active,
      &:focus {
        border-color: $clr-wilo-green;
      }
    }
  }
}

#language_switcher {
  @include bp-max-tablet {
    display: block;
  }
  &:active {
    color: #363640;
  }
}

.language-switcher {
  @include bp-max-tablet {
    .wilo-icon {
      transition: all 0.3s ease-in-out;
      &.rotateQuarter {
        transform: rotate(-90deg);
      }
    }
    .current-language {
      font-size: 20px;
      font-family: $NotoBold;
    }
    .language-list {
      display: none;
      list-style-type: none;
      padding-left: 24px;
      li {
        font-size: 20px;
        a {
          color: #009c82;
          font-family: $NotoBold;
        }
      }
    }
  }
}

.super-navigation {
  margin-left: auto;
  .language-switcher {
    .language-list {
      display: none;
      position: absolute;
      top: 22px;
      z-index: 103;
      padding: 0;
      background-color: #009c82;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
      li {
        display: block;
        padding: 5px 7px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #363640;
        }
      }
    }
    .current-language {
      &:after {
        font-family: $WiloIcons;
        content: '\e901';
        margin-left: 5px;
        font-size: 8px;
      }
    }
  }
}

.quicklinks-wrapper {
  .quicklinks-inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mega-menu-teaser {
      width: 300px;
      background-color: $clr-crystal-white;
      margin-bottom: 15px;
      @include bp-max-tablet {
        display: none;
      }

      .mega-menu-teaser-img {
        width: auto;
        height: auto;
      }
    }
  }
}

.main-navigation {
  display: flex;
  flex-wrap: wrap;

  // Diese Regel war nicht gescoped, aber ich habe keine Ahnung wofür sie da ist, deshalb habe ich sie erstmal auf die main-navigation beschränkt
  .wilo-icon {
    &.icon-logo:before,
    &.icon-close:before {
      color: #ffffff;
    }
  }
}
.navigation-wrapper {
  width: 100%;
}

nav.navbar {
  margin: 0;
  border: 0;
  z-index: 1000;
  background-color: $clr-wilo-green;
  border-radius: 0;
}

nav a,
nav a:active,
nav a:focus {
  color: $clr-white;
  text-decoration: none;
}

nav a:hover,
.super-navigation-item:hover {
  color: $clr-white;
}

nav a.active {
  color: $clr-white;
}

.nav-element {
  margin: 0px;
  padding: 0 12px 20px;

  @include bp-max-desktop {
    padding: 0 12px 20px 12px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding: 0 0 20px 12px;
  }

  &.is-active .nav-level1-element-text a {
    color: #00493d;
  }

  @media (min-width: 992px) {
    &:first-child.is-active:after {
      left: calc(50% - 14px);
    }

    &:last-child.is-active:after {
      left: calc(50% - 4px);
    }

    &.is-active:after {
      position: absolute;
      bottom: -9px;
      left: calc(50% - 9px);
      width: 18px;
      content: '';
      border-top: 9px solid $clr-wilo-green;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
    }
  }
}

.nav-level1-element-text a {
  font-family: $NotoBold;
  transition: none;
}

.sub-menu-inner > ul > li > ul > li:last-child:not(.divider) {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.navbar-header {
  width: auto;
}

.navbar-slogan {
  line-height: 1;
  float: right;
  @include font-size(24px);
  font-family: $NotoBold;
  color: $clr-white;
  margin-top: 20px;
  min-height: 24px;
}

.super-navigation {
  margin-top: 25px;
  float: right;
  width: auto;
  position: relative;

  ul {
    list-style: none;
    float: right;
    color: $clr-white;

    li {
      display: inline-block;
    }
  }
}

.super-navigation-item {
  @include font-size(14px);
  margin-left: 15px;
  font-family: $NotoSans;
  color: $clr-white;
  i {
    margin-right: 5px;
  }
}

.navbar-toggle .icon-bar {
  width: 30px;
  height: 5px;
  background-color: $clr-white;
  margin-top: 0;
  margin-bottom: 4px;
}

.nav-level1-element-text span,
.sub-menu-title span {
  @include font-size(14px);
  margin-right: 20px;
}

.sub-menu-title {
  cursor: pointer;
}

.nav-element.mobile,
.nav-level1-element-text span {
  display: none;
}

// Storage Navbar
#mobile-menu {
  display: flex;
  align-items: flex-end;
  position: relative;
  pointer-events: none;
  * {
    pointer-events: auto;
  }
  @include bp-max-tablet {
    width: auto;
    padding: 0;
  }
}

#storage-nav {
  margin: 25px 0 0 auto;
  padding: 0 0 20px 15px;
  transition: transform 0.5s;
  li {
    // border-left: 1px solid #fff;
    display: inline-block;
    padding: 5px 15px 0;
    font-size: 18px;
    flex-grow: 1;
    text-align: center;
    // &:first-child {
    //   border-left: 0;
    //   padding-left: 0;
    // }
    a {
      transition: none;
      p {
        margin: 0;
        display: none;
        @media (min-width: 768px) and (max-width: 991px) {
          display: block;
          margin-left: 15px;
        }
      }
      .nav-badge-wrapper {
        position: relative;
      }
      .nav-badge {
        width: 16px;
        line-height: 100%;
        position: absolute;
        top: -10px;
        z-index: 100;
        right: -7px;
      }
      .nav-badge-inner {
        width: 100%;
        height: 0;
        display: flex;
        justify-content: center;
        padding-bottom: 100%;
        font-size: 9px;
        border-radius: 50%;
        box-shadow: -2px 2px 2px $clr-wilo-green;
        overflow: hidden;
        font-family: $NotoBold;
        background: $clr-white;
        color: #000;
        @include bp-max-tablet {
          box-shadow: none;
          background-color: $clr-wilo-green;
          color: #fff;
        }
      }
    }
  }
  @include bp-max-tablet {
    width: 100vw;
    background-color: #f1f1f1;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin: 0;
      // padding: 0;
      height: 50px;
      border: 0;
      padding: 5px 0;
      &:first-child {
        margin: 0;
        a {
          border: 0;
        }
      }
      a {
        border-left: 1px solid #ccc;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      a,
      a span:before {
        color: #555;
      }
    }
  }
}

#storage-nav .logout-storagenav {
  display: none;
  @include bp-max-tablet {
    display: block;
  }
}

@include bp-max-tablet {
  #storage-nav {
    display: none;
  }
}

.brand-header {
  margin-right: 35px;
  line-height: 0;
  margin-top: 50px;
  float: left;
  width: auto;
  transition: transform 0.5s;
  @media #{$desktop} {
    min-width: 100px;
  }
  .brand-logo-header {
    width: auto;
    @include bp-max-tablet {
      width: 80px;
      margin-top: -5px;
    }
  }
  @include font-size(57px);
  color: $clr-white;
  &:hover {
    color: $clr-white;
  }
  span {
    color: $clr-white;
  }
}

.hamburger-box {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 3px;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
}

li#nav-mywilo {
  position: relative;
  border: 0;
  padding: 0;
  margin-right: 10px;
}

.nav-mywilo-overview {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f1f1f1;
  width: 130px;
  padding: 20px 0 10px 0;
  display: none;
  a {
    display: block;
    padding-bottom: 10px;
    color: #333333;
    &:hover {
      color: #009c82;
    }
    &:focus {
      color: #009c82;
    }
    &:active {
      color: #009c82;
    }
  }
}

.nav-mywilo-overview::before {
  width: 18px;
  content: '';
  border-bottom: 9px solid #f1f1f1;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
} // main-navigation because of stronger general selector
.main-navigation {
  .nav__search-wrapper {
    position: relative;
  }
  .nav__search {
    margin-right: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    transition: width 0.5s ease-in-out;
    z-index: 100;
  }
  .nav__search--hidden {
    width: 0;
    display: block;
  }
  .nav__search-icon {
    bottom: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 15px;
    display: none;
  }
  .nav__search-input {
    background-color: $clr-wilo-green;
    border: 0;
    border-bottom: 1px solid #fff;
    box-shadow: none;
    color: #fff;
    margin-bottom: 25px;
    padding-right: 50px;
    height: auto;
    &:hover,
    &:active,
    &:focus {
      border: 0;
      border-bottom: 1px solid #fff;
    }
  }
  .nav__search-form {
    overflow: hidden;
  }
} 

.loginButton {
  color: $clr-text-darkblue;
  background-color: transparent;
  border: none;
  padding: 0;
  display: none;

  .icon {
    fill: currentColor;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }

  &[data-state="logged-in"] {
    display: block;
    .user-xmark {
      display: none;
    }
    &:hover {
      color: #cc3700;

      .user-check {
        display: none;
      }
      .user-xmark {
        display: inline;
      }
    }

    .loginState {
      &--loggedOut {
        display: none;
      }
    } 
  }
  &[data-state="logged-out"] {
    display: block;
    color: #fff;
    .loginState {
      &--loggedIn {
        display: none;   
      }
      .icon {
        width: 21px;
        height: 21px;
      }
      &__label {
        font-size: 14px;
      }
    }
  }
}

// Icon on Desktop
@media (min-width: 992px) {

  #nav-searchbar a {
    position: relative;
    z-index: 102;
  }
  .searchbar-results-wrapper,
  .quicklinks-wrapper {
    top: -25px;
  }
  .nav__search-form {
    padding-top: 15px;
    background-color: $clr-wilo-green;
  }
  .nav__search-input::-webkit-input-placeholder {
    color: #fff;
  }
  .nav__search-input::-moz-placeholder {
    color: #fff;
  }
  .nav__search-input:-ms-input-placeholder {
    color: #fff;
  }
  .nav__search-input:-moz-placeholder {
    color: #fff;
  }
  .nav__search {
    max-width: calc(100% - 30px);
  }
}

@media (max-width: 991px) {
  // main-navigation because of stronger general selector
  .main-navigation {
    .nav__search-wrapper {
      position: static;
    }
    .nav__search {
      bottom: -93px;
      width: 100%;
      left: 0;
      padding: 30px 15px;
      background-color: #fff;
      overflow: visible;
    }
    .nav__search--hidden {
      display: none;
    }
    .nav__search-input {
      margin: 0;
      background-color: #fff;
      border-bottom: 1px solid $clr-border-grey;
      color: $clr-wilo-green;
      &:hover,
      &:active,
      &:focus {
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: $clr-wilo-green;
      }
    }
    .nav__search-icon {
      display: block;
      color: $clr-wilo-green;
    }
  }
}

.search-form {
  position: relative;
  margin-bottom: 30px;
  &.searchbar-flyout {
    width: 100%;
    position: absolute;
    bottom: -98px;
    left: 0;
    background-color: $clr-white;
    padding: 30px 0;
    display: none;
    margin: inherit;
  }
  .search-input {
    background-color: $clr-white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $clr-border-grey;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:active,
    &:focus {
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: $clr-wilo-green;
    }
  }
  > .container {
    position: relative;
  }
  span.wilo-icon.icon-search {
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 20px;
    cursor: pointer;
    color: $clr-wilo-green;
  }
  form {
    margin-bottom: 0;
  }
}

/* ===============
    Searchbar
    =============== */

.inner-addon {
  position: relative;
}

.searchbar-result {
  overflow: auto;
}

.searchbar-content {
  position: relative;
}

.searchbar-results-wrapper,
.quicklinks-wrapper {
  display: none;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  background-color: $clr-white;
  color: $clr-mega-menue-grey;
  position: absolute;
  z-index: 1000;
  padding: 15px 15px 5px 15px;
}

.searchbar-results-wrapper p {
  font-family: $NotoSans;
}

.searchbar-result-title {
  float: left;
}

.searchbar-result-items {
  float: right;
}

.searchbar-quicklinks-title {
  margin-bottom: 5px;
  @include font-size($font-size-base);
  color: $clr-black;
}

.quicklinks {
  list-style: none;
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.quicklink {
  color: #666;
  &:hover {
    color: $clr-wilo-green;
  }
  transition: color 0.2s ease-in-out;
  margin-bottom: 10px;
  display: block;
}