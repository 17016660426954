/* Fake / Fix Classes only for Target Group */

/* Full Width Fix */

.fk--fw {
    position: relative;
    &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: -50vw;
        width: 200vw;
        height: 100%;
        background-color: #f1f1f1;
    }
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}