.bsr {
    // general styles
    line-height: 1.625rem;

    h2 {
        line-height: 1.5;
    }

    .bsr-text-link {
        color: $clr-wilo-green;
        font-weight: 600;
    }
    .bsr-chevron-link {
        color: $clr-wilo-green;
        font-weight: 600;
        &:before {
            font-family: "wilo-icons", sans-serif;
            content: "\e903";
            font-size: 12px;
            vertical-align: 1px;
            margin-right: 5px;
        }
    }
    .bsr-external-link {
        svg {
            margin-right: 3px;
        }
    }
    .bsr-icon-reset {
        &:before {
            content: '';
            display: block;
            background-image: url('/resources/img/fa-rotate-right.svg');
            width: 24px;
            height: 24px;
        }
    }
    .bsr-abstract {
        font-size: 16px;
        margin-bottom: 1.5rem;
        font-family: $NotoLight;
        font-weight: 300;
    }
    .bsr-section {
        --section-spacing: 64px;
        padding-top: var(--section-spacing);
        padding-bottom: var(--section-spacing);

        // &:first-child {
        //     padding-top: calc(var(--section-spacing) / 2);
        // }

        &--grey {
            background-color: $bsr-grey;
        }

        &--grey {
            & + .bsr-section--grey {
                padding-top: 0;
            }
        }
        
        &--white {
            & + .bsr-section--white {
                padding-top: 0;
            }
        }

        @media #{$desktop} {
            --section-spacing: 96px;
        }
    }
    // overrides
    .content .container {
        margin-bottom: 0px;
    }
    .page-wrapper > .content {
        margin-bottom: 0px;
    }
    @media (min-width: 1400px) {
        .container {
          width: 1320px;
        }
    }
    @media(min-width: 768px) {
        .navbar-nav {
            margin-top: 25px;
        }
    }
    .wilo-btn {
        border-radius: 4px;
    }
    h2 {
        font-weight: 600;
        font-size: 1.5rem;
    }
    .green-textlink {
        font-weight: 600;
        span {
            font-weight: 600;
            vertical-align: 1px;
        }
    }
    .main-navigation-wrapper {
        .main-nav-header {
            @media #{$desktop} {
                &::before,
                &::after {
                    content: unset;
                }
            
                transition: grid-template-rows 300ms ease-in-out;
                display: grid;
                grid-template-rows: 1fr;
                
                > div {
                    overflow: hidden;
                    padding-bottom: 5px;
                }
            }
        }
        &--sticky {
            position: fixed;
            inset: 0 0 auto 0;
        }
        
        &--folded {
            .main-nav-header {
                grid-template-rows: 0fr;
            }
        }
    } 
    .main-navigation {
        .mega-dropdown-menu {
            &.row {
                background-color: white;
            }
        }
        .mega-dropdown-menu-arrow {
            border-bottom: 9px solid white;
            z-index: 1051;
        }
        @media (max-width: 991px) {
            .navbar-nav {
                background-color: white;
            }
        }
    }
    @media (max-width: 991px) {
        .nav-level1-element-text {
            svg {
                margin-right: 6px;
                path {
                    fill: black;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: $clr-wilo-green;
                    }
                } 
            }
        }
    }
    .super-navigation {
        .bsr-external-link {
            svg {
                vertical-align: -1px;
            }
        }
    }
    #wilo-blog-app {
        .blogTeaserTile--anchor {
            color: $bsr-darkgreen;
        }
    }
    .main-footer {
        .fc--lightgrey {
            color: white;
        }
        &__header {
            font-weight: 700;
            a {
                color: white;
            }
        }
        a {
            svg {
                margin-right: 12px;
            }
        }
        li > a {
            svg {
                path {
                    transition: all 0.5s;
                    fill: #c3c3c6;
                }
            }
            &:hover {
                svg {
                    path {
                       fill: white;
                    }
                }
            }
        }
    }
    .ecolution-text-video {
        $self: ".ecolution-text-video";

        #{$self}__content {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: 15px;
        }

        #{$self}__text {
            grid-column: 1 / -1;
        }
        
        #{$self}__video {
            grid-column: 1 / -1;
        }

        #{$self}__headline {
            grid-column: 1 / -1;
        }

        @media #{$desktop} {
            #{$self}__content {
                gap: 1.5rem 1.875rem;
            }

            #{$self}__video {
                align-self: flex-start;
            }

            &--50-50 {            
                #{$self}__headline,
                #{$self}__text {
                    grid-column: 1 / 7;
                }
                
                #{$self}__video {
                    grid-column: 7 / -1;
                }
            }
            &--66-33 {
                #{$self}__headline,
                #{$self}__text {
                    grid-column: 1 / 9;
                }

                #{$self}__video {
                    grid-column: 9 / -1;
                }
            }


            &--only-text {
                #{$self}__headline,
                #{$self}__text {
                    grid-column: 1 / 9;
                }
            }
        }
        padding-bottom: 0;
        h2 {
            margin-bottom: 0;
        }
        &__text-area {
            color: $clr-text-regular;
            >p, >ul {
                margin-bottom: 1rem;
            }
            li {
                margin-bottom: 8px;
            }
        }
        li::marker {
            color: $clr-wilo-green;
        }

    }
    .faq-module,
    .bsr-product-card {
        .faq-block {
            border-bottom: 1px solid #F1F1F1;
            padding: 12px;
            margin: 0;
            .faq-question p {
                font-weight: 400;
                margin: 0;
            }
            .faq-answer {
                padding-top: 12px;
                p:last-child {
                    margin-bottom: 0;
                }
            }
            &.faq-first {
                border-top: 1px solid #F1F1F1;
            }
            &.open {
                .faq-question p {
                    color: $clr-wilo-green;
                }
                .toggle-icon {
                    transform: rotate(-45deg);
                    path {
                       fill: $clr-wilo-green;
                    }
               }
            }
        }
    }
    .bsr-blog-overview {
        .container > .row > .col-12 {
            padding: 0 15px;
        }
        &__headline {
            margin-bottom: 2.5rem;
        }
    }
}