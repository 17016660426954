@font-face {
    font-family: "wilo-icons";
    src: url("../fonts/wilo-icons.woff2") format("woff2"),
    url("../fonts/wilo-icons.woff") format("woff"),
    url('../fonts/wilo-icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wilo-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-space:before {
    content: '\e800';
}

/* '' */
.icon-glyph1:before {
    content: '\e801';
}

/* '' */
.icon-glyph1-1:before {
    content: '\e802';
}

/* '' */
.icon-uni0001:before {
    content: '\e803';
}

/* '' */
.icon-alldownloads:before {
    content: '\e900';
}

/* '' */
.icon-arrow-bottom:before {
    content: '\e901';
}

/* '' */
.icon-arrow-left:before {
    content: '\e902';
}

/* '' */
.icon-arrow-right:before {
    content: '\e903';
}

/* '' */
.icon-arrow-top:before {
    content: '\e904';
}

/* '' */
.icon-brochure:before {
    content: '\e905';
}

/* '' */
.icon-chat:before {
    content: '\e906';
}

/* '' */
.icon-close:before {
    content: '\e907';
}

/* '' */
.icon-envelope:before {
    content: '\e908';
}

/* '' */
.icon-info-i:before {
    content: '\e909';
}

/* '' */
.icon-list:before {
    content: '\e90a';
}

/* '' */
.icon-mywilo:before {
    content: '\e90b';
}

/* '' */
.icon-newsletter:before {
    content: '\e90c';
}

/* '' */
.icon-pumpcontrol:before {
    content: '\e90d';
}

/* '' */
.icon-rangeguide:before {
    content: '\e90e';
}

/* '' */
.icon-search:before {
    content: '\e90f';
}

/* '' */
.icon-tick:before {
    content: '\e910';
}

/* '' */
.icon-logo:before {
    content: '\e911';
}

/* '' */
.icon-renewal:before {
    content: '\e912';
}

/* '' */
.icon-telephone:before {
    content: '\e913';
}

/* '' */
.icon-world:before {
    content: '\e914';
}

/* '' */
.icon-wishlist-notchecked:before {
    content: '\e915';
}

/* '' */
.icon-wishlist:before {
    content: '\e916';
}

/* '' */
.icon-360 .path1:before {
    content: '\e917';
}

/* '' */
.icon-360 .path2:before {
    content: '\e918';
}

/* '' */
.icon-360 .path3:before {
    content: '\e919';
}

/* '' */
.icon-360 .path4:before {
    content: '\e91a';
}

/* '' */
.icon-turnleft:before {
    content: '\e91b';
}

/* '' */
.icon-turnright:before {
    content: '\e91c';
}

/* '' */
.icon-application:before {
    content: '\e91d';
}

/* '' */
.icon-wilosolutionfinder:before {
    content: '\e91e';
}

/* '' */
.icon-IconTicket:before {
    content: '\e91f';
}

/* '' */
.icon-demo:before {
    content: '\e920';
}

/* '' */
.icon-diagram-arrow:before {
    content: '\e921';
}

/* '' */
.icon-research:before {
    content: '\e922';
}

/* '' */
.icon-sales:before {
    content: '\e923';
}

/* '' */
.icon-globe:before {
    content: '\e924';
}

/* '' */
.icon-pin-twocolored .path1:before {
    content: '\e925';
}

/* '' */
.icon-pin-twocolored .path2:before {
    content: '\e926';
}

/* '' */
.icon-quote-end:before {
    content: '\e927';
}

/* '' */
.icon-quote-start:before {
    content: '\e928';
}

/* '' */
.icon-world2:before {
    content: '\e929';
}

/* '' */
.icon-playbutton:before {
    content: '\e92a';
}

/* '' */
.icon-baum:before {
    content: '\e92b';
}

/* '' */
.icon-building:before {
    content: '\e92c';
}

/* '' */
.icon-circle-diagram:before {
    content: '\e92d';
}

/* '' */
.icon-pdf:before {
    content: '\e92e';
}

/* '' */
.icon-goto-download:before {
    content: '\e92f';
}

/* '' */
.icon-circle-house:before {
    content: '\e930';
}

/* '' */
.icon-cloud-house:before {
    content: '\e931';
}

/* '' */
.icon-cold:before {
    content: '\e932';
}

/* '' */
.icon-drain:before {
    content: '\e933';
}

/* '' */
.icon-pump-cloud:before {
    content: '\e934';
}

/* '' */
.icon-shower-rain:before {
    content: '\e935';
}

/* '' */
.icon-snow-points:before {
    content: '\e936';
}

/* '' */
.icon-sprinkler:before {
    content: '\e937';
}

/* '' */
.icon-sun-heating:before {
    content: '\e938';
}

/* '' */
.icon-waterdown:before {
    content: '\e939';
}

/* '' */
.icon-watertab:before {
    content: '\e93a';
}

/* '' */
.icon-factory-water:before {
    content: '\e93b';
}

/* '' */
.icon-factory:before {
    content: '\e93c';
}

/* '' */
.icon-flame-snow:before {
    content: '\e93d';
}

/* '' */
.icon-heat-helix:before {
    content: '\e93e';
}

/* '' */
.icon-heating:before {
    content: '\e93f';
}

/* '' */
.icon-hot-water:before {
    content: '\e940';
}

/* '' */
.icon-powerwater:before {
    content: '\e941';
}

/* '' */
.icon-settings:before {
    content: '\e942';
}

/* '' */
.icon-plus:before {
    content: '\e943';
}

/* '' */
.icon-double-arrow-left:before {
    content: '\e944';
}

/* '' */
.icon-double-arrow-right:before {
    content: '\e945';
}

/* '' */
.icon-bag:before {
    content: '\e946';
}

/* '' */
.icon-clock:before {
    content: '\e947';
}

/* '' */
.icon-marker:before {
    content: '\e948';
}

/* '' */
.icon-alert:before {
    content: '\e949';
}

/* '' */
.icon-calendar:before {
    content: '\e94a';
}

/* '' */
.icon-excel:before {
    content: '\e94b';
}

/* '' */
.icon-hint:before {
    content: '\e94c';
}

/* '' */
.icon-photocamera:before {
    content: '\e94d';
}

/* '' */
.icon-upload:before {
    content: '\e94e';
}

/* '' */
.icon-eye:before {
    content: '\e94f';
}

/* '' */
.icon-pen:before {
    content: '\e950';
}

/* '' */
.icon-matrix-view:before {
    content: '\e951';
}

/* '' */
.icon-teaser-view:before {
    content: '\e952';
}

/* '' */
.icon-list-view:before {
    content: '\e953';
}

/* '' */
.icon-cooling-climate:before {
    content: '\e954';
}

/* '' */
.icon-solar-geothermal:before {
    content: '\e955';
}

/* '' */
.icon-picture:before {
    content: '\e956';
}

/* '' */
.icon-circle-cross:before {
    content: '\e957';
}

/* '' */
.icon-circle-tick:before {
    content: '\e958';
}

/* '' */
.icon-word:before {
    content: '\e959';
}

/* '' */
.icon-zip:before {
    content: '\e95a';
}

/* '' */
.icon-disk:before {
    content: '\e95b';
}

/* '' */
.icon-wifi:before {
    content: '\e95c';
}

/* '' */
.icon-filter:before {
    content: '\e95d';
}

/* '' */
.icon-flipscreen .path1:before {
    content: '\e95e';
}

/* '' */
.icon-flipscreen .path2:before {
    content: '\e95f';
}

/* '' */
.icon-flipscreen .path3:before {
    content: '\e960';
}

/* '' */
.icon-reset:before {
    content: '\e961';
}

/* '' */
.icon-world3:before {
    content: '\e962';
}

/* '' */
.icon-trash-bin:before {
    content: '\e963';
}

/* '' */