.ecolution-countdown {
    $self: &;
    position: relative;

    &__bg {      
        background: 
        linear-gradient(180deg, #003835 0%, #001413 100%),
        linear-gradient(187deg, rgba(0, 156, 130, 0.60) 6.56%, rgba(3, 49, 75, 0.60) 92.95%);
        position: absolute;
        inset: 0;

        picture {
            position: absolute;
            bottom: 0;
            translate: 0 -25%;
            display: block;
            width: 100%;
            overflow: hidden;

            img {
                height: 200px;
                object-fit: cover;

                @media (min-width: 1200px) {
                    object-fit: fill;
                }

            }
        }
    }
    &__wrapper {
        position: relative;
        padding-block: 144px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        @media (min-width: 768px) {

            gap: 48px;
        }

        &, 
        #{$self}__headline {
            color: $clr-white;
        }
    }

    &__headline {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        text-align: center;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 40px;
            
        }
    }

    &__subtext {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        margin: 0;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 24px;  
        }
    }

    .countdown {
        display: flex;
        flex-direction: row;
        padding-block: 21px 8px;

        @media (min-width: 768px) {
            padding-block: 0;
        }

        &__digit {
            display: inline-flex;
            flex-direction: column;
            align-items: center;

            &:not(:last-child) {
                .countdown__value {
                    border-right: 1px solid $clr-white;
                }
            }
        }

        &__value {
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;
            padding: 7px 24px 5px;
            text-align: end;

            &:before {
                content: attr(data-value);
            }

            @media (min-width: 768px) {
                font-size: 80px;
                padding: 22px 26px 24px;
            }
            
        }

        &__label {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.32px;
            text-transform: uppercase;
            
            @media (min-width: 768px) {
                font-size: 20px;
                letter-spacing: 0.4px;
            }
        }
    }

}