.page-module {
    .contact-marginal-wrapper ul li div p.marginal-text {
        color: $clr-gunmetal;
    }
}

.contact-marginal-wrapper {
    background-color: $clr-technical-yellow;
    color: $clr-white;
    padding:30px;
    .text-list-marginal-headline, .contact-information {
        color: $clr-white;
    }
    ul {
        list-style-type: none;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
        li {
            &:last-child {
                div{
                    p, a{
                        margin-bottom: 0;
                    }
                }
            }
            div {
                display: inline-block;
                &:first-child {
                    &.contact-inline-info {
                        min-width: 100px;
                    }
                }
                p.marginal-text,
                a.marginal-text {
                    display: inherit;
                    margin-bottom: 5px;
                    @include font-size(16px);
                    color: $clr-white;
                }
            }
        }
    }
}
