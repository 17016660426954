@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans-Regular.woff") format("woff"),
    url("../fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NotoBold";
    src: url("../fonts/NotoSans-Bold.woff") format("woff"),
    url("../fonts/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "NotoBold";
    src: url("../fonts/NotoSans-Bold-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoLight";
    src: url("../fonts/NotoSans-Light.woff2") format("woff2"),
    url("../fonts/NotoSans-Light.ttf") format("truetype");
}
@font-face {
    font-family: "NotoLight";
    src: url("../fonts/NotoSans-Light-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoSemiBold";
    src: url("../fonts/NotoSans-SemiBold.woff2") format("woff2");
}
@font-face {
    font-family: "NotoSemiBold";
    src: url("../fonts/NotoSans-SemiBold-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoSemiBoldItalic";
    src: url("../fonts/NotoSans-SemiBoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSemiBoldItalic";
    src: url("../fonts/NotoSans-SemiBoldItalic-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoLightItalic";
    src: url("../fonts/NotoSans-LightItalic.woff2") format("woff2");
}

@font-face {
    font-family: "NotoLightItalic";
    src: url("../fonts/NotoSans-LightItalic-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoBoldItalic";
    src: url("../fonts/NotoSans-BoldItalic.woff") format("woff"),
    url("../fonts/NotoSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "NotoBoldItalic";
    src: url("../fonts/NotoSans-BoldItalic-latin-ext.woff2") format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "NotoItalic";
    src: url("../fonts/NotoSans-Italic.woff") format("woff"),
    url("../fonts/NotoSans-Italic.ttf") format("truetype");
}
