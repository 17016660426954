.ecolution-award {
    scroll-snap-align: start;
    $self: &;
    position: relative;
    background-color: #181A1A;
    
    // &__caption-wrapper {
    //     transition-property: opacity translate;
    //     transition-duration: 0.5s;
    //     transition-timing-function: ease-in-out;
    // }
        
    @media #{$desktop} {
        height: 100svh;

        &::before {
            content: '';
            display: block;
            position: absolute;
            inset: 0 50% 0 0;
            background: linear-gradient(92deg, #141616 54.25%, rgba(28, 30, 30, 0.00) 81.04%);

        }

        .container {
            height: 100svh;
            position: relative;

        }
    }


    &__image {
        position: relative;
        display: block;
        overflow: hidden;

        img {
            width: 200%;
            height: auto;
            object-fit: cover;
            translate: -50% 0;
        }

        @media #{$desktop} {
            position: absolute;
            inset: 0 0 0 auto;
            height: 100%;
            
            img {
                min-width: 100%;
                height: 100%;
                width: auto;
                object-position: right;
                object-fit: cover;
                translate: none;
            }
        }

        // @media #{$desktop-large} {
        //     inset: 0 0 0 0;
        // }

    }

    &__caption-wrapper {
        position: relative;
        z-index: 1;
        padding-block-end: 60px;
        
        @media #{$desktop} {
            position: absolute;
            inset: 50% auto auto auto;
            translate: 0 -50%;
            width: clamp(200px, 45%, 504px);
            padding-block-end: 0;
        }
    }
    &__headline,
    &__subheadline,
    &__text,
    &__links a {
        color: $clr-white;

    }
    &__links {
        position: absolute;
        inset: auto auto 0 auto;
        z-index: 1;
        translate: 0 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.25rem;       
        @media #{$desktop} {
            position: relative;
        }
    }
    &__headline {
        font-weight: 700;
        font-size: 1.875rem;
        line-height: 2.25rem;
        margin-bottom: 16px;
        
        @media #{$desktop} {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }
    &__subheadline {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.625rem;
        margin-bottom: 40px;

        @media #{$desktop} {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
    &__text {
        font-size: 1rem;
        line-height: 1.5rem;
        
        p {
            margin: 0;
        }

        > *:not(:last-child) {
            margin-bottom: 20px;
        }

        h3,
        h4,
        h5,
        h6 {
            color: $clr-white;
        }
        
    }
    
}