  a.privacy-link,
  a.green-link {
      color: $clr-wilo-green;
  }

  .page-module.formpage-module {
      .already-customer {
          margin: 30px 0;
          .uniform-radio {
              display: inline-block;
              margin-right: 10px;
              margin-top: 6px;
          }
          .radio-label,
          .input-label {
              vertical-align: top;
              line-height: 38px;
          }
          .radio-label {
              margin-right: 40px;
          }
          .input-label {
              margin-right: 20px;
          }
          .form-control {
              width: auto;
              display: inline-block;
              vertical-align: middle;
          }
          .textinput-wrapper {
              display: inline;
          }
          #customer-id {
              display: inline-block;
              vertical-align: top;
          }
          &.type2 .input-label {
              display: block;
          }
          &.type2 .form-control {
              display: block;
              float: left;
              margin-right: 20px;
          }
          &.type2 p {
              overflow: hidden;
          }
      }
      .radio-module {
          .uniform-radio {
              display: inline-block;
              margin-bottom: -5px;
          }
          label {
              position: absolute;
          }
      }
      .radio-label,
      .input-label,
      .input-module label,
      .select-module label,
      .checkbox-module label p,
      .radio-module label {
          color: $clr-mega-menue-grey;
          @include font-size($font-size-base);
      }
      .radio-module label,
      .checkbox-module label p {
          margin-bottom: 0;
      }
      .input-module label,
      .select-module label,
      .checkbox-module,
      .radio-module {
          display: block;
          margin-top: 15px;
      }
      .section-divide {
          margin: 40px 0;
      }
      .row>.text-headline {
          margin: 0 15px;
      }
      .styled-form .text-content {
          margin: 5px 15px 20px 15px;
      }
      .styled-form.already-customer .text-headline {
          margin-left: 0;
          margin-bottom: 10px;
      }
      .col-sm-6 .col-sm-6 {
          padding-left: 0;
      }
      .col-sm-6 .col-sm-6:last-child {
          padding-right: 0;
      }
      .checkbox-wrapper {
          margin-bottom: -5px;
      }
      .register-btn {
          margin-top: 10px;
      }
      .toggle-icon {
          margin-right: 10px;
          display: inline-block;
          @include prefix(( transition: all 0.5s), webkit moz o);
          @include font-size(14px);
      }
      .form-progress {
          .progress-steps-content {
              min-height: 800px;
              >div {
                  display: none;
                  &:first-child {
                      display: block;
                  }
              }
          }
      }
      textarea.form-control {
          resize: none;
          width: 100%;
          height: 180px !important;
          border: solid 1px #ccc;
      }
      a.green-link {
          margin-bottom: 3px;
          .icon-arrow-right {
              font-size: 10px;
          }
      }
      .wilo-checkbox-error-border{
        border: 1px solid #f76733;
        padding: 15px;
        & label {
            width: auto;
        }
      }
  }