// Newsletter-Box

.newsletter-box {
    background-color: $clr-technical-yellow;
    color: $clr-gunmetal;
    @include font-size(20px);
    padding: 30px;
    line-height: 28px;
    button.btn {
        color: $clr-gunmetal;
        height: 38px;
    }
    input, input:hover {
        color: $clr-gunmetal;
        background-color: $clr-technical-yellow-darker;
        box-shadow: none;
        border: none;
    }
    input {
        box-shadow: none !important;
        -webkit-appearance: none;
        height: 38px;
        &.btn {
            color: $clr-technical-yellow;
        }
        &.input-text {
            @include font-size(14px);
            line-height: initial;
            min-width: 220px;
            padding: 10px;
        }
        @media (max-width: 447px){
            margin-bottom: 10px;
        }
    }
    .icon-newsletter {
        @include font-size(30px);
        margin-right: 15px;
        color: $clr-gunmetal;
    }
    .newsletter-desc {
        @media #{$max-tablet} {
            text-align: center;
        }
    }
    .newsletter-form {
        text-align: right;
        @media #{$max-tablet} {
            text-align: center;
            margin-top:10px;
        }
    }
}

.marginal-page-content {
    .newsletter-box {
        input.input-text {
            min-width: 200px;
        }
        .subtitle{
            display: block;
            .subtitle-divider {
                display:none;
            }
        }
        .icon-newsletter {
            font-size: 40px;
            float: left;
        }
        .newsletter-box-title {
            line-height: 1;
            display: block;
        }
        .newsletter-form {
            margin-top: 5px;
        }
    }
}

::-webkit-input-placeholder {
    font-family: $NotoSans;
    color: $clr-white;
    @include font-size($font-size-base);
}

:-moz-placeholder {
    font-family: $NotoSans;
    color: $clr-white;
    @include font-size($font-size-base);
}

::-moz-placeholder {
    font-family: $NotoSans;
    color: $clr-white;
    @include font-size($font-size-base);
}

:-ms-input-placeholder {
    font-family: $NotoSans;
    color: $clr-white;
    @include font-size($font-size-base);
}
