.references-wrapper {
  .references-filter {
    display: flex;
    &>.dropdown-wrapper {
      @media (min-width: 769px) {
        width: calc(33.33% - 20px);
        &:nth-child(2) {
          margin: 0 30px;
        }
      }
      @include bp-max-smartphone {
        width: 100%;
      }
    }
  }
  .references-items {
    display: flex;
    flex-wrap: wrap;
    @include bp-max-tablet {
      justify-content: space-between;
    }
    .references-item {
      display: flex;
      flex-direction: column;
      @media (min-width: 992px) {
        width: 33.33%;
        padding: 15px;
      }
      @include bp-max-tablet {
        width: 50%;
        padding: 0 15px;
      }
      @include bp-max-smartphone {
        width: 100%;
      }
      .button-module {
        margin-top: -15px;
      }
    }
    & .image-module {
      overflow: hidden;
      img {
        &.text-image-teaser-image {
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          &:hover {
            opacity: 1;
            position: relative;
            transform: scale(1.15);
          }
        }
      }
    }
  }
}