.cart-overview-content {
  h3 {
    font-size: 20px;
  }

  .shipping-information {
    margin-bottom: 30px;
    padding-left: 20px;

    @include bp-max-s-smartphone {
      padding-left: 10px;
    }
  }

  .empty-cart {
    p {
      margin-bottom: 30px;

      @include bp-max-s-smartphone {
        margin-bottom: 20px;
      }
    }
  }

  .cart-information {
    margin-bottom: 30px;

    .cart-other {
      @include bp-tablet-only {
        margin-bottom: 30px;
      }
    }

    .cart-other, .cart-redeem {
      @include bp-max-smartphone {
        margin-bottom: 30px;
      }
    }

    .cart-redeem, .checkout-overview {
      @include bp-tablet {
        min-height: 220px;
      }

      @include bp-desktop-only {
        min-height: 240px;
      }
    }

    .checkout-overview, .cart-redeem {
      display: flex;
      align-items: stretch;
    }

    .checkout-overview {
      margin-bottom: 0;
    }

    .cart-redeem-content {
      padding: 20px;
      border: 1px solid $clr-border-grey;
      flex: 1;

      form {
        border: 0;
        margin: 0;
        padding: 0;
      }

      .voucher-code {
        display: flex;
        justify-content: space-between;
        color: $clr-wilo-green;
        margin: 20px 0;

        .icon-link {
          color: $clr-mega-menue-grey;

          &:hover, &:active, &:focus {
            color: $clr-wilo-green;
          }
        }
      }
    }

    .cart-cost {
      padding: 20px;
      min-height: 100%;
      flex: 1;

      .title, ul {
        display: none;
      }
    }
  }

  .cart-buttons {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;

    @include bp-max-s-smartphone {
      flex-direction: column;

      .wilo-btn {
        width: 100%;
        margin-top: 15px;

        &:last-child {
          margin-top: 5px;
        }
      }
    }
  }
}

.cart-redeem {
  color: $clr-gunmetal;

  p {
    margin-bottom: 5px;
  }

  .title {
    font-family: $NotoBold;
    color: $clr-text-darkblue;
  }

  form {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $clr-border-grey;
  }
}



/* Diese Styles gelten für Cart Overview, Order Checkout und Webshop */

.cart-cost {
  p {
    display: flex;
    margin-bottom: 5px;

    span {
      margin-left: auto;
    }

    &.voucher-amount span {
      color: $clr-wilo-green;
    }

    &.total {
      border-top: 1px solid $clr-border-grey;
      margin-top: 25px;
      padding-top: 15px;
      font-size: 18px;
    }

    &.total, &.total span {
      font-family: $NotoBold;
    }
  }
}


table.product-overview {
  width: 100%;
  margin-bottom: 30px;

  thead {
    @include bp-max-smartphone {
      display: none;
    }
  }

  th {
    color: $clr-wilo-green;
    padding-top: 10px;
    line-height: 1;
    text-align: center;

    &:first-child {
      text-align: left;

      p {
        padding-left: 20px;
      }
    }

    p {
      color: $clr-wilo-green;
      font-family: $NotoBold;
      @include font-size(18px);
      margin-bottom: 10px;
    }

    div {
      height: 2px;
      width: 100%;
      background-color: $clr-wilo-green;
    }

    &.quantity, &.buy {
      @include bp-desktop-large {
        width: 20%;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $clr-crystal-white;
      }

      &:nth-child(even) {
        background-color: $clr-white;
      }

      &:after {
        line-height: 3px;
      }

      td {
        text-align: center;
        padding: 20px;

        &:first-child {
          text-align: left;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .product-information {
    display: flex;
    align-items: center;

    .product-image {
      width: 65px;
      min-width: 65px;
      height: 65px;
      border: 1px solid #dddddd;
      margin-right: 20px;
      padding: 10px;
      display: flex;
      background-color: #fff;
      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .product-description {
      .product-name, .product-number {
        margin-bottom: 0;
      }

      .product-name {
        color: $clr-wilo-green;
      }

      .product-number {
        @include font-size(14px);
      }
    }
  }

  .product-name,
  .price,
  .price-gross,
  .price-total {
    font-family: $NotoBold;
  }

  .price-total {
    @include bp-max-smartphone {
      display: none;
    }
  }

  .price-excl-vat {
    @include font-size(14px);
  }

  .wilo-btn-shop {
    @include bp-max-tablet {
      font-size: 0;
      padding: 5px 10px;
      height: auto;
      text-align: center;

      &:before {
        margin-right: 0;
        font-size: 20px;
      }
    }
  }
}

@include bp-max-s-smartphone {
  table.product-overview {
    .price {
      padding: 8px 0 5px 0;
    }
  }
}

@include bp-max-smartphone {
  table.product-overview {
    tbody {
      display: flex;
      flex-direction: column;
      margin-bottom: 3px;
      width: 100%;

      tr {
        margin-bottom: 3px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;

        td {
          padding: 0;
          margin: auto 0;

          &:first-child {
            margin-bottom: 10px;
            width: 100%;
          }
        }
      }
    }

    .price {
      width: 120px;
      margin-right: 20px;
      text-align: left;
    }

    .quantity {
      flex: 1;

      .quantity-module {
        justify-content: left;

        .button {
          display: inherit;
        }
      }
    }

    .product-information {
      .product-image {
        width: 80px;
        height: 80px;
      }
    }
  }
}
