// Forms
// Buttons
.wilo-btn {
  padding: 10px 20px 10px 10px;
  line-height: 16px;
  font-family: $NotoBold;
  position: relative;
  cursor: pointer;
  border: none;
  @include font-size($font-size-base); // was 14px
  text-align: left;
  height: 38px;
  transition: 0.15s background-color ease-in-out;
  display: inline-block;
  margin: 0 5px 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    margin: 0;
  }
  &:disabled {
    font-style: normal;
  }
  &:before {
    font-family: $WiloIcons;
    content: "\e903";
    margin: 0 5px 0 0;
    font-size: 12px;
    vertical-align: top;
  }
  &.wilo-button-upload,
  &.wilo-btn-primary,
  &.wilo-btn-secondary,
  &.wilo-btn-yellow,
  &.wilo-btn-inverted,
  &.wilo-btn-special,
  &.wilo-btn-shop {
    &[disabled] {
      cursor: default;
      background-color: #dddddd;
      color: #555555;
      font-style: italic;
      font-family: $NotoSans;
      &:hover {
        background-color: #dddddd;
        color: #555555;
      }
    }
  }
  &.wilo-btn-back {
    &:before {
      content: "\e902";
    }
  }
  &.wilo-button-upload {
    :before {
      content: "\e94e";
      margin: 0 7px 0 0;
      font-size: 13px;
    }
  }
  &.wilo-btn-primary {
    color: $clr-white;
    background-color: $clr-text-darkblue;
    &:hover,
    &:focus,
    &:active {
      background-color: $clr-wilo-green;
    }
  }
  &.wilo-btn-secondary {
    color: $clr-white;
    background-color: $clr-mega-menue-grey;
    &:hover,
    &:focus,
    &:active {
      background-color: #575757;
    }
  }
  &.wilo-btn-yellow,
  &.wilo-btn-shop {
    color: $clr-gunmetal;
    background-color: #ffb400;
    &:hover,
    &:focus,
    &:active {
      background-color: #eba500;
    }
  }
  &.wilo-btn-shop {
    &:before {
      content: "\e923";
      margin-right: 12px;
    }
  }
  &.wilo-btn-inverted {
    color: $clr-text-darkblue;
    background-color: $clr-white;
    &:hover,
    &:focus,
    &:active {
      background-color: $clr-wilo-green;
      color: $clr-white;
    }
    &.green-bg {
      &:hover,
      &:focus,
      &:active {
        background-color: darken($clr-white, 10%);
        color: $clr-gunmetal;
      }
    }
  }
  &.wilo-btn-special {
    color: $clr-white;
    background-color: $clr-wilo-green;
    &:hover,
    &:focus,
    &:active {
      background-color: $clr-wilo-green;
    }
  }
}

.mwf-hidden {
  display: none;
}

.mwf-next,
.mwf-cancel {
  float: right;
}

.mwf-button {
  display: inline-block;
}

.mwf-pager {
  text-align: center;
}

.mwf-back {
  cursor: pointer;
}

.mwf-upload-delete,
.mwf-upload-upload {
  @extend .wilo-btn;
  margin: 5px 10px 5px 0;
}

// Tooltips
.icon-hint {
  color: $clr-mega-menue-grey;
  margin-left: 2px;
  @include font-size(12px);
  line-height: inherit;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #787878;
}

.tooltip-inner {
  background-color: $clr-mega-menue-grey;
  padding: 5px 15px;
  border-radius: 0;
}

// Input
.validate-icon {
  position: relative;
  display: flex;
  .wilo-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    @include font-size($font-size-base);
    color: $clr-wilo-green;
    display: none;
  }
  &.error {
    .icon-alert {
      display: block;
      color: $clr-text-error;
    }
    input {
      border-color: $clr-text-error;
      z-index: 0;
    }
  }
  &.success {
    .icon-tick {
      font-size: 12px;
      display: block;
      color: $clr-wilo-green;
    }
  }
}

// Input group incl. error message
.form-row {
  .input-group {
    display: flex;
    flex-flow: row wrap;
    .validate-icon {
      flex: 1;
    }
    input {
      z-index: 0;
      &:focus {
        z-index: 0;
      }
    }
    .invalid-feedback {
      display: none;
    }
    .wilo-btn {
      margin-left: 15px;
      margin-right: 0;
      @include bp-max-s-smartphone {
        margin: 15px 0 0 0;
        order: 3;
      }
    }
    @include bp-max-s-smartphone {
      flex-direction: column;
    }
  }
}

// Error messages
.has-error{
  color: red;
}


.mwf-textinput__input,
.mwf-textarea__textarea {
  &.form-control {
    margin-bottom: 10px;

    &:focus {
      border-color: $clr-wilo-green;
      box-shadow: none;
      outline: 0;
    }
  }
}

// Image Chooser
.wilo-image-chooser-wrapper {
  padding: 15px;
  border: 2px dashed #ddd;
  text-align: center;
  .wilo-image-chooser-text {
    padding: 0 20%;
  }
  .no-image {
    font-family: $NotoBold;
    font-size: 18px;
  }
  .wilo-image-chooser {
    display: inline-block;
  }
  .icon-photocamera {
    color: $clr-mega-menue-grey;
    @include font-size(56px);
  }
}

.wilo-image-chooser {
  .wilo-image-chooser-button {
    text-align: center;
    float: left;
    margin: 5px;
    padding: 10px;
    background-color: $clr-text-darkblue;
    &:hover {
      background-color: $clr-wilo-green;
    }
    p,
    span {
      color: $clr-white;
      font-family: $NotoBold;
      margin-bottom: 0;
    }
    span {
      @include font-size(18px);
    }
  }
}

// General Form Element
.form-element {
  margin-bottom: 15px;
  label {
    color: $clr-mega-menue-grey;
  }
}

input[type="radio"][disabled],
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
fieldset[disabled] input[type="checkbox"] {
  cursor: default;
}

input[type="text"]:disabled {
  cursor: default;
  background-color: #f1f1f1;
  box-shadow: none;
  border: 1px solid #f1f1f1;
  font-style: italic;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border: 1px solid $clr-wilo-green;
  outline: none;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mwf-checkbox,
.mwf-radio {
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  input {
    display: none;
  }
  .mwf-checkbox__text,
  .mwf-radio__text {
    @include font-size($font-size-base);
    display: inline-block;
    position: relative;
    margin-left: 30px;
    cursor: pointer;
  }
}

.mwf-form {
  &__response {
    color: $clr-white;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 40px;
    > span {
      display: inline-block;
    }
    .wilo-icon {
      @include font-size(80px);
      margin-bottom: 25px;
      &.icon-tick {
        border-radius: 50%;
        border: 5px solid $clr-white;
        padding: 25px 15px 15px 15px;
        font-size: 30px;
        height: 80px;
        width: 80px;
      }
    }
    span.headline {
      display: block;
      @include font-size(18px);
      font-family: $NotoBold;
      margin-bottom: 0;
    }
    .btn {
      // inverted Button Style
      margin-top: 10px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.success {
      background-color: $clr-wilo-green;
    }
    &.failure {
      background-color: $clr-neon-orange-light;
    }
  }

  .mwf-row {
    margin-bottom: 1.5em;
  }
}

.form-section {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.form-inline {
  .form-group  {
    select, input {
      display: inline-block;
    }
  }
  label {
    display: block;
    width: 100%;
  }
}

button:disabled:before {
  font-style: normal;
}


input[type="checkbox"] ~ label,
input[type="radio"] ~ label {
  height: 20px;
  width: 100%;
  display: inline-block;
  padding: 0 0 0 0;
  margin: 0 10px 0 0;
  cursor: pointer;
  &:before {
    position: absolute;
    display: inline-block;
    background-size: cover;
    content: " ";
    width: 20px;
    height: 20px;
  }
}

input[type="radio"] ~ label:before {
  background-image: url(/resources/img/radio_default.png);
}

input[type="radio"]:checked ~ label:before {
  background-image: url(/resources/img/radio_selected.png);
}

input[type="radio"]:disabled ~ label:before {
  background-image: url(/resources/img/radio_disabled.png);
  cursor: default;
}

input[type="radio"]:checked:disabled ~ label:before {
  background-image: url(/resources/img/radio_selected_disabled.png);
  cursor: default;
}

input[type="checkbox"] ~ label:before {
  background-image: url(/resources/img/checkbox.png);
}

input[type="checkbox"]:checked ~ label:before {
  background-image: url(/resources/img/checkbox_active.png);
}

input[type="checkbox"]:disabled ~ label:before {
  background-image: url(/resources/img/checkbox_disabled.png);
  cursor: default;
}

input[type="checkbox"]:checked:disabled ~ label:before {
  background-image: url(/resources/img/checkbox_disabled_checked.png);
  cursor: default;
}

.wilo-dark-blue:before,
.wilo-inverted:before,
.table-module table tr td a:before {
  font-family: $WiloIcons;
  content: "\e903";
  margin: 0 5px 0 0;
  @include font-size(10px);
}

.styled-form {
  &.form-group {
    @include bp-max-tablet {
      width: 100%;
    }
  }
}

.custom-formgroup {
  width: 100%;
  .select2-container {
    width: 70% !important;
    margin-right: 5px;
  }
  button.btn.btn-default {
    width: calc(30% - 9px);
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.select-module label {
  display: block;
  color: $clr-mega-menue-grey;
}

// Progressbar
.mwf-form-steps {
  vertical-align: middle;
  .mwf-form-steps__list {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 30px;
    .mwf-form-steps__item {
      list-style: none;
      cursor: pointer;
      @include font-size(14px);
      text-align: center;
      color: #666;
      margin: 0 2px;
      padding: 10px 10px 10px 20px;
      float: left;
      position: relative;
      background-color: $clr-border-grey;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 20px;
      min-height: 40px;
      &:after,
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
      &:after {
        content: " ";
        right: -10px;
        border-left: 10px solid $clr-border-grey;
        z-index: 2;
      }
      &:before {
        right: auto;
        left: 0;
        border-left: 10px solid $clr-white;
        z-index: 0;
      }
      &:first-child {
        margin-left: 0;
        &:before {
          border: none;
        }
      }
      span {
        position: relative;
        font-family: $NotoBold;
        display: inline-block;

        &:before {
          opacity: 0; //content: "✔";
          position: absolute;
          top: -2px;
          left: -20px;
        }
      }
      &--current {
        color: $clr-white;
        background-color: $clr-wilo-green-dark;
        padding-right: 0;
        z-index: 1;
        flex: 5;
        display: flex;
        &:after {
          border-left: 10px solid $clr-wilo-green;
          @include bp-max-tablet {
            border-left: 10px solid $clr-wilo-green-dark;
          }
        }
        @include bp-max-smartphone {
          padding: 10px 10px 10px 20px;
        }
        span {
          &:first-child {
            padding-right: 10px;
            padding-left: 5px;
            margin-left: 0;
            @include bp-max-smartphone {
              padding-right: initial;
              padding-left: initial;
              margin-left: initial;
            }
            &:after,
            &:before {
              content: " ";
              position: absolute;
              top: -10px;
              width: 0;
              height: 0;
              border-top: 20px solid transparent;
              border-bottom: 20px solid transparent;
              @include bp-max-smartphone {
                display: none;
              }
            }
            &:after {
              right: -10px;
              border-left: 10px solid $clr-wilo-green-dark;
              z-index: 2;
            }
            &:before {
              right: auto;
              left: 0;
              border-left: 10px solid $clr-white;
              z-index: 0;
            }
            &:first-child:before {
              border: none;
            }
          }
        }
        &.mwf-form-steps__item .desc-box {
          display: inline-block; //min-width: 878px;
          flex: 5;
          text-align: left;
          margin-top: -10px;
          margin-bottom: -10px;
          background: $clr-wilo-green;
          padding: 11px 10px 11px 25px;
          @include bp-max-desktop {
            //min-width: 678px;
          }
          @include bp-max-tablet {
            display: none;
          }
        }
      }
      .desc-box {
        display: none;
      }
      &:last-child {
        &:after {
          border-left: 0;
        }
      }
    }
  }
}

// Response Boxes
.form-response {
  color: $clr-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 40px;
  > span {
    display: inline-block;
  }
  .wilo-icon {
    @include font-size(80px);
    margin-bottom: 25px;
    &.icon-tick {
      border-radius: 50%;
      border: 5px solid $clr-white;
      padding: 25px 15px 15px 15px;
      font-size: 30px;
      height: 80px;
      width: 80px;
    }
  }
  span.headline {
    display: block;
    @include font-size(18px);
    font-family: $NotoBold;
    margin-bottom: 0;
  }
  .btn {
    // inverted Button Style
    margin-top: 10px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &[data-mwf-form-success] {
    background-color: $clr-wilo-green;
  }
  &[data-mwf-form-error] {
    background-color: $clr-neon-orange-light;
  }
}

.mwf-file__uploaded {
  .mwf-file__thumbnail img {
    width: unset;
  }

  .mwf-file__file-button {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  .mwf-file__file-info span {
    margin-right: 0.5rem;

    &.mwf-file__file-error {
      color: red;
    }
  }

  .mwf-file__ul {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
  }
}
