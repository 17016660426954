.bsr-video-teaser {
    &__textWrapper {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__headline {
        font-size: 1.5rem;
        &,
        * {
            margin: 0;
        }
    }

    &__text {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1em;

        * {
            margin: 0;
        }
    }

    &__tiles {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 15px;
    }

    &-tile {
        grid-column: span 12;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $clr-dark-grey;

        &__video {
            aspect-ratio: 16/9;
            iframe {
                width: 100%;
                height: 100%;
            }           
        }

        &__headline {
            font-family: $NotoBold;
            font-size: 1.25rem;
            line-height: 1.5;
            &,
            *:last-child {
                margin: 0;
            }
        }

        &__text {
            font-size: 1rem;
            &,
            *:last-child {
                margin: 0;
            }
        }
    }

    @media #{$tablet} {
        &__textWrapper {
            padding-bottom: 48px;
        }
        &__tiles {
            column-gap: 30px;
        }
        &-tile {
            grid-column: span 4;
        }
    }

    @media #{$desktop} {
   
    }
}