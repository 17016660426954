.marginal-paragraph {
    padding: 40px 0;
    border-bottom: 1px solid $clr-border-grey;

    .text-image-teaser-text {
      color: $clr-gunmetal;
    }

    &.no-border {
        border-bottom: none;
        padding-bottom: 10px;
    }
    &:first-child {
        padding-top: 0;
        @include bp-smartphone-only {
            margin-top: 30px;
            border-top: 1px solid $clr-border-grey;
        }
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 10px;
    }
    @include bp-smartphone-only {
        padding: 15px 0;
    }
    .marginal-headline,
    .marginal-text,
    .marginal-sub-headline {
        margin-bottom: 15px;
    }
    .marginal-headline,
    .marginal-sub-headline {
        font-family: $NotoBold;
        line-height: 1;
    }
    .marginal-headline {
        font-size: 18px;
        color: $clr-text-darkblue;
    }
    .marginal-text,
    .marginal-sub-headline {
        @include font-size(16px);
        color: $clr-mega-menue-grey;
    }
    .marginal-sub-headline {
        @include font-size($font-size-base);
    }
    .link-list-item-text-wrapper {
        display: inline-table;
        width: 90%;
    }
}

.marginal-page-content {
    .link-list-item-text-wrapper {
        a {
            color: $clr-wilo-green;
        }
    }
}

.marginal-col {
    .marginal-dark-box {
        background-color: $clr-text-darkblue;
        color: $clr-white;
        padding: 35px 30px;
        margin-bottom: 30px;
    }
    .marginal-header {
        font-family: $WILOPlusFMBold;
        font-size: 18px;
        margin-bottom: 15px;
    }
    .video-module-video {
        margin-bottom: 10px;
    }
    .video-module .video-wrapper .video-playbutton {
        width: 70px;
        top: 40%;
    }
    .video-module .video-wrapper .video-playtext {
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        width: auto;
        top: 60%;
        left: 50%;
        z-index: 20;
        cursor: pointer;
        color: $clr-wilo-green;
        font-family: $WILOPlusFMBold;
        font-size: 20px;
    }
}
