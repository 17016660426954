.text-on-image {
    scroll-snap-align: start;
    $self: &;
    position: relative;
    height: 100svh;

    &:not(.text-on-image--in-view) {
        #{$self}__wave,
        #{$self}__caption-wrapper {
            opacity: 0;
            transition-delay: 0.5s;
            transition-duration: 0.1s;
        }   
        &#{$self}--left {
            #{$self}__caption-wrapper {
                translate: -50% 0;
            }
        }
        &#{$self}--right {
            #{$self}__caption-wrapper {
                translate: 50% 0;
            }
        } 
    }

    @media #{$tablet} {
        &:not(.text-on-image--in-view) {  
            &#{$self}--left {
                #{$self}__wave {
                    translate: -5% 0;
                }
                #{$self}__caption-wrapper {
                    translate: -50% -50%;
                }
            }
            &#{$self}--right {
                #{$self}__wave {
                    translate: 5% 0;
                }
                #{$self}__caption-wrapper {
                    translate: 50% -50%;
                }
            }
        }
    }

    .container {
        position: relative;
        height: 100%;
        display: flex;
    }

    &__wave,
    &__caption-wrapper {
        transition-property: opacity, translate, grid-template-rows;
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
    }
    &__wave {
        transition-delay: 0.2s;
    }

    &__image { 
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        
        img {
            min-width: 100%;
            height: 100%;
            width: auto;
            object-position: center;
            object-fit: fill;
        }
    }

    &__wave {
        display: none;

        @media #{$tablet} {
            position: absolute;
            display: block;
            bottom: 0;
        }
    }

    @media #{$tablet} {
        &--left {
            .container {
                justify-content: flex-start;
            }
        }
        &--right {
            .container {
                justify-content: flex-end;
            }
        }
    }

    &__caption-wrapper {
        position: absolute;
        inset: 64px 24px 80px;
        padding: 2rem;
        min-height: 50%;
        opacity: 1;
        @include auto-word-wrap;

        @media #{$tablet} {
            position: absolute;
            inset: unset;
            top: 50%;
            translate: 0 -50%;
            width: clamp(200px, 50%, 504px);
        }

        &--gradient {
            background: linear-gradient(
                180deg, 
                rgba(0, 156, 130, 0.80) 0%, 
                rgba(3, 49, 75, 0.80) 100%
            );
        }
    }
    &__title,
    &__text,
    &__links a {
        color: $clr-white;

    }
    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.25rem;       
    }
    &__title {
        font-weight: 700;
        font-size: 1.875rem;
        line-height: 2.5rem;
        padding-bottom: 20px;
    }
    &__text {
        font-size: 1rem;
        line-height: 1.5rem;
        &:not(:last-child) {
            padding-bottom: 20px;
        }

        p {
            padding: 0;
        }
    }
    
}