.product-detail {
  .product-header {
    .product-title {
      font-family: $NotoBold;
      font-size: 24px;
      margin-top: 11px;
      margin-bottom: 10px;
    }
    .product-header-actions {
      text-align: right;
      @include bp-max-smartphone {
        text-align: left;
        margin-bottom: 10px;
      }
      .btn {
        margin-left: 6px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .section-divide {
    margin: 30px 0;
  }
  .tab- {
    margin-bottom: 30px;
  } // tab: data sheet
  .tab-pane-data-sheet {
    .curve-images {
      .curve-images-title {
        font-size: 19px;
        font-family: $NotoBold;
        color: $clr-black;
        margin-top: 10px;
      }
      .curve-img {
        margin-bottom: 15px;
      }
    }
  }
  .tab-type-gallery {
    .gallery-images-title {
      font-size: 19px;
      font-family: $NotoBold;
      color: $clr-black;
      margin-top: 10px;
    }
    .gallery-img {
      margin-bottom: 15px;
    }
    .gallery-thumbnails {
      padding: 0 27px;
      &.less-than-five {
        padding-left: 0;
      }
      @include bp-max-tablet {
        margin-bottom: 15px;
      }
      .thumb-img {
        margin-right: 4px;
        padding: 1px;
        cursor: pointer;
        border: transparent solid 3px;
        &:last-child {
          margin-right: 0;
        }
        img {
          border: 1px #7f7f7f solid;
          width: auto;
        }
        &.slick-current {
          border: $clr-text-darkblue solid 3px;
          img {
            border-width: 0;
          }
        }
      }
      .slick-next.slick-arrow {
        background: url("/resources/img/curve-thumbnails-button-right.png") transparent
          no-repeat;
        width: 16px;
        height: 27px;
        &:before {
          display: none;
        }
      }
      .slick-prev.slick-arrow {
        @extend .slick-next;
        @extend  .slick-arrow;
        background-image: url("/resources/img/curve-thumbnails-button-left.png");
      }
    }
    .gallery-image-big {
      img {
        width: auto;
        max-width: 100%;
        margin-bottom: 15px;
      }
      .gallery-subtitle {
        margin-bottom: 30px;
      }
    }
  }
  .information-order-placement-table-title {
    font-size: 19px;
    font-family: $NotoBold;
    color: $clr-black;
    margin-top: 10px;
  }
}

// Used on Product Detail / Series
.gallery-image-big {
  .gallery-title {
    margin-top: 15px;
    font-weight: bold;
  }
}

.facts-content {
  .highlighted-content .section-divide {
    margin: 15px auto;
  }
  strong {
    font-family: $NotoBold;
    color: $clr-text-darkblue;
    margin: 0 0 10px 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $NotoBold;
    font-size: 18px;
    color: $clr-text-darkblue;
    margin: 0 0 10px 0;
  }
  h4 {
    font-size: 16px;
    display: inline-block;
    -webkit-touch-callout: none;
    @include prefix(
      (
        user-select: none,
      ),
      webkit khtml moz ms
    );
  }
  h2 {
    font-size: 18px;
  }
  h5[data-toggle] {
    margin-top: 0;
  }
  h4[data-toggle]:before,
  h5[data-toggle]:before {
    display: inline;
    font-family: $WiloIcons !important;
    @include font-size(10px);
    padding-right: 15px;
    content: "\e901";
  }
  ul.arrow-list {
    li {
      display: flex;
      text-indent: 0;
      margin-left: 0;
    }
  }
  ul.prod_detail--datalist,
  ul.prod_detail--list {
    margin: 0 0 30px 0;
    padding: 0;
    width: 100%;
    border-bottom: 2px solid $clr-wilo-green;
    border-top: 2px solid $clr-wilo-green;
    li {
      display: flex;
      padding: 24px 15px;
      color: $clr-text-regular;
      &:nth-child(2n + 1) {
        background-color: $clr-crystal-white;
      }
      > div {
        p {
          margin: 0;
        }
        &:first-child {
          padding-left: 0;
          font-family: $NotoBold;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  ul.prod_detail--list {
    li {
      flex-direction: column;
      ul {
        display: flex;
        flex-direction: column;
        border: 0;
        list-style-type: disc;
        margin-bottom: 0;
        li {
          background-color: transparent;
          padding-bottom: 0;
          &:nth-child(2n + 1) {
            background-color: transparent;
            padding-bottom: 0;
          }
          &:before {
            width: 4px;
            height: 4px;
            background-color: black;
            display: block;
            border-radius: 50%;
            position: absolute;
            content: "";
            margin-left: -15px;
            margin-top: 8px;
          }

          ul {
            li {
              &:before {
                height: 1px;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

.product-detail-images {
  padding: 24px 0;
}

.product-detail-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-thumbnails {
  display: flex;
  max-width: 434px;
  margin: auto;
  justify-content: center;
  .slick-list {
    width: 296px;
  }
  @media (max-width: 480px) {
    width: 214px;
    .slick-list {
      width: 74px;
    }
  }
  .slick-arrow {
    cursor: pointer;
    background-color: #009c82;
    color: #fff;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 64px !important;
    height: 64px;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.thumbnails {
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.product-detail-thumbnail {
  margin-right: 10px;
  padding: 4px;
  border: 1px solid $clr-border-grey;
  width: 64px;
  height: 64px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  @include bp-max-s-smartphone {
    max-width: 64px;
  }
  &.slick-current {
    border: 1px solid $clr-wilo-green;
  }
}

@include bp-max-tablet {
  .series-information {
    > .row {
      display: flex;
      flex-direction: column;
      .data-sheet-slider {
        order: 0;
      }
      .facts-content {
        order: 1;
      }
    }
  }
}
