@import "~@wilo/common/css/fonts.css";
@import "~@wilo/common/css/icons.css";
// BSR TODO: remove import once fa 6 svg are available
@import "~@wilo/common/css/fontawesome/all.css";
$side-menu-width: calc(100vw - 60px);
$side-menu-width-negative: calc(-1 * #{$side-menu-width});

$side-menu-width-max-bp: 500px;
$side-menu-width-max: 440px;
$side-menu-width-max-negative: calc(-1 * #{$side-menu-width-max});

$NotoSans: "Noto Sans", sans-serif;
$NotoBold: "NotoBold", sans-serif;
$NotoBoldItalic: "NotoBoldItalic", sans-serif;
$NotoItalic: "NotoItalic", sans-serif;

$WiloIcons: "wilo-icons", sans-serif;