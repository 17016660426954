// 404 Styles

.error-page {
  display:flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;

  #animate-404 {
    height: 60vh;
    max-height: 80vw;
  }

  h1 {
    display:block;
    font-size: 60px;
    margin-bottom: 30px;
  }

  .column-wrap {
    display:flex;
    &:first-of-type {
      border-bottom:1px solid $clr-wilo-join-grey;
      margin-bottom:50px;
    }

  }

  .left-side {
    -webkit-flex: 2 0 0;
    flex: 2 0 0;
    flex-direction: row;
    -webkit-flex-direction: row;
    display: flex;
    align-items: baseline;
    &:first-of-type {
      p {
        margin: 0 0 50px 0;
        font-size:20px;
        @include bp-desktop {
          margin:0 0 0 40px;
        }
      }
    }

    @include bp-max-tablet {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
  }

  
  .right-side {
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    margin-left:30px;
  }

  img {
    max-width:100%;
    height:auto;
  }
}
