// Cookies

.cookie-box {
    display:none;
    background: green;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: rgba(54, 54, 64, 0.85);
    color: $clr-white;
    .cookie-box-inner {
        padding: 20px 0;
        a {
            color: $clr-wilo-green;
        }
    }
}

