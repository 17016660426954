.wilo-webshop-teaser-module {
    border: 1px solid #f1f1f1;

    .webshop-teaser-content {
        padding: 30px;

        .pump-replacement-teaser-title {
            font-size: 18px;
            font-family: $NotoBold;
            color: #363640;
        }
        .webshop-item-slider {
            .price {
                font-family: $NotoBold;
                color: #363640;
            }

            img {
                max-height: 135px;
                width: auto;
                margin: 0 auto;
            }
        }
    }
    .webshop-enter {
        background-color: #f1f1f1;
        padding: 30px;
        text-align: right;

        @include bp-max-s-smartphone {
            .buttons {
                display: flex;
                flex-direction: column;

                .wilo-btn {
                    margin: 5px 0 0;
                    width: 100%;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .wilo-btn {
            margin-bottom: 0;
        }
    }
}
