.highlighted-headline__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: $clr-text-darkblue;

  &.wilo-green {
    background-color: $clr-wilo-green;
  }
  &.wilo-grey {
    background-color: $clr-light-grey;
    h2,
    h3,
    p {
      color: $clr-black;
    }
  }

  h2,
  h3,
  p {
    color: $clr-white;
    margin: 0;
  }
  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    font-family: $NotoBold;
  }

  p {
    font-size: 1rem;
  }

  p:not(:first-child),
  h3:not(:first-child) {
    margin-top: 1rem !important;
  }
}
