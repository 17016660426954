.navbar-alternate {
  background: $clr-white;

  .brand-header {
      margin-top: 20px;
      margin-bottom: 20px;
      @include font-size(57px);
      color: $clr-text-green;
  } 
  .navbar-slogan {
    margin-top: 55px;
  }
}
