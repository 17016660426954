@use "sass:math";
@import "./keyFiguresTeaser.scss";
@import "./defaultTeaser.scss";
@import "./contactTeaser.scss";
@import "./downloadTeaser.scss";

.four-column-teaser {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;

  width: 100%;

  grid-gap: 1rem;
  a {
    color: white;
  }

  @mixin template-areas-large {
    &.equal-sized-grid {
      grid-template-columns: repeat(3, 1fr);
    }

    grid-template-areas: ". . ." ". . ." ". . ." ". . .";
    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    -ms-grid-rows: 300px 10px 300px 10px 300px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    .teaser-3 {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }

    .teaser-4 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-5 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    .teaser-6 {
      -ms-grid-row: 3;
      -ms-grid-column: 5;
    }

    .teaser-7 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }

    .teaser-9 {
      -ms-grid-row: 5;
      -ms-grid-column: 5;
    }
    /** End IE11 base-layout **/
  }

  @mixin template-areas-medium {
    &.equal-sized-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    grid-template-areas:
      ". . "
      ". . "
      ". . "
      ". . ";

    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr 10px 1fr;
    -ms-grid-rows: 300px 10px 300px 10px 300px 10px 300px 10px 300px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    .teaser-3 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-4 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    .teaser-5 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-6 {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }

    .teaser-7 {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 9;
      -ms-grid-column: 3;
    }

    .teaser-9 {
      -ms-grid-row: 9;
      -ms-grid-column: 1;
    }

    /** End IE11 base-layout **/
  }

  @mixin template-areas-small {
    &.equal-sized-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    
    grid-template-areas:
      "."
      "."
      "."
      ".";

    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 250px 10px 250px 10px 250px 10px 250px 10px 250px 10px 250px
      10px 250px 10px 250px 10px 250px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-3 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-4 {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
    }

    .teaser-5 {
      -ms-grid-row: 9;
      -ms-grid-column: 1;
    }

    .teaser-6 {
      -ms-grid-row: 11;
      -ms-grid-column: 1;
    }

    .teaser-7 {
      -ms-grid-row: 13;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 15;
      -ms-grid-column: 1;
    }

    .teaser-9 {
      -ms-grid-row: 17;
      -ms-grid-column: 1;
    }

    /** End IE11 base-layout **/
  }

  @include template-areas-small;

  @media (min-width: 690px) {
    @include template-areas-medium;
  }

  @media (min-width: 1024px) {
    @include template-areas-large;

    &.top-left {
      grid-template-areas:
        "a a ."
        "a a ."
        ". . .";
    }

    &.top-right {
      grid-template-areas:
        ".  a a"
        ".  a a"
        ".  . .";
    }

    &.bottom-left {
      grid-template-areas:
        ". .  ."
        "a a  ."
        "a a  .";
    }

    &.bottom-right {
      grid-template-areas:
        ".  . ."
        ".  a a"
        ".  a a";
    }

    .highlight {
      grid-area: a;
    }
  }

  & > div {
    @media (min-width: 500px) and (max-width: 689px) {
      padding-top: 60%;
    }
  }

  //overwrite mb--4 class to be able to reuse FS-Template and avoid redundance
  .teaser.mb--4 {
    margin-bottom: 0;
  }
}
