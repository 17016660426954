.ecolution-lead {
    $self: &;
    padding-bottom: 95px;
    color: $clr-white;
    @media (min-width: 768px) { 
        padding-bottom: 120px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 48px;
        @media (min-width: 768px) { 
            gap: 72px;
        }

       
    }

    &__headline {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        margin-bottom: 10px;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &-lottie {
            height: 76px;
            width: auto;
            padding-left: 0.25em;
            padding-bottom: 2px;
        }


        @media (min-width: 768px) { 
            margin-bottom: 55px;
        }
    }

    &__text {

        p:last-child {
            margin: 0;
        }


        @media (min-width: 768px) {
            width: 65%;
            &--left {
                align-self: start;
            }
            
            &--right {
                align-self: end;
            }
        }
    }


}