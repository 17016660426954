.text-list-marginal-wrapper {
    .marginal-text {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .link-list {
        margin: 15px 0 0 0;
        .link-list-item {
            a {
                color: $clr-wilo-green;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}