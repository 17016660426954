.globalBadge {
    position: fixed;
    left: 30px;
    bottom: 30px;
    z-index: 100;

    .globalBadge__link {
        border-radius: 50%;
        display: block;
        border: 1px solid #009c82;
        overflow: hidden;
        padding: 15px;
        background-color: #fff;
    }

    &__close {
        background-color: #009c82;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    &__img {
        width: 162px;
        height: 162px;
    }
    @media (max-width: 768px) {
        display: none;
    }
}