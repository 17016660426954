.wilo-profile-wrapper {
    .wilo-profile-stage {
        .wilo-profile-stage-image {
            background-image: url(/resources/img/dashboard-keyvisual.jpg);
            height: 350px;
            margin-bottom: 30px;
            position: relative;
            .wilo-image-chooser {
                position: absolute;
                bottom: 30px;
                right: 30px;
            }
        }
    }
    .multi-col-section {
        .page-module {
            margin-bottom: 0;
        }
    }
    .wilo-profile-content-wrapper {
        transition: 0.2s all ease-in-out;
    }
    .wilo-profile-element-header {
        overflow: auto;
        .wilo-profile-element-edit-header {
            overflow: auto;
            h2 {
                color: #363640;
                float: left
            }
            span,
            .wilo-icon {
                float: right;
                font-size: 18px;
                cursor: pointer;
            }
            .edit-mode-text {
                display: none;
                color: #787878;
                font-size: 14px;
            }
        }
    }
    .wilo-profile-element-content {
        transition: 0.2s all ease-in-out;
        margin-bottom: 30px;
    }
    .wilo-profile-checkboxes {
        .checkbox-wrapper {
            p {
                font-size: 14px;
            }
        }
    }
    .edit-mode-buttons {
        display: none;
    }
    .zip-code-city-form {
        label {
            display: block;
        }
        .zip {
            width: calc(30% - 5px);
            margin-right: 5px;
            float: left;
        }
        .city {
            width: 70%;
            float: left;
        }
    }
}

.wilo-profile-wrapper {
    .wilo-edit-profile {
        padding: 15px;
        border: 1px solid #009c82;
        margin-bottom: 30px;
        .wilo-profile-element-edit-header {
            .wilo-icon {
                display: none;
            }
            .edit-mode-text {
                display: inline-block;
                cursor: default;
            }
        }
        .wilo-profile-element-content {
            margin-bottom: 0;
            .edit-mode-buttons {
                display: block;
                button {
                    float: right;
                    margin-left: 10px;
                    min-width: 100px;
                }
            }
        }
    }
}

.fix-element {
    display: none;
}