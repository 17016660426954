

.image-module, .image-caption-module {
    .image-module-images-wrapper-inner {
        .slick-dots {
            position: static;
            margin: 5px 0 0 0;
        }
        .image-module-image-content-wrapper {
            position: relative;
            .image-module-image {
                width: 100%;
            }
            .image-module-on-image-caption-wrapper {
                background-color: $clr-text-darkblue;
                padding: 30px;
                position: absolute;
                width: 40%;
                opacity: 0.95;
                &.top-left {
                    top: 30px;
                    left: 60px;
                }
                &.bottom-left {
                    bottom: 30px;
                    left: 60px;
                }
                @include bp-smartphone-only {
                    width: 100%;
                    position: static
                }
                p, a {
                    color: $clr-white;
                }
                .image-module-on-image-caption-title {
                    font-size: 18px;
                    line-height: 1;
                    font-family: $NotoBold;
                    color: $clr-white;
                }
                .image-module-on-image-caption-text {
                    margin-bottom: 0;
                }
            }
        }
    }
    .image-module-caption-wrapper {
        .image-module-caption {
            font-style: italic;
            margin: 5px 0 0 0;
        }
    }
}

.three-cols-section {
    .image-module {
        .image-module-images-wrapper-inner {
            .image-module-image-content-wrapper {
                .image-module-on-image-caption-wrapper {
                    width: 100%;
                    position: static;
                }
            }
        }
    }
}

.two-cols-section {
    .image-module {
        .image-module-images-wrapper-inner {
            .image-module-image-content-wrapper {
                .image-module-on-image-caption-wrapper {
                    @include bp-max-tablet {
                        width: 100%;
                        position: static;
                    }
                }
            }
        }
    }
}

.one-third-col {
    .image-module {
        .image-module-images-wrapper-inner {
            .image-module-image-content-wrapper {
                .image-module-on-image-caption-wrapper {
                    width: 100%;
                    position: static;
                }
            }
        }
    }
}

.marginal-page-content {
    .two-cols-section {
        .image-module {
            .image-module-images-wrapper-inner {
                .image-module-image-content-wrapper {
                    .image-module-on-image-caption-wrapper {
                        width: 100%;
                        position: static;
                    }
                }
            }
        }
    }
}

.image-caption-module {
    .image-module-on-image-caption-wrapper {
        .link-list-item-text-wrapper {
            a {
                color: $clr-white;
            }
        }
    }
}



.image-caption-module .image-module-images-wrapper-inner .image-module-image-content-wrapper .image-module-on-image-caption-wrapper.bottom-left {
    left:35px;
}

.image-caption-module .image-module-images-wrapper-inner .image-module-image-content-wrapper .image-module-on-image-caption-wrapper .image-module-on-image-caption-title {
    font-size: 18px;
    line-height: 1;
    color: white;
    font-family: $NotoBold;
}
