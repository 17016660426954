.bsr-installer-search {
    
    &__wrapper {
        background-color: $clr-crystal-white;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 15px;
    }

    &__picture {
        grid-column: 1 / -1;
        margin-bottom: 16px;
        position: relative;

        picture {
            display: block;
        }
    }

    &__icon {
        border-radius: 50%;
        background-color: $clr-wilo-green;
        width: 50px;
        aspect-ratio: 1 / 1;
        position: absolute;
        inset: 0 auto auto 0;
        padding: 12px;
        translate: -5px -12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $clr-white;
        font-size: 24px;
        line-height: 100%;
    }

    &__textContent {
        grid-column: 1 / -1;
        padding: 24px 16px 16px;
    }

    &__headline {
        font-family: $NotoBold;
        font-size: 1.5rem;
    }

    &__abstract {
        margin-bottom: 24px;
    }

    &__text {
        margin-bottom: 32px;
    }
    
    
    @media #{$desktop} {

        &__wrapper {
            gap: 30px;
            padding: 48px 0;
        }

        &__picture {
            margin: 16px 0 0 -30px;
            grid-column: 2 / 7;
        }

        &__icon {
            width: 64px;
            translate: -16px -24px;
            font-size: 32px;
        }
        
        &__textContent {
            margin-top: 10px;
            grid-column: 8 / -2;
            padding: 0;
        }

        &__abstract {
            margin-bottom: 24px;
        }

        &__text {
            margin-bottom: 32px;
        }
    }
}