// Downloads
.download-items-wrapper.download-section {
    margin-left: -0.25%;
    margin-right: -0.25%;
    margin-bottom: 0;
    .slick-dots {
        bottom: 10px;
    }
}

.slick-initialized .download-item-wrapper {
    @media #{$smartphone-only} {
        padding-left: 35px;
        padding-right: 35px;
    }
}

.download-item-wrapper {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    >a:hover,
    a:focus {
        .wilo-icon {
            @include prefix(( transform: translateY(-10px), transition: all 0.5s), webkit moz);
        }
    }
    >a:hover,
    a:focus,
    a:link,
    a:visited,
    a:active {
        color: $clr-black;
    }
    @media (min-width: 992px) {
        width: 20%;
    }
}

.slick-initialized .download-item {
    display: table;
    margin-bottom: 0;
}

.download-item {
    margin-bottom: 15px;
    padding: 30px;
    background-color: $clr-crystal-white;
    min-height: 150px;
    width: 100%;
    @include bp-smartphone-only {
        max-height: 180px !important;
    }
    @include bp-tablet-only {
        min-height: 220px;
    }
    @include bp-desktop-only {
        min-height: 200px;
    }
    span {
        width: 100%;
        float: left;
        text-align: center;
        &.wilo-icon {
            @include font-size(25px);
            @include prefix(( transition: all 0.5s), webkit moz);
        }
    }
    @media (min-width: 992px) {
        display: table;
        margin-bottom: 0;
    }
}

.download-section {
    .small-padding {
        padding: 0 5px 0 0;
        &:last-child {
            padding: 0 0 0 0;
        }
    }
}

.slick-initialized .download-item-text {
    position: static;
    transform: translateX(0%)
}

.download-item-text {
    display: table-row-group;
    vertical-align: bottom;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    span {
        color: $clr-mega-menue-grey;
    }
    @media (min-width: 992px) {
        position: static;
        transform: translateX(0%)
    }
}

.download-text {
    margin: 15px 0 0 0;
}

.download-items-wrapper {
    .download-text {
        margin: 15px 0 0 0;
        min-height: 60px;
        @include bp-smartphone-only {
            min-height: 0;
        }
    }
    @include bp-max-tablet {
        padding-left: 30px;
        padding-right: 30px;
    }
    .download-item-wrapper {
        @include bp-max-tablet {
            float: left;
            width: 33.33%;
            &:nth-child(n+4) {
                display: none;
            }
            &.slick-slide {
                &:nth-child(n+4) {
                    display: block;
                }
            }
        }
        @media (max-width: 768px) {
            width: 50%;
            &:nth-child(n+3) {
                display: none;
            }
            &.slick-slide {
                &:nth-child(n+3) {
                    display: block;
                }
            }
        }
        @media (max-width: 600px) {
            width: 100%;
            &:nth-child(n+2) {
                display: none;
            }
            &.slick-slide {
                &:nth-child(n+2) {
                    display: block;
                }
            }
        }
    }
}

span.trigger {
    height: auto;
    &:before {
        /* TODO */
        font-family: $WiloIcons !important;
        content: "\e903";
        display: inline-block;
        margin-right: 5px;
        @include font-size(12px);
    }
}

.magazine-download {
    position: relative;
    &,
    .download-tile-module-wrapper,
    .inner-list-items {
        width: 100%;
    }
    .facts-content h5 {
        margin: 0;
        margin-bottom: 5px;
    }
    .facts-content ul.arrow-list {
        line-height: 1;
    }
    .facts-content h4 {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .download-tile-module-wrapper .inner-list-items .download-tile-download-wrapper {
        float: right;
        margin: 0;
        padding: 0;
        width: auto !important;
        div {
            display: block;
        }
    }
    @media (min-width: 992px) {
        .col-md-6 {
            width: 75%;
        }
    }
    .page-module:first-child {
        margin-top: 0;
    }
}