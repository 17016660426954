.login-container {
  .join-mywilo-box {
    padding: 20px;

    .join-mywilo-box-icon {
      align-self: center;

      span {
        @include font-size(92px);
      }
    }

    .row {
      display: flex;
      flex-direction: column;
    }

    @include bp-desktop {
      padding: 30px;

      .row {
        flex-direction: row;
      }
    }
  }

  .login-box, .join-mywilo-box {
    min-height: 317px;

    @include bp-max-tablet {
      min-height: 435px;
    }

    @include bp-desktop-only {
      min-height: 350px;
    }
  }

  @include bp-max-smartphone {
    .page-module:first-child {
      margin-top: 0;
    }
  }
}

.box-icon {
  .wilo-icon:before {
    color: $clr-text-regular;
  }
}


// Loginbox

.login-box {
  border: 1px solid $clr-wilo-green;
  padding: 20px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .box-icon {
    text-align: center;
    margin-bottom: 15px;

    span {
      @include font-size(92px);
    }
  }

  @include bp-max-smartphone {
    &.page-module:last-child {
      margin-bottom: 30px;
    }
  }

  @include bp-desktop {
    padding: 30px;
    flex-direction: row;
    justify-content: flex-end;

    .box-icon {
      width: 25%;
      padding-right: 30px;
      text-align: center;
      align-self: center;
    }
  }

  .box-headline {
    @include font-size(18px);
    margin: 0 0 15px 0;
    display: inline-block;

    @include bp-max-smartphone {
      margin-top: 15px;
    }
  }

  .wilo-btn {
    width: 100%;
  }

  .pass-entry {
    margin-bottom: 30px;
  }
}