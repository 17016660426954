.ecolution-two-column-teaser {
    $self: &;
    color: $clr-white;

    .row {
        margin-inline: 0;
    }

    &__teaser {
        position: relative;

        &:last-child {
            #{$self}__wave {
                img {
                    translate: -50% 0;
                }
            }
        }
    }
    
    &__background {
        display: block;
        background: linear-gradient(180deg, #001616 0%, #003B3A 100%);
        aspect-ratio: 16 / 9;
    }
    
    &__content {
        background-blend-mode: multiply;
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px;
        @media (min-width: 768px) {
            padding: 48px;
        }
    }

    &__headline {
        font-size: 1.875rem;
        line-height: 1.875rem;
        font-weight: 700;
        margin-bottom: 8px;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        position: relative;

        img {
            padding-left: 0.25em;
            height: 2.5rem;
            padding-bottom: 2px;
            width: auto;
        }

        @media (min-width: 768px) {
            font-size: 2.5rem;
            line-height: 2.5rem;
            img {
                height: auto;
            }
        }

        
    }

    &__subheadline {
        font-size: 1rem;
        line-height: 2.875rem;
        font-weight: 700;
        margin-bottom: 16px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
    }

    &__cta {
        align-self: center;
        justify-self: center;
        padding: 8px 12px;
        color: $clr-white;
        border: 1px solid $clr-white !important;
    }
}