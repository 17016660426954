.bsr-stage {
    $self: &;
    position: relative;

    &__image-wrapper { 
        position: relative;
        width: 100%;
        height: 60svh;

        picture,
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text-wrapper {
        position: absolute;
        inset: 0;
    }
    &__text {
        --letter-size: 32px;
        position: absolute;
        color: $clr-crystal-white;
        font-family: $NotoBold;
        line-height: 1.5;
        font-size: var(--letter-size);
        bottom: var(--letter-size);
        max-width: 300px;
        
        @media #{$tablet} {
            --letter-size: 52px;
            max-width: 400px;
        }
    }


    &--contentPage {
        #{$self}__image-wrapper {
            height: calc(50svh - 174px);
            max-height: 400px;
            min-height: 230px;
            
            @media #{$desktop} {
                height: 430px;
                max-height: unset;
            }
        }
    }
}