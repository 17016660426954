// Events
.events-headline-wrapper {
    margin-bottom: 20px;
}

.events-headline {
    @include font-size(18px);
}

.events-item {
    &:last-child {
        margin-bottom: 0;
    }
    font-family: $NotoBold;
    margin-bottom: 10px;
    margin-left: 0;
    img {
        width: 75px;
        height: 75px;
        float: left;
        margin-right: 15px;
    }
    p {
        color: $clr-text-green;
        @include font-size($font-size-base);
    }
    h4 {
        margin-bottom: 5px;
    }
}
