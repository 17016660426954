.bsr-fault-signal-assistance {

    h2 {
        margin-block-end: 1rem;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        row-gap: 12px;

        input {
            height: auto;
            padding: 10px 16px;
            border: 1px solid $clr-mega-menue-grey; 
        }

        button {
            height: auto;
            margin: 0;
            color: white;
            background-color: $clr-text-darkblue;
            padding: 10px 20px;
            border: none;
            margin-right: 8px;

            &:hover, &:focus-visible {
                background-color: $clr-wilo-green;
            }

            i {
                font-size: 24px;
            }
        }
    }

    &__tool {
        background: white;
        padding: 2rem 0;
    }

    &__result {
        padding: 2.5rem;

        p:first-child {
            margin-bottom: 1.5rem;

            * {
                font-family: $NotoBold;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $clr-lighter-grey;
        }
    }

    &__errorcode {
        color: $clr-wilo-green;
    }


}