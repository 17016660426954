.bsr-anchor-navigation {
    background-color: $clr-wilo-green;
    position: relative;
    top: 0;
    z-index: 1;

    &__container {
        position: relative;
    }

    &__scrollContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x proximity;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__scrollButton {
        position: absolute;
        z-index: 1;
        top: 0;
        height: 100%;
        width: 32px;
        border: 0;
        background-color: $clr-wilo-green;
        color: $clr-white;

        &--hidden {
            display: none;
        }

        &--next {
            right: 0;
        }

        &--prev {
            left: 0;
        }
    }
    
    &__link {
        $link: &;
        --horizontal-spacing: 22px;
        font-family: $NotoSemiBold;
        position: relative;
        white-space: nowrap;
        color: $clr-white;
        padding: 20px var(--horizontal-spacing);
        scroll-snap-align: start;

        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: 5px;
            height: 3px;
            width: calc(100% - 2 * var(--horizontal-spacing));
            border-radius: 3px;
            background-color: $clr-white;
        }

        &:last-child {
            scroll-snap-align: end;
        }

        &:focus {
            color: $clr-white;
        }

        &:focus-visible {
            outline: 1px solid $clr-white !important;
            outline-offset: -3px;
        }

        &:hover {
            color: $clr-white;

            &::after {                
                display: block;
                background-color: rgba(255 255 255 / 50%);
            }
        }

        &--active {
            &,
            &:hover {
                &::after {
                    display: block;
                    background-color: $clr-white;        
                }
            }
        }
    }
}