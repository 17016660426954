#em-exchangemirror-table {
    position: relative;
    .table-responsive.row {
        margin: 0;
        position: relative;
    }
    span.icon-envelope {
        position: absolute;
        top: -5px;
        right: 0;
        color: $clr-wilo-green;
        font-size: 25px;
    }
    th:first-child {
        border: none;
        background-color: #fff;
    }
}

.em-container {
    margin-bottom: 15px;
    position: relative;
    .wilo-btn {
        margin-bottom: 15px;
        height: auto;
    }
}

.wilo-emhidden,
.emhidden {
    display: none;
}

.em-back-button {
    cursor: pointer;
    padding-left: 15px;
}

.em-back-to-list {
    margin-bottom: 20px;
}

table.table tr th.emGreyLight {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.emGreyLight {
    background-color: $clr-crystal-white;
}

.emProgrssLoader {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    display: block;
}

@include bp-desktop {
    #em-list-head .em-head-item {
        padding-left: 0;
        padding-right: 5px;
        &:first-child {
            padding-left: 15px;
        }
        &:last-child {
            padding-right: 15px;
        }
        .em-head-inner {
            background-color: $clr-wilo-green-transparent;
            color: $clr-wilo-green;
            border-top: 2px solid $clr-wilo-green;
            padding: 15px;
            font-family: $NotoBold;
            text-align: left;
        }
    }
}

@include bp-desktop {
    #em-pump-list {
        .row {
            &:first-child,
            &:first-child:hover {
                cursor: auto;
            }
            .pf-cell {
                padding-left: 0;
                padding-right: 5px;
                &:first-child {
                    padding-left: 15px;
                }
                &:last-child {
                    padding-right: 15px;
                }
                .pf-inner {
                    background-color: $clr-crystal-white;
                    color: $clr-text-regular;
                    padding: 15px;
                    margin-top: 5px;
                    min-height: 70px;
                    .icon {
                        padding-left: 5px;
                    }
                }
            }
            &:hover {
                cursor: pointer;
                .pf-cell {
                    .pf-inner {
                        background-color: $clr-wilo-green;
                        color: $clr-white;
                        padding: 15px;
                        margin-top: 5px;
                        .icon {
                            padding-left: 5px;
                            color: $clr-white;
                        }
                    }
                }
            }
        }
    }
}

@include bp-max-tablet {
    .table-module {
        border: 0 solid transparent;
    }
    #em-pump-list {
        .row {
            margin: 2px 0 0 0;
            background-color: $clr-crystal-white;
            padding: 7px 0 0 0;
            box-sizing: border-box;
            cursor: pointer;
            &:first-child,
            &:first-child:hover {
                cursor: auto;
                background-color: $clr-wilo-green;
                color: $clr-white;
            }
            &:hover {
                background-color: $clr-wilo-green;
                color: $clr-white;
            }
            &#em-list-head:after {
                content: "";
            }
            &:after {
                content: "\e903";
                font-family: $WiloIcons !important;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 20px;
                margin-top: -40px;
                z-index: 99;
            }
            &.wilo-emhidden {
                &:after {
                    opacity: 0;
                }
            }
        }
    }
}

@include bp-max-smartphone {
    #exchangemirror-table .table-responsive {
        padding: 10px;
    }
}

#em-mail {
    margin-bottom: 30px;
    #wilo-formular {
        background-color: #f1f1f1;
        padding: 20px;
        margin: 0;
        select,
        input {
            background-color: #fff;
        }
    }
}

#em-error-msg {
    display: none;
}

#progress-wrapper {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    @include prefix(( transition: all 0.5s ease-in-out), webkit moz);
}

.wilo-search-box {
    background-color: #e9e9e9;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    input[type=text] {
        width: 95%;
        height: 40px;
        padding: 0 10px;
    }
    input[type=text]::-webkit-input-placeholder {
        color: #7b7b7b;
    }
    input[type=text]::-moz-placeholder {
        color: #7b7b7b;
    }
    input[type=text]:-ms-input-placeholder {
        color: #7b7b7b;
    }
    input[type=text]:-moz-placeholder {
        color: #7b7b7b;
    }
    input[type=text]::placeholder {
        color: #7b7b7b;
    }
    &:before {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-family: $WiloIcons !important;
        content: "\e90f";
        background: $clr-text-darkblue;
        text-align: center;
        color: #fff;
    }
    button {
        top: 20px;
        position: absolute;
        right: 20px;
        height: 40px;
        width: 40px;
        opacity: 0;
    }
}
