// Join Wilo
.join-mywilo-box{
    background-color: $clr-wilo-green;
    padding: 30px;
    display: flex;
    color: $clr-white;
    .join-mywilo-box-icon{
        flex: 1;
        display: flex;
        align-items: center;
        span {
            margin: auto;
            font-size: 60px;
        }
    }
    .join-mywilo-box-content {
        flex: 3;
        .section-headline {
            font-size: 18px;
        }
        ul li, ul li::before, .section-headline{
            color: $clr-white;
        }
        .buttons{
            display: flex;
            a {
                flex: 1;
            }
            @media (max-width: 1199px){
                display: block;
                padding: 0 15px;
                a {
                    width: 100%;
                    margin: 0 0 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &.row > *, .row > *{
        margin: 0 15px;
    }
    @media (max-width: 991px){
        display: block;
        .join-mywilo-box-icon{
            margin-bottom: 15px;
        }
    }
}

.marginal-col{
    .join-mywilo-box{
        display: block;
        .join-mywilo-box-icon {
            margin-bottom: 15px;
        }
        .join-mywilo-box-content{
            .buttons {
                display: block;
                a {
                    width: 100%;
                    margin: 0 0 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}