    // Parallax Header
    div.dashboard-header {
        margin-top: 190px;
        position: relative;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        display: table;
        &.dashboard-header {
            height: 33vh;
            @include bp-smartphone-only {
                height: 40vh;
            }
        }
        @include bp-max-tablet {
            margin-top: 0;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background: rgba(0, 0, 0, 0.3);*/
            z-index: 1;
        }
        .bg-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: cover;
            background-position-y: center;
            z-index: 9;
            &.dashboard-background {
                background-position: 0 -90px;
                @include bp-max-tablet {
                    background-position: 0 60px;
                }
            }
        }
        .profile-photo-wrapper {
            border: 2px solid $clr-white;
            border-radius: 50%;
            overflow: hidden;
            display: inline-table;
            margin: 0 5px;
            height: 40px;
            width: 40px;
        }
    }

    .image-text-wrapper.profile-header .image-text {
        padding: 20px;
        font-family: $NotoBold;
    }

    .profile-name {
        font-family: $NotoBold;
        color: $clr-light-grey;
        margin-right: 20px;
    }

    .content {
        background-color: $clr-white;
        /*margin-top: 100vh;*/
        /*margin-top: 630px; */
    }

    .image-text-wrapper {
        padding: 30px 25px;
        color: $clr-white;
        position: relative;
        height: 550px;
        &.profile-header {
            padding: 0;
        }
        .image-text {
            padding: 20px 15px;
            background-color: $clr-text-darkblue;
            color: $clr-white;
            @include font-size(18px);
            position: absolute;
            bottom: 50px;
            &.dashboard-image-text {
                bottom: auto;
                top: 20vh;
                line-height: 36px;
            }
        }
    } // Page Title Header
    header {
        &.page-title-header {
            height: 130px;
            background-image: url('/resources/img/Header_small_green.png');
            @include bp-max-tablet {
                margin-top: 100px;
            }
            @include bp-smartphone-only {
                height: 100px;
            }
            .page-title-text-wrapper {
                height: 130px;
                position: relative;
                @include bp-smartphone-only {
                    height: 100px;
                }
                .page-title-text {
                    position: absolute;
                    bottom: 25px;
                    color: $clr-white;
                    font-size: 24px;
                    font-family: $NotoBold;
                }
            }
        }
    }

    body.frontofpage {
        .content {
            background-color: transparent;
        }
    }

    .promo-highlight-teaser {
        background: transparent;
        height: 70vh;
        position: relative;
        z-index: 5;
        width: 100%;
        @include bp-max-tablet {
            height: 50vh;
        }
        .promo-highlight-link {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 999;
            .promo-highlight-back {
                position: fixed !important;
                top: 50%;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateY(-50%);
                width: 72.5%;
                margin: 0 auto;
                @include bp-desktop-large {
                    width: 75%;
                }
                @media all and (min-width:1500px) {
                    width: 50%;
                }
                @media all and (min-width:2200px) {
                    width: 40%;
                }
            }
        }
    }

    .stage-content-divider {
        display: block;
        background-color: white;
        height: 50px;
        width: 100%;
        z-index: 9;
        position: relative;
    }
