.stage-header {
    position: relative;
    .container {
        @include bp-max-smartphone {
            padding: 0;
        }
    }
    .stage-images {
        margin-bottom: 0;
        .slick-track {
            display: flex;
            .stage-image {
                display: flex;
            }
        }
        .slider-arrow {
            @include bp-max-tablet {
                top: 180px;
            }
            @include bp-max-smartphone {
                top: 25vw;
            }
        }
        .stage-image {
            position: relative;
            flex-direction: column;
            display: none;
            &:first-child {
                display: flex;
            }
            &>img {
                height: 570px;
                @media (max-width: 1200px) {
                    height: 470px;
                }
                @include bp-max-tablet {
                    height: 360px;
                }
                @include bp-max-smartphone {
                    flex-shrink: 0;
                    height: unset;
                }
            }
            @include bp-max-smartphone {
                background-color: #363640;
            }
            &.slick-slide {
                .inner-content {
                    display: flex;
                }
            }
            .inner-content {
                display: none;
                position: absolute;
                bottom: 55px;
                left: 55px;
                max-width: 60%;
                @include bp-max-tablet {
                    position: static;
                    max-width: 100%;
                }
                .stage-content-text-wrapper {
                    padding: 30px;
                    background-color: $clr-text-darkblue;
                    opacity: 0.95;
                    min-height: 200px;
                    flex-grow: 2;
                    @include bp-max-tablet {
                        opacity: 1;
                    }
                    &.inverted {
                        background-color: $clr-white;
                        h1 {
                            color: $clr-text-darkblue;
                        }
                        h2 {
                            color: $clr-text-darkblue;
                        }
                        p {
                            color: $clr-gunmetal;
                        }
                        @include bp-max-tablet {
                            background-color: $clr-text-darkblue;
                            h1 {
                                color: $clr-white;
                            }
                            h2 {
                                color: $clr-white;
                            }
                            p {
                                color: #bcbcbc;
                            }
                            .wilo-btn {
                                background-color: #ffffff;
                                color: #363640;
                            }
                        }
                    }
                    &.no-image {
                        width: 100%;
                    }
                    @include bp-max-tablet {
                        max-width: none;
                        margin-right: 0;
                    }
                    h1,
                    h2 {
                        color: $clr-white;
                        font-family: $NotoBold;
                        @include font-size(24px); // @Fiete soll 24px ist 18px
                        margin: 0;
                        margin-bottom: 15px
                    }
                    p {
                        color: #bcbcbc;
                        @include font-size($font-size-base);
                        margin-bottom: 15px;
                    }
                }
                .stage-content-image-wrapper {
                    background-color: $clr-white;
                    opacity: 0.95;
                    padding: 30px;
                    display: flex;
                    flex-grow: 1;
                    @include bp-max-tablet {
                        width: 30%;
                        opacity: 1;
                        background-color: $clr-text-darkblue;
                    }
                    @include bp-max-smartphone {
                        max-width: none;
                        position: absolute;
                        bottom: 50%;
                        right: 50%;
                        height: auto;
                        float: none;
                        transform: translateX(50%);
                    }
                    @include bp-max-s-smartphone {
                        display: none;
                    }
                    .stage-content-image {
                        margin: auto;
                        max-width: 140px;
                        max-height: 140px;
                        @include bp-max-tablet {
                            background-color: $clr-white;
                            padding: 15px;
                            float: right;
                        }
                    }
                }
            }
            &:first-child {
                .inner-content {
                    display: flex;
                }
            }
        }
    }
    .stage-page {
        height: auto;
        position: static;
        background-size: cover;
        background-position: center center;
        .stage-content-container {
            position: relative;
            height: 430px;
            @include bp-max-smartphone {
                padding: 0;
                height: calc(50vh - 100px);
                min-height: 250px;
                max-height: 400px;
            }
            .stage-content-sub {
                position: absolute;
                bottom: 50px;
                height: auto;
                width: 100%;
                @include bp-max-smartphone {
                    bottom: 0;
                }
                .stage-content-text-wrapper {
                    padding: 30px;
                    background-color: $clr-text-darkblue;
                    float: left;
                    margin-right: 0;
                    height: 100%;
                    opacity: 0.95;
                    &.inverted {
                        opacity: 0.94;
                        background-color: $clr-white;
                        h1,
                        h2 {
                            color: $clr-text-darkblue;
                        }
                        p {
                            color: $clr-gunmetal;
                        }
                    }
                    @include bp-max-smartphone {
                        max-width: none;
                        width: 100%;
                        position: static;
                        bottom: 0;
                        left: 0;
                        margin: 0;
                        height: auto;
                    }
                    @include bp-max-s-smartphone {
                        width: 100%;
                        height: auto;
                    }
                    h1,
                    h2 {
                        color: $clr-white;
                        margin: 0;
                    }
                    p {
                        color: #bcbcbc;
                        @include font-size(14px); // ToDo
                        margin-bottom: 15px;
                    }
                }
                .stage-content-image-wrapper {
                    float: left;
                    background-color: $clr-white;
                    height: 100%;
                    padding: 30px;
                    position: relative;
                    @include bp-max-smartphone {
                        max-width: none;
                        width: 30%;
                        position: absolute;
                        bottom: 50%;
                        right: 50%;
                        height: auto;
                        float: none;
                        transform: translate(50%, -90%);
                    }
                    @media (max-width: 600px) {
                        display: none;
                    }
                    .stage-content-image {
                        max-width: 140px;
                        max-height: 140px;
                    }
                }
            }
        }
    }
    &.subpage {
        .stage-page {
            .stage-content-container {
                .stage-content {
                    height: auto;
                    .stage-content-text-wrapper {
                        padding: 20px;
                        h1,
                        h2 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .slick-dots {
        bottom: 10px;
        @include bp-max-tablet {
            bottom: 0;
            margin: 15px 0;
        }
        @include bp-max-smartphone {
            position: static;
        }
        li {
            button {
                &:before {
                    font-size: 10px;
                    color: $clr-white;
                    opacity: 0.5;
                    @include bp-max-tablet {
                        color: $clr-light-grey-text;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: $clr-wilo-green;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.slick-slide {
    height: auto;
}

.stage-content {
    .slick-list>.slick-track {
        display: flex;
    }
}
