// Tab Module required
.search-filter-module-wrapper {
    .search-match {
        display: inline-block;
        float: left;
        font-size: 18px;
        font-family: $NotoBold;
        color:$clr-black;
        transform: translateY(25%);
        margin: 0;
        span {
            font-family: inherit;
            &#queryString {
              font-family: $WILOPlusFMRegular;
            }
        }
    }
    .search-filter-dropdown {
        @include bp-max-tablet {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include bp-max-smartphone {
            margin-top:15px;
        }
    }
}

.search-result-list {
    .text-picutre-image-wrapper {
        // margin-bottom: 15px;
        max-height: 150px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width:auto;
            max-height:150px;
            max-width:100%;
        }
    }
}

.productseries-search-result {
    .wilo-btn-primary {
        margin-right: 10px;
        &:last-child {
            margin: 0;
        }
    }
}

.pdf-search-result {
    .pdf-guide:before {
      @include font-size(160px);
    }
    .pdf-content {
      padding-top:30px;
    }

    @include bp-max-tablet {
      .pdf-guide:before {
        @include font-size(120px);
      }
    }
}

.media-search-result {
  .video-player-control {
      .wilo-icon {
        font-size: 35px!important;
        @include bp-smartphone-only {
            font-size: 75px!important;
        }
      }
      span {
        font-size: 16px!important;
        @include bp-smartphone-only {
            font-size: 18px!important;
        }
      }
  }

}


.search-result-wrapper {
    .paging-list {
        margin: 0 auto;

    }
}

.search-header {
    .products-per-page {
        transform: translateY(25%);
        @include bp-max-tablet{
            float: right;
            margin: 0 0 0 15px;
        }
    }
    .search-filter-dropdown {
        float: right;
        select, .select2, .select2-container{
            min-width: 150px !important;
        }


    }
}

.sort-by {

    text-align:right;
    line-height: 2.6;
    @include bp-max-smartphone{
        text-align:left;
    }
}

.search-result-list {
    list-style-type: none;
    padding: 0;
    &>li {
        list-style-type: none;
        margin: 0;
    } // /& > li
} // .search-result-list
