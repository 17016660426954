/* Uploader */

div.uploader {
    background-position: 0 -297px;
    height: 28px;
}

div.uploader span.action {
    background-position: right -409px;
    height: 24px;
    line-height: 24px;
}

div.uploader span.filename {
    height: 24px;
    /* change this line to adjust positioning of filename area */
    margin: 2px 0 2px 2px;
    line-height: 24px;
}

div.uploader.focus,
div.uploader.hover,
div.uploader:hover {
    background-position: 0 -353px;
}

div.uploader.focus span.action,
div.uploader.hover span.action,
div.uploader:hover span.action {
    background-position: right -437px;
}

div.uploader.active span.action,
div.uploader:active span.action {
    background-position: right -465px;
}

div.uploader.focus.active span.action,
div.uploader:focus.active span.action,
div.uploader.focus:active span.action,
div.uploader:focus:active span.action {
    background-position: right -493px;
}

div.uploader.disabled {
    background-position: 0 -325px;
}

div.uploader.disabled span.action {
    background-position: right -381px;
}

/* PRESENTATION */

/* Select */

div.uniform-select span {
    padding: 0 25px 0 2px;
    cursor: pointer;
}

div.uniform-select span {
    width: 100%;
    text-shadow: 0 1px 0 #fff;
}

div.uniform-select.disabled span {
    color: #bbb;
}

/* Checker */

div.uniform-checkbox {
    margin-right: 10px;
}

/* Radio */

div.uniform-radio {
    margin-right: 10px;
}

/*

CORE FUNCTIONALITY 

Not advised to edit stuff below this line
-----------------------------------------------------
*/

.uniform-select select:focus,
.uniform-radio input:focus,
.uniform-checkbox input:focus,
.uploader input:focus {
    outline: 0;
}

/* Select */

div.uniform-select {
    position: relative;
    padding-left: 10px;
    overflow: hidden;
}

div.uniform-select span {
    display: block;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.uniform-select select {
    position: absolute;
    opacity: 0;
    border: none;
    background: none;
}

/* Checker */

div.uniform-checkbox {
    position: relative;
    float: left;
}

div.uniform-checkbox span {
    display: block;
    float: left;
    text-align: center;
}

div.uniform-checkbox input {
    opacity: 0;
    display: inline-block;
    background: none;
}

/* Radio */

div.uniform-radio {
    position: relative;
}

div.uniform-radio span {
    display: block;
    float: left;
    text-align: center;
}

div.uniform-radio input {
    opacity: 0;
    text-align: center;
    display: inline-block;
    background: none;
}