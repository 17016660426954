.product-margin-teaser {
    margin-bottom: 30px;
    color: $clr-mega-menue-grey;

    .product-margin-teaser-title {
        font-size: 19px;
        font-family: $NotoBold;
        color: $clr-black;
        margin-top: 10px;
    }

    .product-margin-teaser-subtitle {
        @extend .product-margin-teaser-title;
        font-size: 14px;
    }
}
