.pdf-guide {
    font-size: 14px;
    color: $clr-wilo-green;
    font-weight: bold;
}

.pdf-guide:before {
    font-family: $WiloIcons !important;
    content: "\e92e" !important;
    margin-right: 16px;
    @include font-size(30px);
    float: left;
    font-weight: normal;
}

@media (max-width: 991px) {
    #documents-guides>div {
        margin-bottom: 10px;
    }
}