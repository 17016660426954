.tile {
    height: 100%;
    align-self: stretch;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;

    &--inner,
    &--content {
        display: flex;
        flex-direction: column;
    }

    &--content,
    &--main {
        flex: 1 0 auto;
    }

    &--image {
        img {
            object-fit: cover;
        }
    }

    &--inner {
        height: 100%;
        border: 1px solid $wilo-lightgrey-box;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 0 5px #0000001a;
    }
    
    &--content {
        padding: 1.5rem 1rem;
    }

    &--header {
        display: flex;
        justify-content: space-between;
        color: $clr-light-grey-text; // slightly different value than corporate
    }


    &--headline {
        @extend .fw--b;
        color: $clr-text-darkblue;
        font-size: 15px;
    }
    
    &--anchor {
        color: $clr-wilo-green;
    }

    &--footer {
        margin-top: 1.875rem;
    }
}