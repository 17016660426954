//// select2 overrides
//////////////////////////////////////////////////////////////////////////////////////////

.select2-dropdown {
    border: 1px solid $clr-text-green;
    border-radius: 0;
    &.select2-dropdown--above {
        box-shadow: 0 0 0 $clr-mega-menue-grey;
    }
    &.select2-dropdown--below {
        box-shadow: 0 0 0 $clr-mega-menue-grey;
    }
}

.select2-container--default {
    .select2-selection--single {
        background: $clr-mega-menue-grey;
        color: $clr-white;
        border-radius: 0;
        @include font-size(16px);
        font-family: $NotoBold;
        height: 38px;
        &:hover,
        &:focus,
        &:active {
            background-color: $clr-wilo-green;
            border-color: $clr-wilo-green;
        }
        .select2-selection__rendered {
            color: inherit;
            font-family: inherit;
            padding-top: 4px;
            padding-left: 17px;
            padding-right: 15px;
        }
        .select2-selection__arrow {
            height: 37px;
            background: url('/resources/img/select2-dropdown-arrow.png') no-repeat center;
            width: 12px;
            right: 15px;
            b {
                display: none;
            }
        }
    }
    .select2-results__option {
        color: $clr-mega-menue-grey;
        padding: 10px 16px;
        @include font-size(16px); // border-left: 1px #787878 solid;
        // border-right: 1px #787878 solid;
        border-bottom: 1px $clr-crystal-white solid;
        &[aria-selected=true] {
            background: $clr-crystal-white;
        }
        &--highlighted[aria-selected] {
            background: $clr-crystal-white;
            color: $clr-mega-menue-grey;
        }
    }
    &.select2-container--disabled .select2-selection--single {
        cursor: default;
        background-color: #dddddd;
        color: #555555;
        font-style: italic;
        font-family: $NotoSans;
        border: 1px solid #dddddd;
        &:hover {
            background-color: #dddddd;
            color: #555555;
        }
    }
}