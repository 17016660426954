.ecolution-full-width-video {
    scroll-snap-align: start;

    &__headline {
        visibility: hidden;
        height: 0;
        margin: 0;
    }
    
    &__video {
        display: block;
        width: 100%;
        aspect-ratio: 16 / 9;
        height: auto;
        overflow: hidden;

        @media #{$desktop} {
            max-height: 100svh;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }

        iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
            position: relative;
            left: 50%;
            top: 0;
            translate: -50% 0;
        }
    }
}