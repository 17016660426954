.bsr-introduction {
    $self: &;

    &__breadcrumb {
        margin-bottom: 24px;

        a {
            color: $clr-light-grey;

            &:not(:last-child) {
                border-right: 1px solid $clr-light-grey;
                padding: 0 7px 0 0;
                margin: 0 2px 0 0;
            }
        }
    }

    &__information {
        margin-bottom: 16px;
    }

    h1 {
        margin-bottom: 16px;
    }
    
    .bsr-abstract {
        margin-bottom: 24px;
    }

    &__text {
        p {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--contentPage {
        #{$self}__breadcrumb {
            margin-bottom: 64px;
        }
    }

    @media #{$desktop} {
        // #{$self}__text-wrapper {
        //     margin: 0;
        // }

        &#{$self}--layout-75-25 {
            #{$self}__text-wrapper {
                width: 75%;
            }
        }

        &#{$self}--layout-66-33 {
            #{$self}__text-wrapper {
                width: 66%;
            }
        }

        &#{$self}--layout-50-50 {
            #{$self}__text-wrapper {
                width: 50%;
            }
        }
    }
}