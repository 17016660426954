// partial copy of _threeColumnTeaser.scss
.bsr-three-column-teaser {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  grid-gap: 1rem;

  @mixin template-areas-large {
    &.equal-sized-grid {
      grid-template-columns: repeat(3, 1fr);
    }

    grid-template-areas: ". . ." ". . ." ". . ." ". . .";
  }

  @mixin template-areas-medium {
    &.equal-sized-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    grid-template-areas:
      ". . "
      ". . "
      ". . "
      ". . ";
  }

  @mixin template-areas-small {
    &.equal-sized-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    
    grid-template-areas:
      "."
      "."
      "."
      ".";
  }

  @include template-areas-small;

  @media (min-width: 768px) {
    @include template-areas-medium;
  }

  @media (min-width: 1200px) { 
    @include template-areas-large;
  }

}