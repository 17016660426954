.tools-module {
  .tab-pane .marginal-page-content {
    padding-left: 0;

    .page-module:first-child {
      margin-top: 20px;
    }
  }
  .tab-pane .page-module:first-child {
    margin-top: 30px;
  }
  .tab-pane .marginal-col {
    padding-right: 0;
    margin-top: 30px;
  }

  .tools-module-headline {
    font-family: $WILOPlusFMBold;
    color: $clr-text-darkblue;
    float: left;
    margin: 8px 15px;
    @include font-size($font-size-base);
  }

  .btn-default.wilo-green {
    float: right;
    margin: 0 20px;
  }
  .btn-default.wilo-green:before {
    font-family: $WiloIcons;
    content: "\e903";
    margin: 0 5px 0 0;
    @include font-size(14px);
  }
  .text-image-module-text-wrapper {
    color: $clr-light-grey;
  }
}
