.media-downloads-marginal-wrapper, .downloads-module {
    .media-downloads-list {
        &.link-list {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .link-list-item {
            .link-list-item-text-wrapper {
                font-family: $NotoSans;
                display: inline;
                color: $clr-dark-grey;
                a {
                    color: $clr-text-darkblue;
                }
            }
            .download-text {
                span {
                    font-size: 16px;
                    margin-right: 10px;
                    float: left;
                    &.wilo-icon {
                        color: $clr-dark-grey;
                    }
                }
                @include bp-tablet-only {
                    width: 100%;
                }
                @include bp-max-s-smartphone {
                    width: 100%;
                }
            }
            .download-button {
                margin-top: 10px;
                @include bp-tablet-only {
                    width: 100%;
                    a.btn {
                        width: 100%;
                    }
                }
                @include bp-max-s-smartphone {
                    width: 100%;
                    a.btn {
                        width: 100%;
                    }
                }
                a.btn {
                    float: right;
                }
            }
            span {
                @include font-size($font-size-base);
            }
        }
    }
}

.three-cols-section {
    .media-downloads-marginal-wrapper {
        .media-downloads-list {
            .link-list-item {
                .download-text {
                    width: 100%;
                    margin-bottom: 5px
                }
                .download-button {
                    width: 100%;
                    a.btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.one-third-col {
    .media-downloads-marginal-wrapper {
        .media-downloads-list {
            .link-list-item {
                .download-text {
                    width: 100%;
                    margin-bottom: 5px
                }
                .download-button {
                    width: 100%;
                    a.btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}
