.floating-text-image-module {
    .floating-text-image-paragraph-image-wrapper {
        display: inline-block;
        @include bp-max-tablet {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    a {
        color: $clr-wilo-green;
    }
}

.wilo-half-width {
    width: 50%;
}

.wilo-one-third-width {
    width: 33%;
}

.wilo-two-thirds-width {
    width: 66%;
}

.wilo-left-image {
    margin: 0 15px 0 0;
    float: left;
}

.wilo-right-image {
    margin: 0 0 0 15px;
    float: right;
}