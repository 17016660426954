@import '../general_vars.scss';

/* Media */

@media (max-width: 991px) {
    .super-navigation {
        display: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .main-nav-header {
        height: 100px;
        padding: 35px 15px;
    }

    .brand-header {
        margin: 0 0 0 0;
        font-size: 30px;
    }

    .navbar-toggle {
        margin: 0;
        padding: 0;
    }

    .navbar-collapse {
        width: auto;
        border-top: 0;
        @include prefix((box-shadow: none), webkit);
    }

    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important
    }

    .navbar-collapse.in {
        overflow-y: visible
    }

    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        padding-left: 0;
        padding-right: 0
    }
    
    .about-wilo {
        display: block;
    }

    .navbar-nav>.nav-element,
    .sub-menu-title {
        float: none;
        padding: 15px 0 15px 0;
        border-bottom: 1px solid $clr-light-grey;
        margin-top: 0;
    }

    .nav-element:last-child {
        border-bottom: none;
    }

    .navbar-toggle {
        &.is-active {
            position: fixed;
            right: 15px;
            top: 35px;
            z-index: $z-index-navigation-active + 1;

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: #363640;
            }
        }
        &:hover {
            opacity: 1;
        }
    }

    .navbar-nav {
        display: none;
        position: fixed;
        z-index: $z-index-navigation-active;
        top: 0;
        height: 100%;
        background-color: $clr-crystal-white;
        margin: 0;
        left: 0;
        width: 100%;
        padding: 50px 15px 30px;

        &.is-active {
            display: block;
        }
    }

    nav a:hover {
        color: $clr-wilo-green;
    }

    .nav-level1-element-text>a,
    .sub-menu-title {
        transition: none;
        font-size: 20px;
        color: $clr-text-darkblue;
    }

    .nav-level1-element-text span {
        display: inline-block;
        margin-right: 10px;
    }

    .nav-element .mobile {
        display: block;
    }

    .sub-menu-link,
    .mega-dropdown-menu .container .dropdown-header {
        color: $clr-text-darkblue;
    }

    .mega-dropdown-menu-arrow {
        display: none;
    }

    .mega-dropdown-menu.row {
        padding: 50px 15px 30px;
        width: 100%;
        height: 100vh;
        overflow: auto;
        box-shadow: none;
    }

    .sub-menu-container {
        width: 100%;
    }

    .sub-menu-container>[class*='col-'],
    .sub-menu-inner [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }

    .sub-menu-title {
        display: block;
        padding: 15px 0 15px 0;
        font-family: $NotoBold;
    }

    .sub-menu-link:hover:before {
        display: none;
    }

    .sub-menu {
        top: 0;
        position: fixed;
        z-index: $z-index-navigation-active;
        display: none;
        width: 100%;
        right: 0;

        &.is-active {
            display: block;
        }

        .mobile-container {
            width: 100%;
            padding: 0 20px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .sub-menu-inner {
            .dropdown-header {
                color: #fff;
            }
        }
    }
}


@media (max-width: 768px) {
    .main-nav-header {
        width: 100%;
        padding: 35px 15px;
    }
}