// Tabs

.nav-tabs > li > a {
    background-color: $clr-text-yellow;
    color: $clr-grey!important;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #009c82;
}

.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    opacity: 1;
    background-color: $clr-text-yellow;
    color: $clr-white;
    border-radius: 0;
    border-bottom: 1px solid #009c82;
}

.nav-tabs.nav-tabs-green {
    border-bottom: 2px solid $clr-wilo-green;
    margin-bottom: 30px;

    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus {
        background-color: $clr-wilo-green;
        color: $clr-white !important;
        border: none;
         border-bottom: 1px solid #009c82;

    }

    > li > a {
        background-color:#dddddd;
        color: #777 !important;
    }
}

.content select.tab-dropdown {
    min-width: auto;
    width: 100%;
    margin-bottom: 10px;
}

.wilo-tab-headline {
    margin-top: 18px;
    font-size: 20px;
}

.form-inline {
    .form-group {
        .label {
            margin-top: 15px;
            &:first-child{
                margin-top: 0;
            }
        }
        select,
        input,
        .select2-container{
            @media #{$max-desktop} {
                width: 100% !important;
            }
        }
        button.btn.btn-default,
        a.btn.btn-default,
        input.btn.btn-default {
            @media #{$max-desktop} {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}