// News / Teaser
.teaser-item,
.news-item p {
    color: $clr-light-grey;
}

.teaser-item {
    @include bp-smartphone-only {
        margin-bottom: 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }
}

.teaser-item-headline {
    color: $clr-dark-grey;
    @include font-size(18px);
    margin-top: 20px;
    margin-bottom: 5px;
}

.news-section {
    .publish-information {
        color: #000;
    }
    .news-item {
        @include bp-smartphone-only {
            margin-bottom: 30px;
        }
        &:hover {
            img {
                -webkit-transform: scale(1.15);
                transform: scale(1.15);
            }
        }
        img {
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
        }
    }
    .col-sm-4:last-child {
        .news-item {
            @include bp-smartphone-only {
                margin-bottom: 0;
            }
        }
    }
}

.news-headline {
    @include font-size(18px);
    display: inline-block;
    margin-bottom: 10px;
}

a.btn.news-button,
a.btn.teaser-button {
    @include font-size(14px);
}

a.btn.teaser-button {
    background-color: $clr-text-darkblue;
}

.wilo-submit {
    text-align: left;
    width: auto;
}

.news-button:before,
.teaser-button:before,
.wilo-submit:before {
    font-family: $WiloIcons;
    content: "\e903";
    margin: 0 5px 0 0;
    @include font-size(14px);
}

.news-image-container,
.teaser-image-container {
    overflow: hidden;
    margin-bottom: 15px;
}

.news-image,
.teaser-image {
    height: auto;
    width: 100%;
}