// Glossary
.glossary-wrapper {
  margin: 0 0 30px 0;
  .alphabetic-filter-header {
    display: inline-block;
    border-bottom: 1px solid $clr-border-grey;
    width: 100%;
    padding-bottom: 15px;
    .alphabet-letters {
      width: 100%;
      .active {
        color: $clr-black;
      }
    }
  }
  .glossary-letter-wrap>span,
  .glossary-headline {
    font-size: 18px;
    font-family: $NotoBold;
    color: $clr-text-darkblue;
    margin-bottom: 15px;
    display: inline-block;
  }
  .glossary-letter-wrap>span {
    font-size: 28px;
  }
  ul.glossary-list {
    padding: 0;
    list-style-type: none;
    li {
      border-bottom: 1px solid $clr-border-grey;
      margin-bottom: 20px;
      padding-bottom: 10px;
      span {
        color: $clr-text-darkblue;
        font-size: 14px;
      }
      p {
        color: $clr-mega-menue-grey;
      }
      a {
        color: $clr-wilo-green
      }
    }
  }
}

.glossar-pagination-wrapper {
  margin-bottom: 30px;
}

.alphabet-letters {
  padding-left: 0;
  @include bp-max-smartphone {
    padding: 0 35px;
  }
}

.alphabet-letters,
.list-pages {
  float: left;
  list-style: none;
  padding: 0;
  .alphabet-letter,
  .list-page {
    @include font-size($font-size-base);
    display: inline-block;
    color: $clr-light-grey;
    cursor: pointer;
    margin-right: 17px;
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &:active {
      color: $clr-black;
    }
  }
}

.alphabet-letter.no-content {
  opacity: 0.3;
  &:hover,
  &.active {
    color: inherit;
    font-family: inherit;
    cursor: default;
    opacity: 0.125;
  }
}

.content {
  .alphabet-letters {
    .wilo-icon {
      font-size: 12px;
    }
    @media screen and (max-width: 1199px) {
      float: none;
      text-align: left;
      .alphabet-letter {
        margin: 15px;
      }
    }
  }
}

.paging-list,
.paging-list-item {
  color: $clr-gunmetal;
  margin: 0 auto;
  display: table;
  >* {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .list-pages {
    float: none;
    display: inline;
    margin-right: 0px;
    .list-page {
      margin-right: 20px;
      a {
        color: $clr-light-grey;
      }
      &.active {
        font-family: $NotoBold;
        color: $clr-text-darkblue;
        a {
          color: inherit;
          font-family: inherit;
        }
      }
    }
  }
  span {
    @include font-size(12px);
    color: $clr-mega-menue-grey;
    cursor: pointer;
  }
}
