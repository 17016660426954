// Product Slider

.bx-wrapper .bx-viewport {
    border: none;
    box-shadow: none;
}

.slider-arrow {
    position: absolute;
    display: block;
    top: 50%;
    @include prefix((
      transform: translate(0, -50%)
    ), webkit moz ms o);
    @include font-size(20px);
    z-index:9;
    &.arrow-left{
        left: 0;
    }

    &.arrow-right{
        right: 0;
    }
}

.slider-description {
    float: right;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    .wilo-btn {
        width: 100%;
        margin-bottom: 5px;
        &:last-child{
          margin-bottom: 0;
        }
        @media #{$smartphone-only} {
            min-width: 135px;
        }
        @media #{$max-tablet} {
            width:100%;
        }
    }
    span {
        width: 100%;
        display: block;
    }
    .subline {
        color: $clr-mega-menue-grey;
        margin-bottom: 20px;
    }
}

.product-slider-section {
    .slider-arrow {
        opacity: 0.4;
    }
    .buy-online{
        position: absolute;
        bottom: 15px;
        background-color: #ffbb00;
        padding: 7px 10px;
        font-size: 14px;
        color: #363640;
        font-family: $NotoBold;
        line-height: 1;
        &:after{
            content: " ";
            position: absolute;
            top: 0;
            right: -8px;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 8px solid #ffbb00;
            z-index: 2;
        }
    }
}

.item-slider{
    .slick-track{
        display: flex;
    }
    .item-slide{
        display: flex;
        flex-wrap: wrap;
        width: 20%;
        float: left;
        padding: 0 10px;
        height:inherit;
        .image-wrapper {
            height:200px;
            display: flex;
            flex-direction: column;
            img {
                max-height: 200px;
                max-width: 100%;
                height: auto;
                width: auto;
                margin: auto;
                display: block;
                transform: scale(0.85);
                @include prefix((
                transition: all 0.5s
                ), webkit moz ms o);
                align-self: center;
                flex-shrink: 0;
            }
        }
        &:hover {
            .image-wrapper img {
              @include prefix((
                transform: scale(0.95)
              ), webkit);
            }
        }
        &:nth-child(n+6) {
            display: none;
        }
        &.slick-slide{
            &:nth-child(n+6) {
                display: flex;
            }
        }
        @media (max-width: 1200px){
            width: 25%;
            &:nth-child(n+5) {
                display: none;
            }
            &.slick-slide{
                &:nth-child(n+5) {
                    display: flex;
                }
            }
        }
        @media (max-width: 991px){
            width: 33.333%;
            &:nth-child(n+4) {
                display: none;
            }
            &.slick-slide{
                &:nth-child(n+4) {
                    display: flex;
                }
            }
        }
        @media (max-width: 768px){
            width: 50%;
            padding-left: 35px;
            padding-right: 35px;
            &:nth-child(n+3) {
                display: none;
            }
            &.slick-slide{
                &:nth-child(n+3) {
                    display: flex;
                }
            }
        }
        @media (max-width: 480px){
            width: 100%;
            &:nth-child(n+2) {
                display: none;
            }
            &.slick-slide{
                &:nth-child(n+2) {
                    display: flex;
                }
            }
        }
    }
}



/* Styles Product slider for cart overview page */
.cart-product-slider {
    color: $clr-text-darkblue;

    .slider-product-headline {
        margin-bottom: 5px;
    }

    .wilo-btn {
        margin: 0;
    }

    .price {
        font-family: $NotoBold;
        font-size: 18px;
        margin-bottom: 15px;

    }
}
