// Survey
.survey-teaser {
    width:100%;
    overflow:hidden;
    position: relative;
    box-sizing: border-box;
    .survey-wrapper {
      @include prefix((
        transition: all .5s ease
      ), webkit moz o ms);
        width:200%;
        position:relative;
        display: flex;
        .survey {
            &.first{
                border-right: 1px solid #fff;
                box-sizing: content-box;
            }
            width:50%; // you need to divide 100% / number of steps
            float:left;
            margin-bottom:0;
            label {
                padding: 10px 16px;
                width: 100%;
                background-color: $clr-technical-yellow;
                color: $clr-gunmetal;
                margin-bottom: 2px;
                @include prefix((
                  transition: all 0.5s
                ), webkit moz o ms);
                cursor: pointer;
                span {
                    display: none;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &.active {
                    background-color: #eba500;
                }
            }
            &.ending {
                background-color: $clr-technical-yellow;
                padding:20px;
                color: $clr-gunmetal;
                p {
                    color: $clr-gunmetal;
                }
                .wilo-icon {
                    color: $clr-gunmetal;
                    border-radius: 50%;
                    border: 5px solid $clr-gunmetal;
                    padding: 25px 15px 15px 15px;
                    font-size: 30px;
                    height: 80px;
                    width: 80px;
                    display: block;
                    text-align: center;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }
            }
            @media #{$max-smartphone} {
            }
        }
    }
}

.js-selectedSurvey {
    background-color: $clr-technical-yellow-darker;
    .wilo-icon {
        display: inline-block;
        float: right;
    }
}

.js-second-step {
    transform: translateX(-50%);
}

div.survey.first > label > input {
    visibility: hidden;
    display: none;
}

div.survey.first label span {
    display: none;
}

div.survey.first > label > input:checked +span {

        display: inline-block;
        float: right;
        line-height: 1.42857143;
        @include font-size(13px);
}

.survey-title {
    margin-bottom: 10px;
    font-family: $NotoBold;
    @include font-size(18px);
    display: inline-block;
}
