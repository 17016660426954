.video-module {
    .video-wrapper {
        position: relative;
        .video-module-video {
            width: 100%;
        }
        .video-playbutton {
            position: absolute;
            transform: translateY(-50%) translateX(-50%);
            width: 150px;
            top: 50%;
            left: 50%;
            z-index: 20;
            cursor: pointer;
            @include bp-smartphone-only{
                width: 100px;
            }
        }
        .transparent-overlay{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            background-color: $clr-white;
            opacity: 0.6;
            top: 0;
            left: 0;
        }
    }

    .video-module-text-title {
        margin-top: 0;
        font-family: $NotoBold;
        font-size: 18px;
    }
}
