.assistent-box {
  color: $clr-crystal-white;
  background-color: rgb(0, 156, 130);
  background-image: url("/resources/img/badge/assistant_app_module_bg.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto 100%;
  @include font-size(1.5rem);

  &__section {
    padding-block: 3rem;
    display: flex;
    place-items: center;
    justify-content: space-around;
  }

  &__title {
    font-family: $NotoBold;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__badges {
    gap: 15px;
    display: flex;
    margin-left:14vw;

    img {
      width: 130px;
      object-fit: cover;
      transition: all 0.8s ease;

      &:hover,
      &:focus {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }

    a {
      display: contents;
    }
  }

  &__promote {
    font-size: 15px;
    color: $clr-crystal-white;
    white-space: pre-line;
  }
}

@media (max-width: 580px) {
  .assistent-box__badges {
    flex-direction: column;
  }
}


@media (min-width: 581px) and (max-width: 767px) {
  .assistent-box__badges {
    flex-direction: row;
  }
}


@media (max-width: 767px) {
  .assistent-box {
    &__section {
      flex-direction: column;
    }

    &__title {
      word-break: break-word;
      font-size: 24px;
    }

    &__badges {
      margin-left: unset;

      img {
        width: 110px;
        background-blend-mode: color-burn;
      }
    }

    &__title,
    &__promote,
    &__desc {
      align-items: unset;
      text-align: center;
      object-fit: scale-down;
    }
  }
}

@media (min-width: 768px) {
  .assistent-box__desc {
    margin-left: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .assistent-box {
    &__title {
      white-space: nowrap;
      word-break: break-word;
      font-size: 24px;
    }

    &__badges {
      flex-direction: column;

      img {
        width: 110px;
      }
    }
  }
}
