.page-bottom-teaser {

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    &--element {
        display: flex;
    }

    &--inner {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: $clr-white;
        border: 1px solid $wilo-lightgrey-box;
        border-radius: 3px;
    }

    &--image {
        height: 200px;

        @include bp-max-smartphone {
            height: 300px;
        }

        img {
            object-fit: cover;
            height: 100%;
        }
    }

    &--text {
        padding: 22.5px 15px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        justify-content: space-between
    }

    &--text {
        p {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
            margin: 0;
        }
    }

    &--linktext {
        padding-top: 0px;
        color: $clr-wilo-green;
    }

    a::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

}