.ecolution-text-video {
    $self: &;
    padding-bottom: 80px;

    @media #{$desktop} {
        padding-bottom: 120px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media #{$desktop} {
            flex-direction: row;
        }
    }

    &__text {
        color: var(--text-color, $clr-black);
        margin-block-end: 72px;
        flex-basis: 50%;

        p {
            margin-block-end: 32px;
        }

        @media #{$desktop} {
            margin-block-end: 0;
        }

        &#{$self}--only-text {
            flex-basis: 100%;
        }
    }

    &__strongText {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
    }

    &__intermediateHeadline {
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-weight: 700;

    }

    &__video {
        flex-basis: 41.66667%;
        .video-wrapper {
            position: relative;
            aspect-ratio: 16 / 9;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }

}