.bsr-teaser {
    $self: &;
    
    &__grid {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(12, 1fr);
        background-color: $bsr-grey;
    }

    &__picture {
        position: relative;
        grid-column: 1 / -1;
        
        &Caption {
            position: absolute;
            inset: 16px;
            color: $clr-white;
            font-family: $NotoBold;
            font-size: 1.25rem;
            width: 75%;
        }
    }
    &__content {
        grid-column: 1 / -1;
        padding: 24px 16px;
        color: $clr-dark-grey;
        font-family: $NotoSans;
    }

    &__headline {
        font-size: 1.5rem;
        font-family: $NotoBold;
        padding-bottom: 16px;
        margin: 0;
    }
    &__text {
        font-size: 1rem;
        padding-bottom: 32px;
        margin: 0;
    }
    &__cta {
        padding-bottom: 32px;

        &.wilo-yellow {
            color: $clr-dark-grey;
        }
    }


    @media #{$tablet} {
        &__content {
            padding: 24px 32px 32px;
        }
        &__headline {
            padding-bottom: 24px;
        }

        &__picture {
            &Caption {                
                inset: 40px 32px;
                font-size: 1.5rem;
            }
        }
    }

    @media #{$desktop} {
        &__grid {
            gap: 30px;
        }

        &__picture {
            grid-row: 1;
            grid-column: 1 / 9;

            img,
            picture {
                display: block;
                height: 100%;
            }

            &Caption {                
                inset: 64px 40px;
                font-size: 2rem;
            }
        }
        &__content {
            grid-row: 1;
            grid-column: 9 / 13;  
            padding: 64px 40px 32px 0;
        }

        &--right {
            #{$self}__picture {
                grid-column: 5 / 13;
            }
            #{$self}__content {
                grid-column: 1 / 5;
                padding: 64px 0 32px 40px;
            }   
        }
    }
}