
.mfp-iframe-scaler {
  .mfp-title {
    position:absolute;
    font-size:$font-size-base;
  }
}

.lightbox-white {

  .mfp-content > div {
    position: relative;
    background: white;
    padding: 26px;
    width: auto;
    max-width: 600px;
    margin: 5px 0;
    border: 1px $clr-mega-menue-grey solid;

    .lightbox-title {
      margin-top: 0;
      font-size: 18px;
      font-family: $NotoBold;
      margin-bottom: 25px;

      &.with-subtitle {
        margin-bottom: 0;
      }
    }

    h5 {
      @extend .lightbox-title;
    }

    .lightbox-subtitle {
      margin-top: 0;
    }

    button.mfp-close {
      color: #373741;
      font-size: 46px;
      text-align: right;
      padding-right: 5px;
    }

    .actions-footer {
      border-top: 1px #ddd solid;
      padding-top: 28px;
      margin-top: 28px;

      h5 {
        margin-bottom: 0;
      }
    }

    .arrow-list {
      margin-bottom: 15px;
    }
  }

  &.mfp-wrap {
    z-index: 999;
  }

  &.mfp-bg {
      z-index: 998;
      background: none;
  }

  .bordered-checkbox-list {
    > .checkbox {
      border-bottom: 1px #ddd solid;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:first-child {
        margin-top: 15px;
        padding-top: 15px;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      > label {
        font-family: $WILOPlusFMBold;
        color: #777777;

        .suffix {
          font-family: $NotoSans;
        }
      }
    }
  }
}

.form-lightbox {
  .control-label {
    color: #777777;
    font-family: $WILOPlusFMBold;
    text-align: left;
  }

  .form-control {
      box-shadow: none;
      background: $clr-crystal-white;
      border: 0;
      font-family: $NotoSans;
  }
}

.lightbox-grey {
  @extend .lightbox-white;

  .mfp-content > div {
    background: $clr-text-darkblue;
    color: white;
    border-color: #777978;

    .actions-footer {
      border-color: #78797e;
    }

    button.mfp-close {
      opacity: 1;
      color: $clr-white;
    }
  }
}

.video-link {
  position: relative;
  display: block;
  img {
    opacity: 0.2;
    @include prefix((
      transition: all 0.3s ease-in-out
    ), webkit moz o);

  }
  & > .wilo-icons {
    position: absolute;
    width: 100%;
    height: 100%;
    color:$clr-wilo-green;
    transition: all 1s;
    text-align:center;
    z-index:9;
    @include prefix((
      transition: all 0.3s ease-in-out
    ), webkit moz o);

    &::before {
      font-size:48px;
      position: relative;
      top: calc(50% - 24px);
    }

  }

  &:hover {
    img {
      opacity: 1;
    }
  }


}

.mfp-modal-video {
  background: $clr-white none repeat scroll 0 0;
  margin: 10px auto;
  max-width: 80%;
  padding: 20px 30px;
  position: relative;
  text-align: center;
  float: none;
  video {
    width:100%;
    max-width: 100%;
  }
}
