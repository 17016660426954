$font-sm: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 24px;

/* Font Sizes */
.fs--sm {
    font-size: $font-sm;
}

.fs--md {
    font-size: $font-md;
}

.fs--lg {
    font-size: $font-lg;
}

.fs--xl {
    font-size: $font-xl;
}

/* Font Weights */
.fw--r{
    font-family: $NotoSans;
}

.fw--b{
    font-family: $NotoBold;
}

/* Headlines */
.hl--sm {
    @extend .fs--sm;
    @extend .fw--b;
}

.hl--md {
    @extend .fs--md;
    @extend .fw--b;
}

.hl--lg {
    @extend .fs--lg;
    @extend .fw--b;
}

.hl--xl {
    @extend .fs--xl;
    @extend .fw--b;
}

/* Font Color */
