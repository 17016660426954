// Wilo Color Theme
.btn-default.wilo-yellow {
    color: $clr-white;
    background-color: $clr-text-yellow;
    border: 0 solid $clr-text-yellow !important;
    padding: 10px;
    &:hover {
        color: $clr-white;
        background-color: $clr-text-yellow-hover;
    }
}

.btn-default.wilo-dark-blue,
.btn.btn-default.wilo-dark-blue {
    color: $clr-white;
    background-color: $clr-text-darkblue;
    border: 0 solid $clr-text-darkblue !important;
    &:hover {
        background-color: $clr-wilo-green;
        border: 0 solid $clr-text-green !important;
    }
}

.btn-default.wilo-green {
    color: $clr-white;
    background-color: $clr-wilo-green;
    border: 0 solid $clr-wilo-green !important;
    &:hover {
        background-color: $clr-text-darkblue;
        border: 0 solid $clr-text-darkblue !important;
    }
}

.bg-bsr-grey {
    background-color: $bsr-grey;

}