.order-and-download .article {
	margin-bottom: 30px;
}

.order-and-download .article-content .article-title h3 {
    height: 60px;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px !important;
    text-align: center;
    color: #787878;
    padding: 4px 20px;
}

.order-and-download .article .article-content .amount {
    width: 164px;
    margin: 0 auto;
}
.order-and-download .article .article-content .amount select {
    float: left;
    width: 100px;
    margin-right: 10px;
    border: 1px solid silver;
}

.order-and-download .article .article-content .amount .add-btn {
	color: #000;

    position: relative;
    margin-left: 10px;
    width: 44px;
    height: 34px;
    background-color: #FFF;
    border: 1px solid silver;
    border-radius: 4px;
}

.order-and-download .article .article-content .amount .add-btn ::after {
    font-family: "Ionicons", sans-serif;
    content: "\f167";
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 16px;
    line-height: 34px;
}


.order-and-download .article .article-selection {
	background-color: #009c82;
	text-align: center;
	padding: 0 5px;
	color: #FFF;
}
.order-and-download .article .inner {
	height: 444px;
	overflow: hidden;
	margin: 0;
	border: 1px solid #ececed;
	border-top-color: rgb(236, 236, 237);
	border-right-color: rgb(236, 236, 237);
	border-bottom-color: rgb(236, 236, 237);
	border-left-color: rgb(236, 236, 237);
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 10px 0
	rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}


.order-and-download .article .inner {
	border-color: #009c82;
	position: relative;
}

.order-and-download .article .article-img {
	padding: 20px 0;
	text-align: center;
	height: 220px;
}

.order-and-download .article .download a {
	background: #ececed;
	position: relative;
	display: block;
	margin: 0 auto;
	padding: 4px 20px;
	text-align: center;
	color: #009c82;
}


.article-selection-wrapper {
	bottom: 0;
	position: absolute;
	width: 100%;
}
.article-selection-wrapper  a{
	color: #fff;
	text-decoration: underline;
}

.article-selection-wrapper p {
	margin: 0;
	padding: 5px 0;
}


.downloads-cart {
	border-bottom: 1px solid #787878;
	margin-bottom: 50px;
}

.grid-cart.dropdown {
	position: relative;
	display: none;
    
    .btn:before{
        margin:0;
        content:'';
    }
}
.grid-cart .wilo-icon.icon-sales {
    margin-right: 15px;
}


.grid-articles .btn:before {
    content:'';
    margin-right:0;
}
    
.grid-cart.dropdown .btn {
	color: #fff;
	border-color: #009c82;
	background-color: #009c82;
}

.grid-cart-text.cart-count {
    background: #fff;
    color: #009c82;
    position: absolute;
    left: 29px;
    top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
}


.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1050;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    
    width: 300px;
	
	right: 0;
}
.grid-menu .grid-cart .dropdown-menu {
    left: auto;
    right: 0;
}


.grid-menu .grid-filter .dropdown-menu, .grid-menu .grid-cart .dropdown-menu {
    width: 300px;
    padding: 0;
    border: none;
}


.dropdown-menu .btn.btn-block.btn-order {
	position: relative;
	padding-left: 20px;
	padding-right: 40px;
	text-align: left;
	width: 100%;

}


.dropdown-menu li .article .unit {
    display: block;
    margin-top: 2px;
    font-size: 12px;
    font-weight: normal;
}

.dropdown-menu li .article {
    position: relative;
    padding: 10px 40px 10px 20px;
    border-bottom: 1px solid #ececed;
    font-weight: bold;
}

#cart .article .article-remove {
    position: absolute;
    right: 0;
    top: 15px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

#cart li.cart-article {
    .article-remove {
        float: right;
        margin: 5px;
    }
}

@media(max-width: 991px){
    .article-img {
        height: 100%;
        max-height: 100%;
        display: block;
    }

    .article-img img {
        display: block;
        width: auto;
        margin: 0 auto;
    }

    .order-and-download .article-content .article-title h3 {
        height: auto;
    }

    .order-and-download .article .inner {
        height: auto;
        min-height: 400px;
    }
}

