// Magazine Index Downloads
.magazine-index-downloads {
    .tab-content-link {
        @include font-size(18px);
        font-family: $NotoBold;
    }
}

.expandable-content-list-module {
    .expandable-content-list-module-wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $clr-border-grey;
        &:first-child {
            padding-top: 5px;
        }
        .expandable-content-list-module-toggle {
            @include font-size(18px);
            font-family: $NotoBold;
            color: $clr-text-darkblue;
            span {
                margin-right: 15px;
                display: inline-block;
                @include font-size(14px);
                transition: all 0.5s;
            }
        }
        .expandable-content-list {
            &:last-child {
                margin: 0;
            }
            .download-tile-module {
                margin-bottom: 0;
                margin-top: 15px;
            }
        }
        .expandable-content-list-module-content {
            display: none;
        }
    }
    &:first-child {
        .expandable-content-list-module-content {
            display: block;
        }
    }
    .download-tile-image-wrapper {
        margin-bottom: 15px;
    }
}
