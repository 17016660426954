.chat-container {
    position: fixed;
    bottom: calc(50% - 320px);
    right: -330px;
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 645px;
    transform: translate(-330px, 0);
    transition: 0.5s transform ease-in-out;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0 3px 6px 0;
    z-index: 9999;

    @media (max-width: 768px) {
        right: -100%;
        width: 100%;
        height: 100%;
        bottom: 0;
        transform: translate(-100%, 0);
    }

    &--closed {
        transform: translate(0, 0);
    }

    &--open {
        overflow: initial;

        @media (max-width: 768px) {
            overflow: hidden;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        padding: 0 0.75rem;
        font-size: 14px;
        border-bottom: 1px solid #dddddd;

        p {
            margin: 0;
        }
    }

    &__content {
        position: relative;
        flex-grow: 1;
        height: 575px;
    }

    &__close {
        color: #000;
    }
}
