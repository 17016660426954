/* Green ____________________________________________________________________________________________________________*/
.highlight_teaser_topic_wrapper {
  .h2_green {
    font-size: 24px;
    color: white;
  }
  .em_dash::before {
    content: "\2014";
    font-size: 48px;
    font-weight: 700;
    color: white;
  }
}
.full-width {
  width: 100vw !important;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}
.highlight_teaser_topic_wrapper {
  width: 100vw;
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.background_full_width_image {
  width: 100%;
  height: 370px;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}
.space_injector_wrapper,
.space_injector_wrapper_gun_metal,
.space_injector_wrapper_light_grey {
  padding-left: unset !important;
}
.qudrat_wrapper_space_injector {
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: auto !important;
  margin-right: auto !important;
}
.highlight_teaser_info {
  z-index: 1;
  background-color: #009c82;
  /* margin-left: 100px; */
  display: block;
  width: 370px;
  height: 370px;
  padding: 48px 24px;
}
/*Gun Metal____________________________________________________________________________________________________________*/
.h2_gun_metal {
  font-size: 24px;
  color: white !important;
}
.em_dash_gun_metal::before {
  content: "\2014";
  font-size: 48px;
  font-weight: 700;
  color: white;
}
.highlight_teaser_topic_wrapper_gun_metal {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.qudrat_wrapper_space_injector_gun_metal {
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.highlight_teaser_info_gun_metal {
  z-index: 1;
  background-color: #363640;
  /* margin-left: 100px; */
  display: block;
  width: 370px;
  height: 370px;
  padding: 48px 24px;
}
/* Light Grey ____________________________________________________________________________________________________________ */
.h2_light_grey {
  font-size: 24px;
  color: #000;
}
.em_dash_light_grey::before {
  content: "\2014";
  font-size: 48px;
  font-weight: 700;
  color: #000;
}
.highlight_teaser_topic_wrapper_light_grey {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.qudrat_wrapper_space_injector_light_grey {
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  margin-left: auto !important;
  margin-right: auto !important;
}
.highlight_teaser_info_light_grey {
  z-index: 1;
  background-color: #f1f1f1;
  /*   margin-left: 100px; */
  display: block;
  width: 370px;
  height: 370px;
  padding: 48px 24px;
}
@media (min-width: 320px) and (max-width: 767px) {
  /* Green ____________________________________________________________________________________________________________*/
  .qudrat_wrapper_space_injector {
    top: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    margin: 0 !important;
    z-index: 1;
  }
  .highlight_teaser_info {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
  /*Gun Metal____________________________________________________________________________________________________________*/
  .qudrat_wrapper_space_injector_gun_metal {
    top: 200px;
    display: block;
    width: 100vw;
    z-index: 1;
  }
  .highlight_teaser_info_gun_metal {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
  /* Light Grey ____________________________________________________________________________________________________________ */
  .qudrat_wrapper_space_injector_light_grey {
    top: 200px;
    display: block;
    width: 100vw;
    margin: 0 !important;
    z-index: 1;
  }
  .highlight_teaser_info_space_light_grey {
    display: block;
    width: 270px !important;
    height: 270px !important;
  }
  .highlight_teaser_info_light_grey {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
}
@media (max-width: 319px) {
  /* Green ____________________________________________________________________________________________________________*/
  .h2_green {
    font-size: 18px;
  }
  .qudrat_wrapper_space_injector {
    top: 50vmin;
    display: block;
    width: 100vw;
    margin: 0 !important;
    z-index: 1;
  }
  .highlight_teaser_info {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
  /*Gun Metal____________________________________________________________________________________________________________*/
  .h2_gun_metal {
    font-size: 18px;
  }
  .qudrat_wrapper_space_injector_gun_metal {
    top: 50vmin;
    display: block;
    width: 100vw;
    margin: 0 !important;
    z-index: 1;
  }
  .highlight_teaser_info_gun_metal {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
  /* Light Grey ____________________________________________________________________________________________________________ */
  .h2_light_grey {
    font-size: 18px;
  }
  .qudrat_wrapper_space_injector_light_grey {
    top: 50vmin;
    display: block;
    width: 100vw;
    margin: 0 !important;
    z-index: 1;
  }
  .highlight_teaser_info_light_grey {
    margin: 0;
    width: 100vw !important;
    height: 200px !important;
    padding: 0 24px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
}

.teaser-text-left {
  margin-right: auto;
}

.teaser-text-right {
  margin-left: auto;
}