// Demo Content

.demo-nav {
    overflow:hidden;

    .demo-content {
        position: fixed;
        line-height: 50px;
        bottom:0;
        left:0;
        width:100%;
        height:50px;
        background: #757575;
        color: #FFFFFF;

        @include prefix((
          transform: translateX(-100%)
        ), webkit);

        @include prefix((
          transition: all 0.5s
        ), webkit moz);

        z-index: 1000;
        .icon-demo {
            font-size: 35px;
            line-height: 53px;
        }
        .inner {
            font-family: NotoSansRegular, Verdana, Arial, Helvetica, sans-serif;
            font-size:20px;
        }
        a.controll-forw, a.controll-back {
            border: 1px solid white;
            background: transparent;
            line-height: 30px;
            margin: 8px 0;
            color: white;
            span.icon-arrow-left, span.icon-arrow-right {
                background: white;
                padding: 10px;
                display: inline-block;
                color: grey;
                &.icon-arrow-left {
                    margin: 0px 0 0 6px;
                }
                &.icon-arrow-right {
                    margin: 0px 6px 0 0px;
                }
                @include bp-smartphone-only  {
                    margin:0!important;
                }

            }
            &:hover {
                background: white;
                color: #757575;
            }
        }
        a.controll-forw {
            padding:0px 10px 0 0;
            @include bp-smartphone-only {
                padding:0;
            }
        }
        a.controll-back {
            padding:0px 0 0 10px;
            @include bp-smartphone-only {
                padding:0;
            }
        }
    }
    .demo-opener {
        position: fixed;
        background:#757575;
        color: #FFFFFF;
        border:1px #757575;
        bottom:0;
        left:0;
        height:50px;
        width:50px;
        text-align: center;
        cursor:pointer;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        z-index: 999;
        span {
            position: absolute;
            top:50%;
            left:50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &:hover {
            background:$clr-grey;
            color: $clr-wilo-green;
        }

    }
    .demo-open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .demo-close {
        cursor:pointer;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        line-height: 17px;
    }
}
