.bsr-product-use-case-table {
    $self: &;
    
    
    &__scrollContainer {
        overflow-x: auto;
     }

    &__content {
        width: 1290px;
    }

    &__row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column: 2 / -1;
    }

    &__col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 30px;
        border-left: 2px solid $clr-lighter-grey;
        color: $clr-grey;

        &, a {
            font-size: 1rem;
            font-family: $NotoBold;
        }
        
        a {
            color: $clr-wilo-green;
        }

        .wilo-icon {
            &.icon-tick {
                color: $clr-wilo-green;
            }
            &.icon-close {
                color: $clr-mega-menue-grey;
            }
        }
    }

    &__section {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: auto;
        border: 2px solid $clr-lighter-grey;
       
        &Caption {
            grid-row: 1 / -1;
            display: flex;
            justify-content: center;
            align-items: center;
            rotate: 180deg;
            background-color: rgba(0 156 130 / 10%);
            writing-mode: tb-rl;
            color: $bsr-darkgreen;
            font-family: $NotoBold;
        }
        &Content {
            grid-column: 2 / -1;
        }

        &--icons {
            border: unset;

            #{$self}__col {
                border: unset;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 16px;
                color: $clr-wilo-green;
            }
        }

        &--parameters {

            &, * {
                color: $bsr-darkgreen;
            }

            #{$self}__row {
                border-bottom: unset;

                &:not(:last-child) {
                    border-bottom: 2px solid $clr-lighter-grey;
                }
            }
        }

        &--values {
            border-top: unset;
            
            #{$self}__row {
                border-bottom: unset;

                &:nth-child(odd) {
                    background-color: $clr-crystal-white;
                }
            }
        }
    }

    &__link {
        padding-top: 40px;
    }
}