.link-list {
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;
    display: table;
}

.text-image-paragraph-title {
    color: $clr-mega-menue-grey;
    font-family: $NotoBold;
}

.link-list-item {
    margin-bottom: 5px;
    color: $clr-wilo-green;
    font-family: $NotoBold;

    span {
        font-size: 12px;
        margin-right: 5px;
    }
}

.link-list-item-text-wrapper {
    display: table-row;
}

.link-list-item-inner {
    display: table-cell;
}
