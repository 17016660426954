#product-results-list {
  border-collapse: separate;
  border-spacing: 5px;

  thead {
    background: none;
    border: 0;

    th {
      border-bottom: 2px solid $clr-wilo-green;
      border-left: 0;
      border-right: 0;
      box-shadow: none;
      color: $clr-wilo-green;
      font-weight: 600;
      padding-left: .5em;
      padding-right: .5em;
      text-align: left;
      @include bp-max-smartphone {
        min-width:100px;
      }
    }

  }

  td {
    background-color: $clr-crystal-white;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    color: $clr-super-navigation-grey;
    padding: .5em;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      vertical-align: middle;
    }

    &.wilo-align-left {
      text-align:left;
    }

    &.wilo-align-right {
      text-align:right;
    }

    &.wilo-align-center {
      text-align:center;
    }

    &.wilo-align-justify {
      text-align:justify;
    }

  }

  .add {
    cursor: pointer;

    .toggle-element {
      border: 1px solid $clr-wilo-green;
      color: $clr-wilo-green;
      display: inline-block;
      padding: .5em;
    }

    .icon {
      float: left;
    }
  }

  .remove {
    background-color: $clr-wilo-green;
    cursor: pointer;

    .toggle-element {
      color: $clr-white;
    }
  }

}

.product-results-controls {
  padding-left: 0,;
  padding-right: 0;

  .product-results-controls-inner {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-left: 1em;
    }

    &:last-child {
      padding-right: 1em;
    }
  }
}

.add-to-project-button {
  background-color: $clr-wilo-green;
  color: $clr-white;

  &:hover {
    color: $clr-super-navigation-grey;
  }
}

.load-more-results-button {
  background-color: $clr-text-darkblue;
  color: $clr-white;
}

.page-section {
  padding-bottom: 30px;

  .tab-headline {
    cursor: default;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .wilo-more {
    border: 0;
    color: $clr-white;
  }

  &.mod {
    margin-bottom: 0;

    .btn {
      display: block;

      &::before {
        content: '\e903';
        font-family: $WiloIcons;
        font-size: 14px;
        margin: 0 5px 0 0;
      }
    }

    h3 {
      color: $clr-wilo-green;
    }
  }
}

@media (min-width: (40em - .0625)) {
  #product-results-list {
    border-collapse: separate;
    border-spacing: 5px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .product-results-controls {
    .product-results-controls-inner {
      padding-left: 1.4em;

      &:first-child {
        display: none;
      }

      &:last-child {
        padding-right: 1.4em;
        width: 100%;
      }

      .load-more-results-button {
        width: 100%;
      }
    }
  }
}

.product-results {
  margin-bottom: 30px;
  > strong {
      font-size:18px;
      font-family: $NotoBold;
  }
}

#product-results-list .toggle-element {
  width: 30px;
  height: 30px;
  margin: 10px auto;
  padding: 0 !important;
  display: block !important;
}

#product-results-list a {
  color: $clr-super-navigation-grey;
  font-weight: bold;
}

#product-results-list .add .toggle-element:before {
  font-family: $WiloIcons !important;
  content: "\e943";
  font-size: 16px;
  line-height: 28px;
  padding-left: 1px;
}

#product-results-list .remove .toggle-element:before {
  font-family: $WiloIcons !important;
  content: "\e907" !important;
  font-size: 16px;
  line-height: 28px;
  padding-left: 1px;
}

.wilo-align-left {
  text-align:left;
}

.wilo-align-right {
  text-align:right;
}

.wilo-align-center {
  text-align:center;
}

.wilo-align-justify {
  text-align:justify;
}
