.section-title,
.product-series-information-section-title,
.help-dropdown-section>label,
.help-dropdown-section-title,
.search-section-title,
.alphabetic-filter-content-pump-content-product-name {
    font-family: $NotoBold;
}

.introduction-content,
.help-dropdown-section>label,
.help-dropdown-section-title {
    color: $clr-mega-menue-grey;
}

/* .product-types-filter-wrapper,
.application-segments-wrapper,
.alphabetic-pumps-wrapper {
    display: none;
} */

.pump-finder-tabs {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $clr-border-grey;
}

.introduction-content {
    margin-bottom: 20px;
}

.product-types,
.product-series-information-list {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 0px;
}

.product-highlights-filter {
    padding: 35px 0 10px;
    @include bp-max-smartphone {
        padding: 5px 0 10px;
    }
}

.product-series-wrapper {
    margin: 30px 0;
    background: #DDDDDD;
    padding: 30px;
    &:first-child {
        margin-top: 0px;
    }
    .product-series-overview-images {
        height: 100%;
        padding: 0px 15px;
        .product-series-overview-image {
            transform: translateY(-50%);
            margin-top: calc(50% + 15px);
            @include bp-max-smartphone {
                transform: none;
                margin-top: inherit;
            }
        }
    }
    .product-series-information {
        height: 350px !important;
        margin-top: 0px;
    }
    .product-series-overview-image-wrapper {
        padding: 15px;
        margin: 0px;
        background: $clr-white;
        height: 100%
    }
}

.product-series-overview {
    @include bp-max-tablet {
        margin-bottom: 20px;
    }
    @include bp-max-smartphone {
        height: inherit !important;
    }
}

.half-button-tbt {
    @include bp-tablet-only {
        width: calc(50% - 12px);
    }
}

// Pump Finder View
.pump-finder-view {
    margin-bottom: 30px;
    ul.viewer-choose {
        float: right;
        list-style: none;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        @include bp-max-smartphone {
            float: left;
        }
        li {
            display: inline;
            margin-right: 30px;
            color: $clr-mega-menue-grey;
            &:first-child {
                @include bp-max-smartphone {
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
            &:last-child {
                margin-right: 0px;
            }
            &.active {
                a {
                    opacity: 1;
                }
            }
            a {
                color: $clr-text-darkblue;
                opacity: 0.6;
                @include prefix(( transition: opacity 0.3s ease-in-out), webkit moz ms o);
                &:hover,
                &:active,
                &:focus,
                &.active {
                    opacity: 1;
                }
                span {
                    vertical-align: middle;
                }
                span.wilo-icon {
                    font-size: 18px;
                    margin-right: 5px;
                    @include bp-max-smartphone {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

.pumpfinder-filter-section,
.product-highlights-filter-section {
    select {
        @include bp-smartphone-only {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}

.ph-tabs-inner {
    margin-top: 35px;
}

.product-highlights-filter-section {
    border-bottom: 1px solid $clr-border-grey;
    .introduction-wrapper {
        h2 {
            font-size: 18px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 30px;
            color: $clr-mega-menue-grey;
        }
    }
}

.help-dropdown-section-wrapper {
    padding: 30px 0;
    @include bp-max-tablet {
        padding: 0px;
    }
    .form-inline {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.help-dropdown-section {
    button {
        min-width: 100px;
        @include bp-smartphone-only {
            min-width: inherit;
        }
    }
    @include bp-smartphone-only {
        button,
        select {
            width: 100%;
        }
        select {
            margin-bottom: 10px;
        }
    }
    .row.form-group {
        margin-bottom: 0px;
        width: 100%;
        @include bp-max-smartphone {
            width: inherit;
        }
    }
    .form-group {
        button.btn.btn-default {
            margin-top: 0px;
        }
    }
    .select2-container {
        @include bp-max-smartphone {
            margin-bottom: 10px;
        }
    }
}

.form-inline .help-dropdown-section .form-group button.btn.btn-default {
    margin-top: 0px;
}

.product-series-information-section-title {
    line-height: 1;
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
}

.product-series-information-section-text {
    color: $clr-mega-menue-grey;
    display: inline;
}

.section-title {
    font-size: 18px;
}

.series-name-wrapper {
    margin-bottom: 15px;
}

.product-series-buttons {
    margin-top: 20px;
}

.product-series-buttons>button {
    min-width: 150px;
    margin-right: 10px;
    &:last-child {
        @include bp-max-tablet {
            margin-bottom: 20px
        }
    }
}

.filter-section {
    margin: 15px 0 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $clr-border-grey;
    .filter-option {
        margin-top: 15px;
    }
}

.filter-option {
    filter.title {
        font-size: 18px;
        @include bp-max-tablet {
            margin-bottom: 5px;
        }
    }
    select {
        width: 100%;
        @include bp-max-tablet {
            margin-bottom: 10px;
        }
    }
}

.help-dropdown-section>label {
    font-size: 14px;
}

.help-dropdown-section-title {
    margin-bottom: 30px;
    font-size: 18px;
}

.help-dropdown {
    @include bp-max-tablet {
        border-bottom: 1px solid $clr-light-grey;
        padding-top: 30px;
        padding-bottom: 30px;
        &:last-child {
            border-bottom: 0px;
        }
    }
    .section-title {
        margin-bottom: 0px;
    }
}

input.products-per-page-input,
input.filter-per-page-input {
    width: 100%;
    background-color: #eee;
    border-radius: 0px;
    height: 38px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 14px;
    border: none;
    padding: 0px 14px;
    &::-webkit-input-placeholder {
        color: $clr-mega-menue-grey;
        @include font-size(14px);
        font-family: $NotoSans;
    }
    &:-moz-placeholder {
        color: $clr-mega-menue-grey;
        @include font-size(14px);
        font-family: $NotoSans;
    }
    &::-moz-placeholder {
        color: $clr-mega-menue-grey;
        @include font-size(14px);
        font-family: $NotoSans;
    }
    &:-ms-input-placeholder {
        color: $clr-mega-menue-grey;
        @include font-size(14px);
        font-family: $NotoSans;
    }
}

.search-section-wrapper {
    .search-section-inner {
        padding: 30px;
        background-color: $clr-crystal-white;
        .search-seaction-input {
            @include bp-max-smartphone {
                margin-bottom: 10px;
            }
        }
    }
    .search-section-title {
        font-size: 18px;
    }
    select {
        margin-bottom: 5px;
        min-width: 0;
        width: 100%;
    }
    button {
        @include bp-max-smartphone {
            min-width: 100px;
            width: 100%;
        }
    }
}

.introduction-title {
    margin-bottom: 10px;
    display: block;
}

.product-series-information-list-item {
    margin-left: 25px;
    margin-bottom: 5px;
    &:before {
        font-family: $WiloIcons;
        margin: 0px 0px 0px -25px;
        font-size: 10px;
        content: "\e92f";
        color: $clr-mega-menue-grey;
        padding-right: 13px;
    }
}

.join-my-wilo-box-pump-finder {
    padding-left: 0px;
}

.icon-mywilo.join-my-wilo-icon-pump-finder {
    padding: 0px;
    width: auto;
    text-align: left;
    font-size: 42px;
}

.application-segments-wrapper {
    padding: 0px;
    #product-results-list {
        display: table;
        thead {
            th {
                border-bottom: none;
                font-family: $NotoBold;
                width: 12.5%;
                &:first-child {
                    text-align: left;
                    width: 25%;
                }
                .wilo-icon {
                    font-size: 30px;
                    float: left;
                    margin-right: 6px;
                    min-height: 30px;
                }
                .prl-desc {
                    text-align: left;
                    @include bp-max-tablet {
                        display: inline-block;
                        width: 100%;
                        margin-top: 10px;
                        min-height: 32px;
                    }
                }
            }
        }
        tr {
            td {
                padding: 15px;
                &:first-child {
                    font-family: $NotoBold;
                    text-align: left;
                }
                @media screen and (max-width: 498px) {
                    padding: 5px;
                }
            }
            &.pump-type {
                @media screen and (max-width: 498px) {
                    display: none;
                }
                td {
                    background-color: #ccebe8;
                    color: $clr-wilo-green;
                    border-top: 2px solid $clr-wilo-green;
                }
            }
            &.pump-title {
                @media screen and (max-width: 498px) {
                    display: none;
                }
                td {
                    background-color: #f8f8f8;
                    color: $clr-black;
                }
            }
            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                padding-right: 0px;
            }
        }
    }
    a.btn {
        width: 100%;
        text-align: center;
    }
}

.alphabetic-filter-header {
    margin-bottom: 30px;
    width: 100%;
}

.products-per-page {
    float: right;
}

.products-per-page,
.filter-per-page {
    @media screen and (max-width: 1200px) {
        float: left;
        margin-top: 15px;
    }
    .products-per-page-text {
        float: left;
        margin-right: 10px;
        color: $clr-mega-menue-grey;
        margin-bottom: 0px;
    }
    .products-per-page-input,
    .filter-per-page-input {
        width: 60px;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 0px;
        transform: translateY(-25%);
    }
    .btn {
        transform: translateY(-25%);
    }
}

.filter-sorter {
    @media screen and (max-width: 768px) {
        margin-bottom:20px;
    }
    .styled-form {
        float: left;
        min-width: 200px;
        transform: translateY(-25%);
    }
}

.filter-per-page {
    .filter-desc {
        @include bp-max-tablet {
            margin-bottom: 20px;
        }
    }
    .wilo-select {
        transform: translateY(-25%);
        min-width: 200px;
        max-width: 80%;
    }
    @media screen and (max-width: 1200px) {
        float: none;
    }
}

.dropdown.dropdown-pf span.icon-arrow-bottom {
    position: absolute;
    right: 13px;
    top: 13px;
}

.alphabetic-filter-content-pump-wrapper {
    padding: 30px;
    min-height: 238px;
    border: 1px solid $clr-light-grey;
    @include prefix(( transition: all 0.5s, transform: scale(0.975)), webkit moz o);
    &:hover {
        border: 1px solid $clr-wilo-green;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        z-index: 1;
        cursor: pointer;
        @include prefix(( transition: all 0.5s ease, transform: scale(1)), webkit moz o);
    }
    .pump-content-wrapper {
        @include bp-tablet {
            min-height: 60px;
        }
        @include bp-desktop {
            min-height: 85px;
        }
    }
}

.alphabetic-filter-content-pumps {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    li {
        padding-bottom: 30px;
        .alphabetic-filter-content-pump {
            .alphabetic-filter-content-pump-content-product-name {
                font-size: 18px;
                margin-bottom: 0px;
                line-height: 1.2;
            }
            .alphabetic-filter-content-pump-content-promotional-name {
                color: $clr-light-grey;
            }
            .btn {
                width: 100%;
                &:first-of-type {
                    margin-bottom: 10px;
                }
            }
            .alphabetic-filter-content-pump-image {
                width: 100px;
                margin: 0 auto;
            }
            .alphabetic-filter-content-pump-image-wrapper {
                text-align: center;
                img {
                    max-height: 175px;
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }
}

.js-pumpfinder {
    .pump-right {
        margin-right: -10px;
    }
    .pump-left {
        margin-left: -10px;
    }
}

.pump-finder-main-section {
    .hidden {
        display: none;
    }
    .alphabetic-filter-content-pump-content a.btn.btn-default {
        padding-left: 15px;
    }
    .alphabetic-filter-content-pump-content a.btn.btn-default:before {
        margin: 0;
    }
}

// Angular Seriesfinder app
.wilo-clear-button {
    float: left;
    @include bp-max-smartphone {
        float: left;
    }
}
