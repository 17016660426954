.bsr-pump-replacement-teaser {
    
    &__wrapper {
        background-color: $clr-crystal-white;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 15px;
    }

    &__picture {
        grid-column: 1 / -1;
        margin-bottom: 16px;
        position: relative;

        picture {
            display: block;
        }
    }

    &__textContent {
        grid-column: 1 / -1;
        padding: 24px 16px 16px;
    }

    &__headline {
        font-family: $NotoBold;
        font-size: 1.5rem;
    }

    &__abstract {
        margin-bottom: 24px;
    }

    &__text {
        margin-bottom: 32px;
    }
    
    &__form {
        input {
            width: 100%;
            margin-bottom: 29px;
            border-radius: 4px;
            font-size: 1rem;
            padding: 10px 35px 10px 16px;
            border: 1px solid $clr-mega-menue-grey;
        }

        .wilo-yellow {
            display: inline-block;
            color: $clr-dark-grey;
        }
    }
    
    @media #{$desktop} {

        &__wrapper {
            gap: 30px;
            padding: 48px 0;
        }

        &__picture {
            margin: 16px 0 0 -30px;
            grid-column: 2 / 7;
        }

        &__icon {
            width: 110px;
            padding: 24px;
            translate: -36px -24px;
        }
        
        &__textContent {
            margin-top: 10px;
            grid-column: 8 / -2;
            padding: 0;
        }

        &__abstract {
            margin-bottom: 24px;
        }

        &__text {
            margin-bottom: 32px;
        }
    }
}